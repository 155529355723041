<div class="messages-header">
    <div
        *ngIf="chat.isClosed === 1"
        class="archive-info">
        <div class="archive-titles">
            <div
                class="archive-type"
                *ngIf="defineGroupOf(chat) === EChatGroupType.CUSTOMER">
                Customer Chats Archive
            </div>
            <div
                class="archive-type"
                *ngIf="defineGroupOf(chat) === EChatGroupType.ADMIN">
                Admin Chats Archive
            </div>
            <div class="archive-cnt">{{ getArchiveChatsCnt(chat) }} chats</div>
        </div>
        <div class="archive-arrow-holder">
            <img
                class="archive-arrow-img"
                src="assets/images/chat/arrow-icon.svg" />
        </div>
    </div>
    <div
        *ngIf="chat.mode === EChatType.DefaultChat"
        class="user-title">
        <div class="avatar-holders">
            <app-user-avatar
                *ngFor="let member of chatMembersWithouUser"
                class="chat-title-avatar"
                [firstname]="member.firstname"
                [lastname]="member.surname"
                [colour]="member.avatarColour">
            </app-user-avatar>
        </div>
        <div class="chat-title">{{ title }}</div>
    </div>
    <div
        *ngIf="chat.mode === EChatType.Channel"
        class="channel-title">
        <div class="channel-name">{{ title }}</div>
        <div
            class="channel-member-cnt"
            [matMenuTriggerFor]="memeberList"
            #menuTrigger="matMenuTrigger">
            {{ chat.members.length }} members
        </div>
    </div>
    <div
        class="sender-email"
        *ngIf="senderEmail">
        {{ senderEmail }}
    </div>
    <div
        class="lead-link"
        *ngIf="senderLeadLink">
        <a
            [routerLink]="[senderLeadLink]"
            routerLinkActive="router-link-active">
            View Lead Page
        </a>
    </div>
    <div
        *ngIf="chat.isClosed !== 1"
        class="archive-btn-holder">
        <button
            class="btn-styled btn-styled--secondary"
            (click)="archiveChat()">
            <img src="/assets/images/icon-archive.svg" />Archive
        </button>
    </div>
</div>
<div
    class="messages-body styled-scrolled"
    #scrollPanel>
    <div
        *ngIf="chat.isClosed === 1"
        class="removed-message">
        This chat has been removed on
        <span class="removed-message-date">{{ chat.lastAction | date : 'd/M/yy' }}</span> at
        <span class="removed-message-date">{{ chat.lastAction | date : 'h:mm a' }}</span>
    </div>
    <ng-container *ngFor="let messagesGroup of messagesGroups | keyvalue">
        <app-chat-day-message-block
            [day]="messagesGroup.key"
            [messageGroup]="messagesGroup.value">
        </app-chat-day-message-block>
    </ng-container>
</div>

<mat-menu
    #memeberList="matMenu"
    class="popup-simple-menu menu-members">
    <div class="menu-memebers-top">
        <div class="menu-memebers-top-left">
            <div class="menu-memebers-chat-members">Chat Members</div>
            <div class="menu-memebers-cnt">{{ chat.members.length }} members</div>
        </div>
        <div
            class="menu-memebers-add-member"
            (click)="addMember()">
            <span>Add Member</span>
            <img src="assets/images/chat/add-user-icon.svg" />
        </div>
    </div>

    <div class="menu-memebers-rows">
        <div
            *ngFor="let memeber of sortedMembers"
            class="menu-memebers-row">
            <div class="menu-memebers-avatar-holder">
                <app-avatar
                    [user]="memeber"
                    [showIcons]="false"
                    border="1px solid #fff"></app-avatar>
            </div>
            <div class="menu-memebers-avatar-name">
                <div class="menu-memebers-name-holder">
                    {{ memeber.firstname }} {{ memeber.surname }}
                    <span
                        *ngIf="isCurrentUser(memeber)"
                        class="menu-memebers-you"
                        >(You)</span
                    >
                </div>
                <div class="menu-memebers-type-holder">{{ memeber.accountType }}</div>
            </div>
            <div
                *ngIf="!isCurrentUser(memeber)"
                class="menu-memebers-basket-holder"
                (click)="removeMember(memeber)">
                <span class="menu-memebers-remove-label">Remove</span>
                <img src="assets/images/chat/basket-icon.svg" />
            </div>
            <div
                *ngIf="isCurrentUser(memeber) === 'member'"
                class="menu-memebers-basket-holder"
                (click)="leaveGroup()">
                <span class="menu-memebers-archive-group">Leave Group</span>
            </div>
            <div
                *ngIf="isCurrentUser(memeber) === 'creator'"
                class="menu-memebers-basket-holder"
                (click)="archiveChat()">
                <span class="menu-memebers-archive-group">Archive group</span>
            </div>
        </div>
    </div>
</mat-menu>
