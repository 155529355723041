<div class="row">
    <div class="col-lg-8">
        <div *ngIf="display === 'board'" class="d-flex align-items-center">
            <small>Order By:</small>
            <button type="button" class="btn btn-round ms-2"
                    (click)="setBoardView('progress')"
                    [ngClass]="{'btn-primary' : boardView === 'progress', 'btn-secondary-reverse' : boardView !== 'progress'}"
            >Workflow <i class="fa fa-fw fa-stream"></i></button>
            <button type="button" class="btn btn-round ms-2"
                    (click)="setBoardView('due')"
                    [ngClass]="{'btn-primary' : boardView === 'due', 'btn-secondary-reverse' : boardView !== 'due'}"
            >Due date <i class="fa fa-fw fa-calendar"></i></button>
            <button type="button" class="btn btn-round ms-2"
                    (click)="setBoardView('update')"
                    [ngClass]="{'btn-primary' : boardView === 'update', 'btn-secondary-reverse' : boardView !== 'update'}"
            >Update needed <i class="fa fa-fw fa-traffic-light"></i></button>

        </div>
    </div>
    <div class="col-lg-4 text-right">
        <div class="d-flex align-items-center">
            <small>Display:</small>
            <button type="button" class="btn btn-round ms-2"
                    (click)="setView('board')"
                    [ngClass]="{'btn-primary' : display === 'board', 'btn-secondary-reverse' : display === 'table'}"
            >Board <i class="fas fa-fw fa-th"></i></button>
            <button type="button" class="btn btn-round ms-2"
                    (click)="setView('table')"
                    [ngClass]="{'btn-primary' : display === 'table', 'btn-secondary-reverse' : display === 'board'}"
            >Table <i class="fa fa-fw fa-table"></i></button>
        </div>
    </div>
</div>

<hr class="my-5">

<app-order-board *ngIf="display === 'board'" [boardView]="boardView" [supplierId]="user.company.id"></app-order-board>

<ng-container *ngIf="display === 'table'">
    <div class="row mt-4">
        <div class="col-12">
            <div class="preview-box">
                <div class="preview-box-title">
                    Orders
                </div>
                <div class="preview-box-content">
                    <div class="data-container" *ngIf="user">

                        <app-order-detail-search
                                class="mt-4"
                                [params]="orderDetailParams"
                                [showSupplier]="showSupplier"
                                [showCustomer]="showCustomer"></app-order-detail-search>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

