<div class="card-popup clickoutside"
    [ngClass]="{'float-right' : idx === 0}"
    *ngIf="card"
    appClickOutside (clickOutside)="onClickOutside($event)">
    <h5 class="ml-3">Actions</h5>
    <ul class="list-group">
        <li class="list-group-item" *ngIf="col.groupId === 1">
            <a (click)="showAssignOrderConfirmation = true;" *ngIf="!showAssignOrderConfirmation">
                <i class="fa fa-check-circle"></i> Assign &amp; confirm order
            </a>
            <ng-container *ngIf="showAssignOrderConfirmation">
                <button class="btn btn-sm btn-white mr-2" (click)="showAssignOrderConfirmation = false;">Cancel</button>
                <button class="btn btn-sm btn-primary mr-2" (click)="setStatus(2)">Confirm</button>
            </ng-container>
        </li>

        <!--- if supplier and awaiting confirmation --->
        <li class="list-group-item" *ngIf="col.groupId === 2 && card.status.id === 2">
            <a (click)="showConfirmOrderConfirmation = true;" *ngIf="!showConfirmOrderConfirmation">
                <i class="fa fa-check-circle"></i> Confirm order
            </a>
            <ng-container *ngIf="showConfirmOrderConfirmation">
                <button class="btn btn-sm btn-white mr-2" (click)="showConfirmOrderConfirmation = false;">Cancel</button>
                <button class="btn btn-sm btn-primary mr-2" (click)="setStatus(3)">Confirm</button>
            </ng-container>
        </li>

        <!--- if supplier and confirmed --->
        <li class="list-group-item" *ngIf="col.groupId === 2 && card.status.id === 3">
            <a (click)="showConfirmOrderProduction = true;" *ngIf="!showConfirmOrderProduction">
                <i class="fa fa-check-circle"></i> Move to production
            </a>
            <ng-container *ngIf="showConfirmOrderProduction">
                <button class="btn btn-sm btn-white mr-2" (click)="showConfirmOrderProduction = false;">Cancel</button>
                <button class="btn btn-sm btn-primary mr-2" (click)="setStatus(4)">Confirm</button>
            </ng-container>
        </li>
        <!--- ready for collection --->
        <li class="list-group-item" *ngIf="col.groupId === 2 && card.status.id === 4">
            <a (click)="showReadyForCollection = true;" *ngIf="!showReadyForCollection">
                <i class="fa fa-check-circle"></i> Ready for collection
            </a>
            <ng-container *ngIf="showReadyForCollection">
                <button class="btn btn-sm btn-white mr-2" (click)="showReadyForCollection = false;">Cancel</button>
                <button class="btn btn-sm btn-primary mr-2" (click)="setStatus(5)">Confirm</button>
            </ng-container>
        </li>

        <!--- into transit --->
        <li class="list-group-item" *ngIf="(col.groupId === 3 && card.status.id === 5) || card.isSample">
            <a (click)="showConfirmInTransit = true;" *ngIf="!showConfirmInTransit">
                <i class="fa fa-check-circle"></i> Mark as collected
            </a>
            <ng-container *ngIf="showConfirmInTransit">
                <button class="btn btn-sm btn-white mr-2" (click)="showConfirmInTransit = false;">Cancel</button>
                <button class="btn btn-sm btn-primary mr-2" (click)="setStatus(6)">Confirm</button>
            </ng-container>
        </li>

        <!--- Set to completed --->
        <li class="list-group-item" *ngIf="col.groupId === 3 && card.status.id === 6">
            <a (click)="showConfirmCompleted = true;" *ngIf="!showConfirmCompleted">
                <i class="fa fa-check-circle"></i> Mark as delivered
            </a>
            <ng-container *ngIf="showConfirmCompleted">
                <button class="btn btn-sm btn-white mr-2" (click)="showConfirmCompleted = false;">Cancel</button>
                <button class="btn btn-sm btn-primary mr-2" (click)="setStatus(7)">Confirm</button>
            </ng-container>
        </li>

        <!-- <li class="list-group-item" *ngIf="col.groupId < 3">
            <a (click)="edit()">
                <i class="fa fa-edit"></i> Edit order
            </a>
        </li> -->
        <li class="list-group-item">
            Customer: {{card.contactFirstname}} {{card.contactSurname}}
        </li>
        <!-- <li class="list-group-item">
            Supplier: {{card.supplier.name}}
        </li> -->
        <!-- <li class="list-group-item" *ngIf="card.allocatedTo">
            Allocated To: {{card.allocatedTo.name}}
        </li> -->
<!--        <li class="list-group-item">-->
<!--            Courier: {{card.supplier.name}}-->
<!--        </li>-->
        <!-- <li class="list-group-item" *ngIf="col.groupId < 3">
            <div class="row no-gutters">
                <div class="col-6">
                    <button class="btn btn-sm btn-white" type="button" (click)="confirmCancel()">
                        <i class="fa fa-ban"></i> Cancel Order
                    </button>
                </div>
                <div class="col-6" *ngIf="col.groupId !== 1">
                    <button *ngIf="!card.osUpdateRequests.length" class="btn btn-sm btn-white" type="button" (click)="requestUpdate()">
                        <i class="fa fa-question-circle"></i> Request update
                    </button>
                </div>
            </div>
        </li> -->
    </ul>
</div>

