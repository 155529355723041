<div class="row">
    <div class="col-12">
        <div class="preview-box">
            <div class="preview-box-title">
                Your Rewards
            </div>
            <div class="preview-box-content">
                <div class="data-container">

                    <div class="table-responsive">
                        <table class="table table-condensed">
                            <thead class="thead-white">

                            <tr>
                                <th>Created</th>
                                <th>Type</th>
                                <th>Pending</th>
                                <th>Awarded</th>
                                <th>Status</th>
                                <th></th>
                                <th></th>
                            </tr>
                            <!--                                    <tr>-->
                            <!--                                        <th></th>-->
                            <!--                                        <th><input type="text" class="form-control" [(ngModel)]="params.orderId" (ngModelChange)="filterSearch()" /></th>-->
                            <!--                                        <th><input type="text" class="form-control" [(ngModel)]="params.orderId" (ngModelChange)="filterSearch()" /></th>-->
                            <!--                                        <th><input type="text" class="form-control" [(ngModel)]="params.invoiceId" (ngModelChange)="filterSearch()" /></th>-->
                            <!--                                        <th><input type="text" class="form-control" [(ngModel)]="params.status" (ngModelChange)="filterSearch()" /></th>-->
                            <!--                                        <th><input type="text" class="form-control" [(ngModel)]="params.shippingStatus" (ngModelChange)="filterSearch()" /></th>-->
                            <!--                                        <th><input type="text" class="form-control" [(ngModel)]="params.dueDate" (ngModelChange)="filterSearch()" /></th>-->
                            <!--                                        <th></th>-->
                            <!--                                    </tr>-->
                            </thead>
                            <tbody>
                            <tr class="underline" *ngFor="let reward of rewards">
                                <td>{{reward.createdAt | date: 'dd MMM yyyy'}}</td>
                                <td>
                                    {{reward.triggerName}}
                                    <span *ngIf="reward.triggerId === 'referral'">
                                                : {{reward.referralEmail}}
                                            </span>
                                </td>
                                <td><span *ngIf="!reward.confirmed">{{reward.points}}</span></td>
                                <td><span *ngIf="reward.confirmed">{{reward.points}}</span></td>
                                <td>
                                    <ng-container *ngIf="reward.confirmed">
                                        <i *ngIf="reward.confirmed" class="fa fa-check text-success"></i> confirmed {{reward.confirmedAt | date: 'dd/MM/yyyy'}}
                                    </ng-container>
                                    <ng-container *ngIf="!reward.confirmed">
                                        {{reward.pendingText}}
                                    </ng-container>
                                </td>
                                <td>
                                    <!--                                            <a [routerLink]="['/account', 'orders', s.id]">-->
                                    <!--                                                <i class="fa fa-edit"></i>-->
                                    <!--                                            </a>-->
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="no-results" *ngIf="!rewards.length">
                        <p>No rewards triggered</p>
                    </div>

                    <app-pagination *ngIf="params.pageArray.length" [params]="params" (update)="filterSearch()"></app-pagination>


                </div>
            </div>
        </div>
    </div>
</div>
