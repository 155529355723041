import { Component, OnInit, Input, Output, EventEmitter, HostBinding } from '@angular/core';
import { MenuService } from 'src/app/services/menu.service';
import { ChatRESTService } from '../../services/chat-rest.service';
import { MatDialog } from '@angular/material/dialog';
import { NewSupportChatDialogComponent } from '../../dialogs/new-support-chat-dialog/new-support-chat-dialog.component';
import { IChat, IChatEvent, IChatListItem, IChatMember, IChatMessage } from '../../models/';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EChatMode } from '../../chat.component';
import { ChatGroupLogicService } from '../../services/chat-group-logic.service';
import { EChatEventName, EChatGroupType, EChatSubgroup, EChatType } from '../../enums/';
import { ChatEventsService } from '../../services/chat-events.service';
import { ChatDialogService } from '../../services/chat-dialog.service';
import { IChatReceivedMessage } from '../../models/chat-message.interface';

export interface IChatCreationData {
	members: IChatMember[];
	mode: EChatType.DefaultChat | EChatType.Channel;
	channelName?: string;
	forwardMessage?: IChatMessage;
}
@UntilDestroy()
@Component({
	selector: 'app-chat-side-menu',
	templateUrl: './chat-side-menu.component.html',
	styleUrls: ['./chat-side-menu.component.scss'],
})
export class ChatSideMenuComponent implements OnInit {
	@Input() openedChat: IChat;
	@Input() domainClass: string;
	@Output() chatSelected = new EventEmitter<string>();
	@Output() chatModeChanged = new EventEmitter<EChatMode>();

	@HostBinding('class') get class() {
		return this.domainClass;
	}

	openedSubgroup: EChatSubgroup;

	constructor(
		public menuService: MenuService,
		public chatRESTService: ChatRESTService,
		public chatGroupLogicService: ChatGroupLogicService,
		public dialog: MatDialog,
		private chatEventsService: ChatEventsService,
		private chatDialogService: ChatDialogService
	) {}

	ngOnInit(): void {
		this.chatEventsService
			.listenEvent(EChatEventName.MessageAdded)
			.pipe(untilDestroyed(this))
			.subscribe((event: IChatEvent) => {
				const message: IChatReceivedMessage = event.data;
				this.handleNewMessage(message);
			});
	}

	typeSwitched(type: EChatGroupType) {
		this.chatGroupLogicService.chatType = type;
	}

	toggleMainSidebar() {
		// TODO: #OLD
		// this.menuService.sidebarOpened = !this.menuService.sidebarOpened;
	}

	selectChat(chatId: number) {
		this.chatEventsService.emitEvent(EChatEventName.ChatSelected, {
			id: chatId,
		});
	}

	async openNewChat(preselectedMember?: IChatMember) {
		let selectedMember: IChatMember;
		if (preselectedMember) {
			selectedMember = preselectedMember;
		} else {
			selectedMember = (await this.chatDialogService.openUsersSelectorDlg('single', 'Start Chat')) as IChatMember;
			if (!selectedMember) return;
		}

		const existChatId = this.chatGroupLogicService.chatExists([selectedMember]);

		if (existChatId) {
			this.chatEventsService.emitEvent(EChatEventName.ChatSelected, {
				id: existChatId,
			});
			return;
		}

		this.chatEventsService.emitEvent(EChatEventName.CreateChat, {
			mode: EChatType.DefaultChat,
			members: this.chatGroupLogicService.addCurrentUserToMemebers([selectedMember]),
		});
	}

	openArchive() {
		this.chatModeChanged.emit(EChatMode.Archive);
	}

	creatNewChat() {
		this.dialog.open(NewSupportChatDialogComponent, {
			height: '324px',
			width: '468px',
			position: { top: '50px' },
			panelClass: 'new-chat-dialog-container',
			data: {
				chats: this.chatGroupLogicService.chats,
			},
		});
	}

	private handleNewMessage(message: IChatReceivedMessage) {
		this.addNewItemToSidebarIfNeeded(message);
		this.addUnreadMarkIfNeeded(message);
	}

	private addUnreadMarkIfNeeded(message: IChatReceivedMessage): number {
		if (this.openedChat.id !== message.chatId) {
			const chat = this.chatGroupLogicService.chats.find((c) => c.id === message.chatId);
			if (!chat) return;
			chat.unread ? chat.unread++ : (chat.unread = 1);
			this.chatGroupLogicService.updateChatState();
		}
	}

	private addNewItemToSidebarIfNeeded(message: IChatReceivedMessage) {
		const existsChat = this.chatGroupLogicService.chats.find((c) => c.id === message.chatId);
		if (existsChat) {
			existsChat.isClosed = 0;
		}
		this.chatGroupLogicService.addChat(message.chatInfo);
		this.selectChatInSidebar(message.chatInfo);
	}

	private selectChatInSidebar(chat: IChatListItem) {
		const group = this.chatGroupLogicService.defineGroupOf(chat);
		const subgroup: EChatSubgroup = this.chatGroupLogicService.findSubgroupFor(chat);
		this.chatGroupLogicService.chatType = group;
		this.openedSubgroup = subgroup;
	}
}
