import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EUserSelectorDlgResultStatus, IUserSelectorSettings } from './user-selector-dlg.interface';

@Component({
    selector: 'app-user-selector-dlg',
    templateUrl: './user-selector-dlg.component.html',
    styleUrls: ['./user-selector-dlg.component.scss'],
})
export class UserSelectorDlgComponent implements OnInit {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: IUserSelectorSettings,
        public dialogRef: MatDialogRef<UserSelectorDlgComponent>
    ) {}

    ngOnInit(): void {
        this.data.style = 'chat';
    }

    cancel() {
        this.dialogRef.close({
            status: EUserSelectorDlgResultStatus.Canceled,
        });
    }

    usersSelected(users) {
        this.dialogRef.close({
            status: EUserSelectorDlgResultStatus.Selected,
            members: users,
        });
    }
}
