

<div class="defaultAside aside-open slide-box-show" id="processHolder">
    <a href="javascript:void(0)" class="closebtn" (click)="close()">&times;</a>

    <div class="page-header-banner">
        <h1 *ngIf="newUser">Add New User</h1>
        <h1 *ngIf="!newUser">Edit User</h1>
    </div>

<div class="p-4">
    <form [formGroup]="form" autocomplete="off" novalidate [autocomplete]="'off'" (ngSubmit)="onSubmit()">

        <div formGroupName="contact">
            <div class="form-group row mb-3">
                <label class="col-sm-3 col-form-label required">First Name</label>
                <div class="col-12 col-sm-9">
                    <input type="text" class="form-control" formControlName="firstname" placeholder="First name"  [ngClass]="{'is-invalid' : form.get('contact.firstname').invalid && form.get('contact.firstname').touched}">
                    <div class="invalid-feedback mt-1 text-11 w-auto">
                        <ng-container *ngIf="form.get('contact.firstname').errors?.required; else fn2charTemplate">
                            Enter first name
                        </ng-container>
                        <ng-template #fn2charTemplate>
                            First Name should be at least two characters long
                        </ng-template>
                    </div>
                </div>
            </div>

            <div class="form-group row mb-3">
                <label class="col-sm-3 col-form-label required">Last Name</label>
                <div class="col-12 col-sm-9">
                    <input type="text" class="form-control" formControlName="surname" placeholder="Last name"  [ngClass]="{'is-invalid' : form.get('contact.surname').invalid && form.get('contact.surname').touched}">
                    <div class="invalid-feedback mt-1 text-11 w-auto">
                        <ng-container *ngIf="form.get('contact.surname').errors?.required; else ln2charTemplate">
                            Enter last name
                        </ng-container>
                        <ng-template #ln2charTemplate>
                            Last Name should be at least two characters long
                        </ng-template>
                    </div>
                </div>
            </div>


            <div class="form-group row mb-3">
                <label class="col-sm-3 col-form-label required">Email Address</label>
                <div class="col-12 col-sm-9">
                    <input type="email" autocomplete="new-password" class="form-control" formControlName="email" placeholder="Email"  [ngClass]="{'is-invalid' : form.get('contact.email').invalid && form.get('contact.email').touched}">
                    <div class="invalid-feedback mt-1 text-11 w-auto">
                        <ng-container *ngIf="form.get('contact.email').errors?.required; else validEmailTemplate">
                            Enter email address
                        </ng-container>
                        <ng-template #validEmailTemplate>
                            Please provide a valid email address
                        </ng-template>
                    </div>
                    <div style="padding-top: 10px">
                        <i class="">This email will also be used when logging in</i>
                    </div>
                </div>
            </div>


            <div class="form-group row mb-3">
                <label class="col-sm-3 col-form-label required">Mobile</label>
                <div class="col-12 col-sm-9">
                    <input type="text" class="form-control" formControlName="mobile" placeholder="Mobile"  [ngClass]="{'is-invalid' : form.get('contact.mobile').invalid && form.get('contact.mobile').touched}">
                    <div class="invalid-feedback mt-1 text-11 w-auto">
                        <ng-container *ngIf="form.get('contact.mobile').errors?.required; else mobileTemplate">
                            Enter mobile number
                        </ng-container>
                        <ng-template #mobileTemplate>
                            Please provide a valid mobile number
                        </ng-template>
                    </div>
                </div>
            </div>

            <div class="form-group row mb-3">
                <label class="col-sm-3 col-form-label ">Landline</label>
                <div class="col-12 col-sm-9">
                    <input type="text" class="form-control" formControlName="landline" placeholder="Landline" [ngClass]="{'is-invalid' : form.get('contact.landline').invalid && form.get('contact.landline').touched}">
                    <div class="invalid-feedback mt-1 text-11 w-auto">
                        Please provide a valid landline
                    </div>
                </div>
            </div>

        </div>

        <div style="padding-top: 10px; padding-bottom: 30px">
            <a (click)="toggleValue('marketing')">
                <i class="far fa-check-square" *ngIf="user.marketing"></i>
                <i class="far fa-square" *ngIf="!user.marketing"></i>
                Subscribe to receive marketing emails
            </a>
        </div>

        <div class="text-left" style="padding-top: 30px">
            
            <button type="submit" class="btn btn-round btn-primary"  [disabled]="!form.valid">
                Save Changes
            </button>
        </div>

    </form>

</div>
</div>


