import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { distinct, filter, map, mergeMap } from 'rxjs/operators';
import { ToolsService } from './services/tools.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from './store';
import { OverlayService } from './services/overlay.service';
import { DOCUMENT } from '@angular/common';
import { ChatService } from './services/chat.service';
import { UserService } from './services/user.service';
import { CookieService } from './services/cookie.service';
import { environment as env } from '../environments/environment';
import { AlertService } from './services/alert.service';
import { AlertConfirmData, defaultAlertConfirmData } from './data-types/alert-data';
import { NotificationService } from './services/notification.service';
import { ChatRESTService } from './chat/services/chat-rest.service';
import { ChatGroupLogicService } from './chat/services/chat-group-logic.service';
import { ChatSocketService } from './chat/services/chat-socket.service';

@UntilDestroy()
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	overlays$ = this.store.select<any>('overlays');
	deviceType$ = this.store.select<string>('deviceType');
	mobileMenu$ = this.store.select<boolean>('mobileMenu');
	mobileMenuAccount$ = this.store.select<boolean>('mobileMenuAccount');
	mobileMenuUser$ = this.store.select<boolean>('mobileMenuUser');

	title = 'supplier';
	currentURL = '';
	asideOpen$ = this.store.select<boolean>('asideOpen');
	alerts$ = this.store.select<any>('alerts');
	userId = '';
	sessionId = '';
	user$ = this.store.select<any>('user');
	step = 'dashboard';

	fullScreen = false;

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.getDeviceSize();
	}

	@HostListener('keydown.escape', ['$event']) onKeydownHandler() {
		this.overlayService.closeAll();
	}

	@HostListener('window:beforeunload', ['$event'])
	unloadHandler(event: Event) {
		this.chatService.sendOfflineSignal();
	}

	constructor(
		private store: Store,
		private router: Router,
		private route: ActivatedRoute,
		private cookieService: CookieService,
		private toolsService: ToolsService,
		private deviceService: DeviceDetectorService,
		private overlayService: OverlayService,
		private chatService: ChatService,
		private userService: UserService,
		private notificationService: NotificationService,
		private chatRESTService: ChatRESTService,
		private chatGroupLogicService: ChatGroupLogicService,
		private chatSocketService: ChatSocketService,
		@Inject(DOCUMENT) private document: Document
	) {
		router.events.subscribe((val) => {
			if (val instanceof NavigationEnd) {
				let activeRoute = val.url.replace(/^\/|\/$/g, '');
				this.overlayService.closeAll();

				if (activeRoute === '') {
					activeRoute = 'home';
					this.router.navigate(['/account']);
				}

				this.store.set('activeRoute', activeRoute);
				const navigationHistory = this.store.selectForLocal('navigationHistory');
				this.currentURL = val.urlAfterRedirects;

				if (
					val.urlAfterRedirects !== '/login' &&
					val.urlAfterRedirects !== '/register' &&
					val.urlAfterRedirects !== '/account/confirmation'
				) {
					navigationHistory.unshift(val.urlAfterRedirects);
				}
				this.store.set('navigationHistory', navigationHistory);
				// this.canonicalService.setCanonicalURL();
			}
		});

		this.epicFunction();
	}

	private intervalNr = null;

	ngOnInit() {
		this.setDomain();
		this.checkCookie();
		this.getDeviceSize();
		this.store.set('innerWidth', window.innerWidth);

		this.sessionId = this.toolsService.sessionId();
		this.store.set('sessionId', this.sessionId);

		this.cookieService.check();
		if (this.cookieService.checkUser()) {
		}
		this.store
			.select<any>('user')
			.pipe(
				distinct<any, string>((user) => user.id),
				untilDestroyed(this)
			)
			.subscribe((user) => {
				if (user) {
					this.notificationService.getNotifications(user).subscribe();
					this.notificationService.listenToNotifications(user);
					this.intervalNr = setInterval(() => {
						this.chatService.sendOnlineSignal();
					}, 5000);
					this.chatRESTService.getChatList().subscribe((chats) => {
						this.chatGroupLogicService.chats = chats;
					});
					this.chatSocketService.setupListenSocketEvents();
				} else {
					this.notificationService.stopListening();
					clearInterval(this.intervalNr);
				}
			});

		if (!this.cookieService.getBasket()) {
			const ysBasket = localStorage.getItem('ysBasket');
			if (ysBasket) {
				this.store.set('basket', JSON.parse(ysBasket));
			}
		}

		this.userService.logSession(this.userId, this.sessionId).subscribe();

		// this.authService.authTimeout();
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				// TODO set this live

				const navigationHistory = this.store.selectForLocal('navigationHistory');

				if (
					event.urlAfterRedirects !== '/login' &&
					event.urlAfterRedirects !== '/register' &&
					event.urlAfterRedirects !== '/account/confirmation' &&
					event.urlAfterRedirects !== '/password/confirmation'
				) {
					navigationHistory.unshift(event.urlAfterRedirects);
				}

				this.store.set('navigationHistory', navigationHistory);
			}
		});
		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				map(() => this.route),
				map((route) => {
					if (route.firstChild) {
						return route.firstChild;
					}
				}),
				filter((route) => route.outlet === 'primary'),
				mergeMap((route) => route.data)
			)
			.subscribe((event) => {
				if (event.protected) {
					this.store.set('protectedRoute', event.protected);
				} else {
					this.store.set('protectedRoute', false);
				}
			});

		// if (!this.store.selectForLocal('user')) {
		//     this.router.navigate(['account']);
		// }
		!env.production && console.log(this.store);
	}

	checkCookie() {
		let cookieEnabled = navigator.cookieEnabled;
		if (!cookieEnabled) {
			document.cookie = 'testcookie';
			cookieEnabled = document.cookie.indexOf('testcookie') !== -1;
		}
		return cookieEnabled || alert('Cookies must be enabled for site functionality. Please enable cookies');
	}

	epicFunction() {
		const device = {
			info: this.deviceService.getDeviceInfo(),
			isMobile: this.deviceService.isMobile(),
			isTablet: this.deviceService.isTablet(),
			isTabletV: this.deviceService.isTablet(),
			isTabletH: false,
			isDesktopDevice: this.deviceService.isDesktop(),
			mobileVersion: true,
		};
		if (this.deviceService.isDesktop()) {
			device.mobileVersion = false;
			this.store.set('deviceType', 'desktop');
		}
		const innerWidth = window.innerWidth;
		if (device.isTablet || device.isMobile) {
			if (innerWidth > 767) {
				device.mobileVersion = true;
				device.isMobile = false;
				device.isTablet = true;
				device.isTabletV = true;
				device.isTabletH = false;
			}
			if (innerWidth > 1020) {
				device.mobileVersion = false;
				device.isMobile = false;
				device.isTablet = true;
				device.isTabletV = false;
				device.isTabletH = true;
			}
		}

		this.store.set('device', device);
	}

	getDeviceSize() {
		let deviceType = this.store.selectForLocal('deviceType');

		// Todo add innerHeight for mobile landscape breakpoints detection
		if (window.innerWidth <= 576) {
			deviceType = 'mobile';
		}
		if (window.innerWidth > 576 && window.innerWidth <= 768) {
			deviceType = 'tabletV';
		}
		if (window.innerWidth > 768 && window.innerWidth <= 1200) {
			deviceType = 'tabletH';
		}
		if (window.innerWidth > 1200) {
			deviceType = 'desktop';
		}
		this.store.set('innerWidth', window.innerWidth);
		this.store.set('deviceType', deviceType);
	}

	closeAll() {
		this.overlayService.closeAll();
	}

	navigationChange(e) {
		this.step = e;
	}

	setDomain() {
		const domainId = localStorage.getItem('activeDomain');
		this.store.set('domain', domainId ? domainId : 'ys');
	}
}
