
<div class="table-responsive">
    <table class="table" width="100%">
        <tbody>
            <tr class="hover" (click)="toggleGiftType(item)" style="border-bottom: 1px solid #EEE" *ngFor="let item of items">
                <td style="width: 50px">
                    <i class="fas fa-square" style="font-size: 22px" *ngIf="item.selected"></i>
                    <i class="far fa-square" style="font-size: 22px" *ngIf="!item.selected"></i>
                </td>
                <td>{{item.name}}</td>
            </tr>
        </tbody>
    </table>
</div>
