<div class="spinner-border" [class]="classList"
*ngIf="(spinnerConfig.showSpinner || isVisible) && !spinnerConfig.isGlobalSpinner && !spinnerConfig.ldsLoader">
    <span class="sr-only"></span> 
</div>
<div class="spinner-border global-spinner"
*ngIf="(spinnerConfig.showSpinner || isVisible) && spinnerConfig.isGlobalSpinner === true">
    <span class="sr-only"></span>
    
</div>
<div *ngIf="(spinnerConfig.showSpinner || isVisible) &&  spinnerConfig.ldsLoader === true" class="lds-ellipsis">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
</div>