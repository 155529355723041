import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { filter, take } from 'rxjs';
import { Store } from 'src/app/store';
import { EChatEventName, EChatStoreKey } from '../enums';
import { MessageMapper } from '../mappers';
import { IChatMember, IChatMessage } from '../models';
import { ChatEventsService } from './chat-events.service';
import { ToastrService } from 'ngx-toastr';
import { ChatGroupLogicService } from './chat-group-logic.service';

@Injectable({
	providedIn: 'root',
})
export class ChatSocketService {
	domain = this.store.selectForLocal('domain');
	user = this.store.selectForLocal('user');

	private chatRooms = ['sycamore'];

	constructor(
		private socket: Socket,
		private chatEventsService: ChatEventsService,
		private store: Store,
		private toastr: ToastrService,
		private chatGroupLogicService: ChatGroupLogicService
	) {
		this.setupUserRoom();
	}

	public joinRoom(roomName: string) {
		if (this.chatRooms.indexOf(roomName) === -1) {
			this.chatRooms.push(roomName);
		}

		this.socket.emit('join', { domain: this.domain, room: roomName });
	}

	public leaveRoom(roomName: string) {
		if (this.chatRooms.indexOf(roomName) !== -1) {
			this.chatRooms.splice(this.chatRooms.indexOf(roomName), 1);
		}

		this.socket.emit('leave', { domain: this.domain, room: roomName });
	}

	private setupUserRoom() {
		this.store
			.select<any>('user')
			.pipe(
				filter((user) => user),
				take(1)
			)
			.subscribe((user) => {
				this.joinRoom(user.id);
			});
	}

	public setupListenSocketEvents() {
		this.socket.on('chatMessageAdded', (message) => {
			const chatStore = this.store.selectForLocal('chatStore');
			chatStore.unread++;
			this.store.set('chatStore', chatStore);

			// no need to notify about welcome message
			if (message.type === 'welcome') return;

			this.chatEventsService.emitEvent(EChatEventName.MessageAdded, MessageMapper.toDomain(message));
		});

		this.socket.on('didMessageEdit', (message) => {
			this.chatEventsService.emitEvent(EChatEventName.MessageEdited, {
				message,
			});
		});

		this.socket.on('didMessageDelete', (message) => {
			this.chatEventsService.emitEvent(EChatEventName.MessageDeleted, {
				message: MessageMapper.toDomain(message),
			});
		});
	}
}
