import { EChatType } from '../enums';
import { IChat } from '../models';
import { ChatMemberMapper } from './chat-member.mapper';
import { MessageMapper } from './message.mapper';

export class ChatMapper {
    public static toDomain(dto: any): IChat {
        return {
            ...dto,
            id: parseInt(dto.id),
            members: dto.users?.map((u) => ChatMemberMapper.toDomain(u)),
            messages: dto.messages.map((message) => MessageMapper.toDomain(message)),
            lastAction: new Date(dto.lastAction),
            mode: dto.users?.length > 2 || dto.type === 'Channel' ? EChatType.Channel : EChatType.DefaultChat,
        };
    }
}
