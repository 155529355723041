<div class="chat-panel" *ngIf="chat.id !== undefined && !loading">
  <div class="messages-area-holder">
    <app-chat-messages-area [chat]="chat"></app-chat-messages-area>
  </div>
  <div class="editor-area-holder">
    <app-chat-editor [openedChat]="chat"></app-chat-editor>
  </div>
</div>
<div class="empty-cont" *ngIf="chat.id === undefined">Select Chat</div>
<div class="empty-cont" *ngIf="chat.id && loading">Loading chat...</div>
