import {
  ComponentFactoryResolver,
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";
import { isBoolean, isNil } from "lodash";
import { InlineSpinnerComponent } from "../../shared/inline-spinner/inline-spinner.component";

interface YssIsLoading {
  isLoading: boolean;
  isCentered?: boolean;
  classList?: string;
}
@Directive({
  selector: "[yssIsLoading]",
})
export class LoadingDirective {
  @Input()
  set yssIsLoading(data: boolean | YssIsLoading) {
    if (isNil(data)) {
      return;
    }
    const isLoading = isBoolean(data) ? data : (data as YssIsLoading).isLoading;
    if (isLoading) {
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(
          InlineSpinnerComponent
        );
      this.view.clear();
      const componentRef = this.view.createComponent(componentFactory);
      componentRef.instance.isVisible = true;
      if (!isBoolean(data)) {
        const config = data as YssIsLoading;
        if (config.isCentered) {
          componentRef.location.nativeElement.classList.add("yss-loading");
        }
        if (config.classList) {
          componentRef.instance.classList = config.classList;
        }
      }
    } else {
      this.view.clear();
      this.view?.createEmbeddedView(this.template);
    }
  }
  constructor(
    private view: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private template: TemplateRef<AnimationPlayState>
  ) {}
}
