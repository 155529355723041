<section id="header" class="header sticky-top bg-white">
    <div class="container-fluid px-xl-5">
        <div class="header__content d-flex align-items-center">
            <div class="d-flex flex-column flex-1" style="padding: 15px 0;">
                <a class="header-sub-item-icon d-block d-xxl-none" (click)="toggleMenu()">
                    <svg-icon src="assets/images/icon-menu.svg"></svg-icon>
                </a>
            </div>

            <div class="d-flex flex-1 justify-content-center" style="padding-left: 20px">
                <a [routerLink]="['/']">
                    <svg-icon src="assets/images/ys-logo-text.svg" [svgClass]="'header__logo-img'"></svg-icon>
                </a>
            </div>

            <div *ngIf="(user$ | async)" class="d-flex flex-1 justify-content-end align-items-center text-end">
                <a appDropdown href="javascript:void(0)" class="header-sub-item-icon dropdown" >
                    <svg-icon src="assets/images/icon-user.svg"></svg-icon>
                    <div class="dropdown-menu dropdown-menu-end dropdown-menu-user shadow-sm px-4 py-3">
                        <a href="https://www.yorkstonesupplies.co.uk" class="dropdown-item fw-bold small px-0 mb-2 text-primary custom-color"
                           
                           
                        >
                        Back to homepage
                        </a>
                        <a  class="dropdown-item fw-bold small px-0 mb-2 text-primary custom-color"
                            [routerLink]="['/account']"
                            
                        >
                        My Account
                        </a>
                        <div class="dropdown-divider mt-0 mb-2"></div>
                       
                        <a (click)="logoutConfirmation()"
                        class="dropdown-item fw-bold  small px-0 text-primary custom-color">
                         Sign out
                     </a>
                    </div>
                </a>
                <a appDropdown class="header-sub-item-icon dropdown me-0" href="javascript:void(0)">
                    <svg-icon src="assets/images/icon-bell.svg"></svg-icon>
                    <div *ngIf="(notifications$ | async).unread > 0"
                         class="badge-count">{{(notifications$ | async).unread}}</div>
                    <div class="dropdown-menu dropdown-menu-end dropdown-menu-notifications shadow-sm">
                        <app-notifications-list></app-notifications-list>
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>

<app-confirmation *ngIf="confirmationData.open" [data]="confirmationData"
                  (complete)="confirmationComplete($event)"></app-confirmation>
