import { ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { UserAccountType, UserData } from 'src/app/data-types/user-data';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-avatar',
	templateUrl: './avatar.component.html',
	styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnChanges {
	@Input() user: UserData;
	@Input() fontSize: string = '12px';
	@Input() fontWeight: string = '600';
	@Input() detectOnline: boolean = true;
	@Input() border: string;
	@Input() showIcons: boolean = true;
	@Input() onlineCb: (user: any) => boolean = (user) => this.userService.userOnline(user.id);
	@Input() darkIcon = false;

	avatarColour = '#2e797e';

	get avatarIcon(): string | null {
		if (!this.showIcons) return null;

		let pathToIcons;
		if (this.darkIcon) {
			pathToIcons = 'assets/images/chat/user-icons-dark/';
		} else {
			pathToIcons = 'assets/images/chat/user-icons/';
		}

		switch (this.user.accountType) {
			case UserAccountType.Trade:
				if (this.user.isPremium) {
					return pathToIcons + 'trade-premium.svg';
				} else {
					return pathToIcons + 'trade.svg';
				}
			case UserAccountType.Customer:
				return pathToIcons + 'private.svg';
			case UserAccountType.Guest:
				return pathToIcons + 'unknown.svg';

			default:
				return null;
		}
	}

	imagePlaceholder = '';
	constructor(private userService: UserService) {}

	ngOnChanges() {
		this.setProperties();
	}

	isOnline() {
		return this.onlineCb(this.user);
	}

	public setProperties() {
		let firstname = (<any>this.user).firstname;
		let surname = (<any>this.user).surname;

		// for compatibility with global user object
		if (!firstname && !surname && (this.user as any).contact.firstname) {
			firstname = (this.user as any).contact.firstname;
			surname = (this.user as any).contact.surname;
		}

		if (firstname && surname) {
			this.imagePlaceholder = `${firstname.charAt(0)?.toUpperCase()}${surname.charAt(0)?.toUpperCase()}`;
		}

		if ((<any>this.user).avatarColour) {
			this.avatarColour = (<any>this.user).avatarColour;
		}
	}
}
