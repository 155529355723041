<ng-container *yssIsLoading="isLoading">
    <div class="chat-groups-menu-holder">
        <app-chat-side-menu
            [openedChat]="openedChat"
            [domainClass]="domainClass"
            (chatModeChanged)="mode = $event"></app-chat-side-menu>
    </div>

    <div
        *ngIf="mode === EChatMode.Active"
        class="chat-groups-content-holder">
        <app-chat-panel
            [chat]="openedChat"
            [loading]="!!loadingChatSubsciption"></app-chat-panel>
    </div>
    <div
        *ngIf="mode === EChatMode.Archive"
        class="chat-groups-content-holder">
        <app-chat-archive></app-chat-archive>
    </div>
</ng-container>
