import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-order-board-status-update',
    templateUrl: './order-board-status-update.component.html',
    styleUrls: ['./order-board-status-update.component.scss']
})
export class OrderBoardStatusUpdateComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
