import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '../../store';
import { OrdersService } from '../../services/orders.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-order-board-popup',
    templateUrl: './order-board-popup.component.html',
    styleUrls: ['./order-board-popup.component.scss'],
})
export class OrderBoardPopupComponent implements OnInit {
    @Input() card;
    @Input() col;
    @Input() params;
    @Input() idx;
    @Input() boardView;
    @Output() updateRequested = new EventEmitter();

    showAssignOrderConfirmation = false;
    showConfirmOrderConfirmation = false;
    showConfirmOrderProduction = false;
    showReadyForCollection = false;
    showConfirmInTransit = false;
    showConfirmCompleted = false;

    constructor(
        private store: Store,
        private ordersService: OrdersService,
        private router: Router
    ) {
    }

    ngOnInit(): void {
    }

    onClickOutside(event) {
        if (event.value) {
            this.closeAllPopups('');
        }
        // if (event && event['value'] === true) {
        //     this.active = false;
        // }
    }

    setStatus(v) {
        this.card.status.id = v;
        this.ordersService
            .updateOrderLineStatus(this.card)
            .subscribe(() => {
                this.closeAllPopups('');
                this.ordersService
                    .searchOrderDetailBoard(this.params, this.boardView)
                    .subscribe();
            });
    }

    edit() {
        this.router.navigate(['orders', this.card.orderId]);
    }

    confirmCancel() {
    }

    closeAllPopups(toIgnore) {
        this.ordersService.orderDetailBoard.value.cols.forEach((c) => {
            c.orderLines.forEach((ol) => {
                if (ol.id !== toIgnore) {
                    ol.open = false;
                }
            });
        });
    }

    requestUpdate() {
        this.closeAllPopups('');
        this.updateRequested.emit(this.card);
    }
}
