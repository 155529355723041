<div class="" style="width: 100%">
        <div class="mt-4 mb-4" *ngIf="(ordersService.orderDetailBoard | async)">
            <div class=" d-flex justify-content-between align-items-stretch">
                <div class="board-col" *ngFor="let c of (ordersService.orderDetailBoard | async).cols; let ci = index">

                    <ng-container [ngSwitch]="boardView">
                        <h5 class="title" *ngSwitchCase="'progress'">{{ c.supplierText }}</h5>
                        <h5 class="title" *ngSwitchCase="'update'">{{c.title}}</h5>
                        <ng-container *ngSwitchCase="'due'">
                            <h5 class="title" *ngIf="c.minDays < 0"> < {{c.maxDays}} Days</h5>
                            <h5 class="title" *ngIf="c.minDays > 0 && c.maxDays < 100">{{c.minDays}} - {{c.maxDays}} Days</h5>
                            <h5 class="title" *ngIf="c.maxDays > 100"> > 28 Days</h5>
                        </ng-container>
                    </ng-container>

                    <div class="card-holder">
                        <app-order-board-card
                                [card]="card"
                                [col]="c"
                                [colIndex]="ci"
                                [boardView]="boardView"
                                [params]="params"
                                *ngFor="let card of c.orderLines"
                                (updateOrder)="showOrderUpdateModal($event)"
                        ></app-order-board-card>
                    </div>
                </div>
            </div>
        </div>
</div>
<ng-template #editTmpl>
    <h4>Request Update</h4>
    <form novalidate class="mb-4" [formGroup]="updateForm">
        <div class="ff-spectral fw-bold mb-1">
            Reason for update
        </div>
        <ng-select formControlName="reason"
                   [clearable]="true"
                   [searchable]="false"
                   [placeholder]="'Please select a reason'">
            <ng-option [value]="r" *ngFor="let r of requestUpdateReasons">{{ r.name }}</ng-option>
        </ng-select>
        <ng-container *ngIf="updateForm.value.reason && updateForm.value.reason.id === 1">
            <div class="ff-spectral fw-bold mt-3 mb-1">
                Enter reason
            </div>
            <input class="form-control bordered" formControlName="comment" />
            <div class="ff-spectral fw-bold mt-3 mb-1">
                Comment
            </div>
            <textarea formControlName="customReason"
                      class="form-control mb-4"
                      rows="5"></textarea>
        </ng-container>
    </form>
</ng-template>
