import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material.module';
import { OrderSearchComponent } from './order-search/order-search.component';
import { SharedModule } from '../shared/shared.module';
import { OrderDetailSearchComponent } from './order-detail-search/order-detail-search.component';
import { OrderStatusUpdateComponent } from './order-status-update/order-status-update.component';
import { DirectivesModule } from '../directives/directives.module';
import { OrderBoardComponent } from './order-board/order-board.component';
import { OrderBoardPopupComponent } from './order-board-popup/order-board-popup.component';
import { OrderBoardDateviewComponent } from './order-board-dateview/order-board-dateview.component';
import { OrderBoardStatusUpdateComponent } from './order-board-status-update/order-board-status-update.component';
import { OrderBoardContainerComponent } from './order-board-container/order-board-container.component';
import { PipesModule } from '../pipes/pipes.module';
import { OrderBoardCardComponent } from './order-board-card/order-board-card.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
    declarations: [
        OrderSearchComponent,
        OrderDetailSearchComponent,
        OrderStatusUpdateComponent,
        OrderBoardComponent,
        OrderBoardPopupComponent,
        OrderBoardDateviewComponent,
        OrderBoardStatusUpdateComponent,
        OrderBoardContainerComponent,
        OrderBoardCardComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        SharedModule,
        MaterialModule,
        DirectivesModule,
        PipesModule,
        NgSelectModule,
    ],
    exports: [
        OrderSearchComponent,
        OrderDetailSearchComponent,
        OrderStatusUpdateComponent,
        OrderBoardComponent,
        OrderBoardPopupComponent,
    ],
})
export class OrdersModule {
}
