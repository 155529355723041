import { NgModule } from '@angular/core';
import { DateAgoPipe } from './date-ago.pipe';
import { SafeHTMLPipe } from './safe-html.pipe';
import { StripHtmlPipe } from './strip-html.pipe';
import { LeadtimePipe } from './leadtime.pipe';
import { ConsolePipe } from './console.pipe';

@NgModule({
    declarations: [
        SafeHTMLPipe,
        DateAgoPipe,
        StripHtmlPipe,
        LeadtimePipe,
        ConsolePipe
    ],
    imports: [],
    exports: [
        DateAgoPipe,
        SafeHTMLPipe,
        StripHtmlPipe,
        LeadtimePipe,
        ConsolePipe
    ],
})
export class PipesModule {
}
