import { Component, Input } from "@angular/core";
import { SpinnerConfig } from "src/app/models/spinner-config";

@Component({
  selector: "app-inline-spinner",
  templateUrl: "./inline-spinner.component.html",
  styleUrls: ["./inline-spinner.component.scss"],
})
export class InlineSpinnerComponent {
  @Input() set spinnerConfig(spinnerConfig: any) {
    this.config = spinnerConfig;
  }

  get spinnerConfig(): SpinnerConfig {
    return this.config;
  }

  @Input() isVisible = false;
  @Input() classList: string;

  config: SpinnerConfig = {
    isGlobalSpinner: false,
    showSpinner: false,
    ldsLoader: false,
  } as SpinnerConfig;
}
