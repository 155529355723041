import { OverlayService } from '../../services/overlay.service';
import {
    Component,
    HostListener,
    Input,
    OnInit,
    SimpleChanges,
    OnChanges,
    ViewChild,
    TemplateRef
} from '@angular/core';
import { OrdersService } from '../../services/orders.service';
import { Store } from '../../store';
import { debounce } from 'lodash';
import { UserService } from '../../services/user.service';
import { CustomAlertData, DefaultCustomAlertData } from '../../data-types/alert-data';
import { AlertService } from '../../services/alert.service';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
    selector: 'app-order-board',
    templateUrl: './order-board.component.html',
    styleUrls: ['./order-board.component.scss'],
})
export class OrderBoardComponent implements OnInit, OnChanges {
    @Input() supplierId;
    @Input() boardView;

    @ViewChild('editTmpl', { read: TemplateRef }) editTmpl: TemplateRef<any>;

    updateForm = this.fb.group({
        reason: [null, Validators.required],
        customReason: [null],
        comment: [null]
    });

    updateRequested = false;
    requestUpdateReasons = [];

    params = {
        dashboard: false,
        customer: '',
        orderId: '',
        invoiceId: '',
        supplierId: '',
        supplier: '',
        status: '',
        allocatedTo: '',
        createdAt: undefined,
        dueDate: undefined,
        shippingStatus: '',
        freeText: '',
        thisWeek: false,
        thisMonth: false,
        limit: 9999,
        limits: [25, 50, 75, 100],
        page: 0,
        pages: 0,
        pageArray: [],
        totalRecords: 0,
        sort: 'orders.dueDate',
        sorts: [],
    };

    admins = [];

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
        event: KeyboardEvent
    ) {
        this.closeAllPopups('');
        this.requestUpdateCompleted({ refresh: false });
    }

    constructor(
        private store: Store,
        private fb: FormBuilder,
        public ordersService: OrdersService,
        private userService: UserService,
        private overlayService: OverlayService,
        private alertService: AlertService
    ) {
        this.filterSearch = debounce(this.filterSearch, 350);
    }

    ngOnInit(): void {
        this.params.supplierId = this.supplierId;
        // this.getOrderLines();
        this.getAdmins();
        this.ordersService.getUpdateRequestReasons().subscribe(data => this.requestUpdateReasons = data.data);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.boardView) {
            this.filterSearch();
        }
    }

    filterSearch() {
        this.getOrderLines();
    }

    getAdmins() {
        // this.userService.getAdmins().subscribe(data => {
        //     this.admins = data.data;
        // })
    }

    getOrderLines() {
        this.overlayService.toggle('processing');
        this.ordersService
            .searchOrderDetailBoard(this.params, this.boardView)
            .subscribe(() => this.overlayService.toggle('processing'));
    }

    assignBgCol(orderLine) {
        let bgCol = 'text-success';
        if (orderLine.dueDays < 3) {
            bgCol = 'text-danger';
        }
        if (orderLine.dueDays >= 3 && orderLine.dueDays < 5) {
            bgCol = 'text-warning';
        }
        return bgCol;
    }

    closeAllPopups(toIgnore) {
        this.ordersService.orderDetailBoard.value.cols.forEach(c => {
            c.orderLines.forEach(ol => {
                if (ol.id !== toIgnore) {
                    ol.open = false;
                }
            });
        });
    }

    requestUpdateCompleted(e) {
        this.updateRequested = false;
        if (e.refresh) {
            this.ordersService.searchOrderDetailBoard(this.params, this.boardView).subscribe();
        }
    }

    showOrderUpdateModal(card)
    {
        this.updateForm.reset();
        this.alertService
            .customAlert({
                    ...DefaultCustomAlertData,
                    template: this.editTmpl,
                    data: card,
                    cancelText: 'Cancel',
                    confirmText: 'Update',
                    confirmCb: this.doUpdate.bind(this),
                    canConfirmCb: this.canUpdate.bind(this),
                    /*cancelCb: this.cancelCancelled.bind(this)*/
                } as CustomAlertData
            );
    }

    private doUpdate(orderDetail): void
    {
        const updateRequest = {
            orderId: orderDetail.orderId,
            orderDetailIds: [orderDetail.id],
            reason: this.updateForm.controls.reason.value.id,
            newReason: this.updateForm.controls.customReason.value,
            comment: this.updateForm.controls.comment.value
        };

        this.ordersService
            .updateRequestUpdate(updateRequest)
            .subscribe(data => {
                console.log('need to reload', data);
            }
        );
    }

    private canUpdate(): boolean
    {
        return !!this.updateForm.controls.reason.value && (
            this.updateForm.controls.reason.value.id !== 1 ||
            (this.updateForm.controls.reason.value.id === 1
                && !!this.updateForm.controls.customReason.value
                && !!this.updateForm.controls.comment.value)
        );
    }
}
