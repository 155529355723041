import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { Store } from '../store';
import { ToolsService } from './tools.service';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { merge, ReplaySubject, Subject } from 'rxjs';
import { Socket } from 'ngx-socket-io';
import { isPlatformBrowser } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class NotificationService
{
    env = environment;

    query: any;
    private sound;
    private socket: Socket;
    private secretSocket: Socket;
    private publicSocket: Socket;
    private notification$: ReplaySubject<any>;
    private destroy$ = new Subject();

    constructor(
        private apiService: ApiService,
        private http: HttpClient,
        private store: Store,
        private toolsService: ToolsService,
        private toastrService: ToastrService,
        @Inject(PLATFORM_ID) private platformId: {},
        private injector: Injector,
    )
    {
        if (isPlatformBrowser(this.platformId)) {
            this.initSound();
            this.socket = this.injector.get<Socket>(Socket);
        }
    }

    getNotifications(user)
    {
        return this.http.get(this.env.apiPath + 'notifications/' + user.id)
            .pipe(
                map((data: any) =>
                    {
                        this.store.set('notifications', data.data);
                        return data;
                    }
                ));

    }

    listenToNotifications(user)
    {
        this.secretSocket = new Socket({ url: `${this.env.socketPath}${user.id}` });
        this.publicSocket = new Socket({ url: `${this.env.socketPath}supplier` });

        merge(
            this.publicSocket.fromEvent('notifications'),
            this.secretSocket.fromEvent('notifications')
        ).subscribe((data: any) =>
        {
            const notifications = this.store.selectForLocal('notifications');
            notifications.unread++;
            notifications.data.unshift(data);
            this.sound.play();
            this.store.set('notifications', notifications);
            this.toastrService.success(data.content);
        });

    }

    stopListening()
    {
        this.store.set('notifications', { unread: 0, data: [] });
        this.publicSocket?.disconnect();
        this.secretSocket?.disconnect();
    }

    send(notificationData)
    {
        const newId = this.toolsService.newUUID();
        notificationData.id = newId;
        notificationData.read = false;
        notificationData.deleted = false;
        notificationData.createdAt = new Date();
    }

    private initSound()
    {
        this.sound = document.createElement('audio');
        this.sound.src = '/assets/ding.mp3';
        this.sound.setAttribute('preload', 'auto');
        this.sound.setAttribute('controls', 'none');
        this.sound.style.display = 'none';
        this.sound.volume = 0.2;
        document.body.appendChild(this.sound);
    }

}
