import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EChatGroupType } from '../../enums/chat-group-type.enum';

@Component({
  selector: 'app-chat-type-switcher',
  templateUrl: './chat-type-switcher.component.html',
  styleUrls: ['./chat-type-switcher.component.scss']
})
export class ChatTypeSwitcherComponent implements OnInit {
  @Input() type: EChatGroupType  = EChatGroupType.ADMIN;
  @Output() switched = new EventEmitter<EChatGroupType>();

  EChatGroupType = EChatGroupType;

  constructor() { }

  ngOnInit(): void {
  }

  toggle(type: EChatGroupType ) {
    this.type = type;
    this.switched.emit(this.type)
  }
}
