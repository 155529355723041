
<div class="data-container">
    <div class="preview-box">
        <div class="preview-box-title">
            Open Orders
        </div>
        <div class="preview-box-content">
          
            <app-order-detail-search class="mt-4" [params]="orderParams" [showSupplier]="false" [showCustomer]="true"></app-order-detail-search>
            
        </div>
    </div>
</div>

<div class="data-container mt-50">
    <div class="preview-box">
        <div class="preview-box-title">
            Sample Orders
        </div>
        <div class="preview-box-content">
            <app-order-detail-search class="mt-4" [params]="sampleParams" [showSupplier]="false" [showCustomer]="true"></app-order-detail-search>
        </div>
    </div>
</div>

<div class="data-container mt-50">
    <app-product-search [params]="productParams" [showSupplier]="false"></app-product-search>
</div>

<div class="data-container mt-50">
    <div class="preview-box">
        <div class="preview-box-title">
            Your Users
        </div>
        <div class="preview-box-content">
            <app-user-search [showCompany]="false" [company]="user.company"></app-user-search>
        </div>
    </div>
</div>

<div class="mt-4">
    <a class="hover" (click)="requestCreate()">
        <img src="/assets/images/requests.png" class="img-fluid" />
    </a>
</div>

<app-account-user-edit *ngIf="(overlays$ | async).userEdit"></app-account-user-edit>

