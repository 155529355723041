import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import * as moment from 'moment';
import { CustomDatepickerHeader } from './custom-dp-header/custom-datepicker-header';


export interface IChatReminderDlgResult {
  datetime: Date;
  comment?: string;
}
@UntilDestroy()
@Component({
  selector: 'app-message-reminder-dlg',
  templateUrl: './message-reminder-dlg.component.html',
  styleUrls: ['./message-reminder-dlg.component.scss']
})
export class MessageReminderDlgComponent implements OnInit {

  customDatepickerHeader = CustomDatepickerHeader;

  timeSelected: any;
  commentText = new FormControl();
  moments = [];

  form = this.fb.group({
    date: ["", [Validators.required]],
    time: ["", [Validators.required]],
    comment: [""]
  });
  
  todayDate: Date = new Date();
  
  get dateSelected(): boolean {
    return !!this.form.value.date
  }

  constructor(
    public dialogRef: MatDialogRef<MessageReminderDlgComponent>,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((values) => {
        let currentTime = moment(values.date);
        let endTime =  moment(values.date).add(1, 'days');
        if(currentTime.isSame(new Date(), "day")) {
          // for same day add from next half of hour
          currentTime = moment(new Date (Math.ceil(new Date().getTime() / (1000 * 30 * 60) ) * 30 * 60 * 1000));
        }
        this.moments = []; 
        while (currentTime.isBefore(endTime)) {
          this.moments.push(
            {
              moment: currentTime.toDate(),
              label: currentTime.format('LT')
            });
          currentTime.add(30, 'm');
        }
      });
  }

  create() {
    this.dialogRef.close({
      datetime: this.form.value.time,
      comment: this.form.value.comment,
    });
  }
}
