import { ProductModule } from './product/product.module';
import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Store } from './store';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { MenuMobileComponent } from './layout/menu-mobile/menu-mobile.component';
import { LoginComponent } from './auth/login/login.component';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { UserModule } from './user/user.module';
import { SignoutComponent } from './auth/signout/signout.component';
import { PasswordResetComponent } from './auth/password-reset/password-reset.component';
import { PasswordSetComponent } from './auth/password-set/password-set.component';
import {
    PasswordResetConfirmationComponent
} from './auth/password-reset-confirmation/password-reset-confirmation.component';
import { PasswordSetConfirmationComponent } from './auth/password-set-confirmation/password-set-confirmation.component';
import { LoginFormComponent } from './auth/login-form/login-form.component';
import { OrdersModule } from './orders/orders.module';
import { SharedModule } from './shared/shared.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { GooglePlacesDirective } from './shared/directives/google-places.directive';
import { ShipmentsModule } from './shipments/shipments.module';
import { PaymentsModule } from './payments/payments.module';
import { ChatModule } from './chat/chat.module';
import { MailModule } from './mail/mail.module';
import { ToastrModule } from 'ngx-toastr';
import { LoginModalComponent } from './auth/login-modal/login-modal.component';
import { RequestCreateComponent } from './user/requests/request-create/request-create.component';
import { HeaderMobileComponent } from './layout/header-mobile/header-mobile.component';
import { FooterMobileComponent } from './layout/footer-mobile/footer-mobile.component';
import { FooterIpadComponent } from './layout/footer-ipad/footer-ipad.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { NgSelectModule } from '@ng-select/ng-select';
import { CookieModule } from 'ngx-cookie';
import { PipesModule } from './pipes/pipes.module';
import { HttpRequestInterceptor } from '../http-request.interceptor';
import { YsErrorHandler } from '../YsErrorHandler';
import { FloatingSpinnerComponent } from './layout/floating-spinner/floating-spinner.component';
import { DashboardsModule } from './dashboards/dashboards.module';
import {ErrorRequestInterceptor} from '../error-request.interceptor';
import {AppNameInterceptor} from '../app-name.interceptor';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        FloatingSpinnerComponent,
        MenuMobileComponent,
        LoginComponent,
        LoginModalComponent,
        SignoutComponent,
        PasswordResetComponent,
        PasswordResetConfirmationComponent,
        PasswordSetComponent,
        PasswordSetConfirmationComponent,
        LoginFormComponent,
        GooglePlacesDirective,
        RequestCreateComponent,
        HeaderMobileComponent,
        FooterMobileComponent,
        FooterIpadComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        UserModule,
        OrdersModule,
        PaymentsModule,
        ProductModule,
        SharedModule,
        CookieModule.forRoot(),
        GoogleMapsModule,
        ShipmentsModule,
        ChatModule,
        MailModule,
        AngularSvgIconModule.forRoot(),
        ToastrModule.forRoot({
            preventDuplicates: true,
            progressBar: true,
            closeButton: true,
        }),
        PopoverModule.forRoot(),
        BsDatepickerModule.forRoot(),
        NgSelectModule,
        PipesModule,
        DashboardsModule
    ],
    providers: [
        Store,
        { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true},
        { provide: HTTP_INTERCEPTORS, useClass: ErrorRequestInterceptor, multi: true},
        { provide: HTTP_INTERCEPTORS, useClass: AppNameInterceptor, multi: true},
        { provide: ErrorHandler, useClass: YsErrorHandler }
    ],
    exports: [],
    bootstrap: [AppComponent],
})
export class AppModule {
}
