import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../environments/environment';
import { Store } from '../store';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class OrdersService {

    orderDetailBoard$: BehaviorSubject<any> = new BehaviorSubject(null);
    updateRequestReasonsCache$: ReplaySubject<any>;

    constructor(
        private apiService: ApiService,
        private http: HttpClient,
        private store: Store,
    ) {
    }

    searchOrderDetailBoard(params, endpoint): Observable<any>
    {
        return this.http.post(
            env.apiPath + 'orders/search/orderdetail/board/supplier/' + endpoint,
            { params }
        )
        .pipe(
            tap(data => this.orderDetailBoard$.next(data.data))
        );
    }

    get orderDetailBoard(): BehaviorSubject<any>
    {
        return this.orderDetailBoard$;
    }

    updateOrderLineStatus(orderLine) {
        const dataToSend = {
            orderLine,
            user: this.store.selectForLocal('user'),
        };
        return this.http.put(env.apiPath + 'orders/line/status', dataToSend)
            .pipe(map((data: any) => data));
    }

    getOrder(id) {
        return this.http.get(env.apiPath + 'orders/' + id)
            .pipe(map((data: any) => data));
    }

    getOrderLineStatuses() {
        return this.http.get(env.apiPath + 'orders/line/statuses')
            .pipe(map((data: any) => data));
    }

    update(order) {
        const dataToSend = {
            order,
        };
        return this.http.put(env.apiPath + 'orders/update', dataToSend)
            .pipe(map((data: any) => data));
    }

    updateStatus(order) {
        const user = this.store.selectForLocal('user');
        const dataToSend = {
            order,
            user,
        };
        return this.http.put(env.apiPath + 'orders/update/status', dataToSend)
            .pipe(map((data: any) => data));
    }

    search(params) {
        const dataToSend = {
            params,
        };
        return this.http.post(env.apiPath + 'orders/search', dataToSend)
            .pipe(map((data: any) => data));
    }

    searchOrderDetails(params) {
        const dataToSend = {
            params,
        };
        return this.http.post(env.apiPath + 'orders/search/orderdetails', dataToSend)
            .pipe(map((data: any) => data));
    }

    getUpdateRequestReasons(): ReplaySubject<any>
    {
        if (!this.updateRequestReasonsCache$) {
            this.updateRequestReasonsCache$ = new ReplaySubject();
            this.http
                .get(env.apiPath + 'update-request-reason')
                .subscribe(data => this.updateRequestReasonsCache$.next(data));
        }
        return this.updateRequestReasonsCache$;
    }

    updateRequestUpdate(request): Observable<any>
    {
        return this.http.post(
            env.apiPath + 'orders/requestupdate',
            {
                request,
                user: this.store.selectForLocal('user'),
            }
        );
    }
}
