<div class="preview-box">
    <div class="preview-box-title">
        Yorkstone Requests
        <button class="btn btn-round btn-primary float-right" (click)="requestCreate()">Create a Request</button>
    </div>
    <div class="preview-box-content">


        <div class="table-responsive">
            <table class="table table-condensed">
                <thead class="thead-white">

                <tr>
                    <th>Sent</th>
                    <th>Sent By</th>
                    <th>Type</th>
                    <th>Message</th>
                    <th>Status</th>
                </tr>
                <!--        <tr>-->
                <!--            <th><input type="text" class="form-control" [(ngModel)]="params.created" (ngModelChange)="filterSearch()" /></th>-->
                <!--            <th></th>-->
                <!--            <th><input type="text" class="form-control" [(ngModel)]="params.orderId" (ngModelChange)="filterSearch()" /></th>-->
                <!--            <th></th>-->
                <!--            <th></th>-->
                <!--            <th></th>-->
                <!--        </tr>-->
                </thead>
                <tbody>
                <tr class="underline" *ngFor="let s of (companyRequests$ | async)">
                    <td>{{s.createdAt | date: 'dd MMM yyyy @ HH:mm'}}</td>
                    <td>{{s.company.username}}</td>
                    <td>{{s.type.name}}</td>
                    <td>{{s.message}}</td>
                    <td>
                        <span *ngIf="!s.status">Pending</span>
                        <span *ngIf="s.status">Completed by {{s.completedBy}} {{s.completedAt | date: 'dd MMM yyyy @ HH:mm'}}</span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <div class="no-results" *ngIf="!(companyRequests$ | async).length">
            <p>No requests made</p>
        </div>

        <app-pagination *ngIf="params.pageArray.length" [params]="params" (update)="filterSearch()"></app-pagination>


    </div>
</div>



