<div
    *ngIf="!avatarIcon"
    class="wrapper"
    [style.border]="border"
    [style.backgroundColor]="avatarColour"
    [ngStyle]="{
        'font-size': fontSize ? fontSize : 10,
        'line-height': fontSize ? fontSize : 10,
        'font-weight': fontWeight ? fontWeight : 'bold'
    }">
    {{ imagePlaceholder }}
    <div
        *ngIf="isOnline() && detectOnline"
        class="online-mark"></div>
</div>
<div
    *ngIf="avatarIcon"
    class="wrapper">
    <svg-icon
        [svgClass]="'avatar-icon-svg'"
        [src]="avatarIcon"></svg-icon>
    <div
        *ngIf="isOnline()"
        class="online-mark"></div>
</div>
