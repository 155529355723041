import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  HostBinding,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { IChat, IChatEvent, IChatListItem, IChatMessage } from "./models/";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ChatRESTService } from "./services/chat-rest.service";
import { Subscription, take, tap } from "rxjs";
import { Store } from "src/app/store";
import { ChatSocketService } from "./services/chat-socket.service";
import { ChatEventsService } from "./services/chat-events.service";
import { ChatGroupLogicService } from "./services/chat-group-logic.service";
import {
  ChatSideMenuComponent,
  IChatCreationData,
} from "./components/chat-side-menu/chat-side-menu.component";
import { EChatStoreKey, EChatEventName, EChatType } from "./enums";
import { UserService } from "../services/user.service";

export enum EChatMode {
  Active,
  Archive,
}

export interface IChatOpened extends IChat {
  sendingMessageId?: string;
  forwardMessage?: IChatMessage;
  targetMessageId?: string;
}

@UntilDestroy()
@Component({
  selector: "app-chat",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.scss"],
})
export class ChatComponent implements OnInit {
  @ViewChild(ChatSideMenuComponent)
  chatSideMenuComponent: ChatSideMenuComponent;
  @HostBinding("class") get class() {
    return this.domainClass;
  }

  public domain$ = this.store.select<string>("domain");
  domainClass = "ys";

  static readonly OPENED_CHAT_DEFAULTS: IChatOpened = {
    id: undefined,
    messages: [],
    members: [],
    mode: EChatType.DefaultChat,
    createdByEmail: "",
  };

  EChatMode = EChatMode;

  user = this.store.selectForLocal("user");
  mode: EChatMode = EChatMode.Active;

  openedChat: IChatOpened = { ...ChatComponent.OPENED_CHAT_DEFAULTS };

  loadingChatSubsciption: Subscription = null;
  isLoading = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private chatGroupLogicService: ChatGroupLogicService,
    private chatEventsService: ChatEventsService,
    private chatRESTService: ChatRESTService,
    private chatSocketService: ChatSocketService,
    private store: Store,
    private cdr: ChangeDetectorRef,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.domain$
      .pipe(untilDestroyed(this))
      .subscribe((val) => (this.domainClass = val));
    this.isLoading = true;
    this.chatRESTService.getChatList().subscribe((chats: IChatListItem[]) => {
      this.chatGroupLogicService.chats = chats;
      this.isLoading = false;
    });

    // TODO: #OLD
    this.userService.all().subscribe();

    this.chatEventsService
      .listenEvent(EChatEventName.ChatSelected)
      .pipe(untilDestroyed(this))
      .subscribe((event: IChatEvent) => {
        this.openChat(event.data as IChatOpened);
      });

    this.chatEventsService
      .listenEvent(EChatEventName.CreateChat)
      .pipe(untilDestroyed(this))
      .subscribe((event) => {
        this.openNewChat(event.data as IChatCreationData);
      });

    this.chatEventsService
      .listenEvent(EChatEventName.ChatUnselected)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.openedChat = { ...ChatComponent.OPENED_CHAT_DEFAULTS };
        this.cdr.detectChanges();
      });

    this.chatEventsService
      .listenEvent(EChatEventName.MessageAdded)
      .pipe(untilDestroyed(this))
      .subscribe((event: IChatEvent) => {
        const message = event.data;
        if (message.id === this.openedChat.sendingMessageId) {
          // handle case for first (initialization) message in chat
          if (this.openedChat.id === null && message.chatId) {
            this.openedChat.id = message.chatId;
          }
          this.openedChat.sendingMessageId = null;
        }
      });

    this.handlePreselectedChat();
  }

  openNewChat(chatData: IChatCreationData) {
    this.openedChat = {
      id: null,
      messages: [],
      members: chatData.members,
      mode: chatData.mode,
      name: chatData.channelName,
      isClosed: 0,
      forwardMessage: chatData.forwardMessage,
      createdByEmail: this.user.username,
    };
    this.store.set(EChatStoreKey.OpenedChat, this.openedChat);
  }

  private openChat(chatData: IChatOpened) {
    if (this.openedChat.id) {
      this.chatSocketService.leaveRoom(this.openedChat.id.toString());
    }

    this.openedChat.id = chatData.id;
    this.mode = EChatMode.Active;

    if (this.loadingChatSubsciption) {
      this.loadingChatSubsciption.unsubscribe();
      this.loadingChatSubsciption = null;
    }

    this.loadingChatSubsciption = this.chatRESTService
      .getChat(chatData.id)
      .pipe(take(1))
      .pipe(
        tap((chat) => {
          this.chatSocketService.joinRoom(chatData.id.toString());
          this.openedChat = {
            ...chat,
            forwardMessage: chatData.forwardMessage,
            targetMessageId: chatData.targetMessageId,
          };
          this.store.set(EChatStoreKey.OpenedChat, this.openedChat);
        })
      )
      .subscribe(() => {
        this.loadingChatSubsciption.unsubscribe();
        this.loadingChatSubsciption = null;
      });

    this.chatRESTService.markAsRead(chatData.id).subscribe(() => {
      this.chatGroupLogicService.markChatAsRead(chatData.id);
    });
  }

  private handlePreselectedChat() {
    const preselectedChatId = this.activatedRoute.snapshot.params.chatId;
    const targetMessageId = this.activatedRoute.snapshot.params.messageId;

    const preselectedUserId =
      this.activatedRoute.snapshot.queryParams["preselected-user"];

    if (preselectedChatId) {
      this.chatEventsService.emitEvent(EChatEventName.ChatSelected, {
        id: parseInt(preselectedChatId),
        targetMessageId: targetMessageId,
      });
    } else if (preselectedUserId) {
      setTimeout(() => {
        const chatUserList = this.store.selectForLocal("allUsers");
        const selectedMembers = chatUserList.find(
          (u) => u.id === preselectedUserId
        );
        this.chatSideMenuComponent.openNewChat(selectedMembers);
      }, 250);
    }
  }
}
