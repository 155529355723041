import { Component, OnInit, Input, ViewChild, Renderer2 } from "@angular/core";
import { CKEditorComponent } from "@ckeditor/ckeditor5-angular";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Store } from "src/app/store";
import { IChatEvent, IChatMember, IChatMessage } from "../../models/";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ChatEditorUploadAdapter } from "./chat-editor-upload-adapter.class";
import { newUUID } from "../../../utils/utils";
import { ChatEventsService } from "../../services/chat-events.service";
import { IChatOpened } from "../../chat.component";
import { EChatEventName } from "../../enums";
import { ChatRESTService } from "../../services/chat-rest.service";

enum EChatEditorResendMode {
  Reply,
  Forward,
}

@UntilDestroy()
@Component({
  selector: "app-chat-editor",
  templateUrl: "./chat-editor.component.html",
  styleUrls: ["./chat-editor.component.scss"],
})
export class ChatEditorComponent implements OnInit {
  public static domain;

  @Input() openedChat: IChatOpened;
  @ViewChild("editor") editorComponent: CKEditorComponent;

  public Editor = ClassicEditor;

  public config = {
    placeholder: "Enter your message",
    toolbar: [
      "bold",
      "italic",
      "link",
      "NumberedList",
      "BulletedList",
      "|",
      "imageUpload",
    ],
    extraPlugins: [FileUploadAdapterPlugin],
    defaultProtocol: "http://",
    link: {
      defaultProtocol: "http://",
    },
  };

  message: IChatMessage;
  content: string = "";
  replyTo = null;
  forwardOf: IChatMessage = null;
  user = this.store.selectForLocal("user");

  get editMode(): boolean {
    return this.message?.id ? true : false;
  }

  constructor(
    private chatEventsService: ChatEventsService,
    private chatRESTService: ChatRESTService,
    private store: Store,
    private renderer: Renderer2
  ) {}

  public getEditor() {
    if (!this.editorComponent) return null;
    return this.editorComponent.editorInstance;
  }

  ngOnInit(): void {
    this.setEventsListeners();
    ChatEditorComponent.domain = this.store.selectForLocal("domain");
  }

  ngOnChanges(): void {
    this.checkForwardMessage();
  }

  saveMessage() {
    if (this.editMode) {
      this.edit();
    } else {
      this.send(this.getEditor().getData(), "chat");
    }
  }

  send(content, type) {
    if (this.canNotSend()) return;
    const messageForSend: IChatMessage = this.buildMessage(content, type);
    this.openedChat.sendingMessageId = messageForSend.id;
    this.chatRESTService.sendMessage(messageForSend).subscribe(() => {
      this.openedChat.isClosed = 0;
    });
    this.cleanEditor();
  }

  edit() {
    this.message.content = this.content;
    this.openedChat.sendingMessageId = this.message.id;
    this.chatRESTService
      .editMessage(this.message as IChatMessage)
      .subscribe(() => {});
    this.cleanEditor();
  }

  cleanEditor() {
    this.content = "";
    this.message = null;
    this.getEditor().setData("");
    this.replyTo = null;
    this.forwardOf = null;
    this.openedChat.forwardMessage = null;
  }

  canNotSend(): boolean {
    return !!this.openedChat.sendingMessageId || !this.getEditor()?.getData();
  }

  onEditorReady() {
    this.buildResendModeElements();
    this.checkForwardMessage();
    this.setPressKeyListener();
  }

  resendModeActive() {
    return !!this.replyTo || !!this.forwardOf;
  }

  private setPressKeyListener() {
    this.editorComponent["elementRef"].nativeElement
      .querySelector(".ck-editor")
      .addEventListener("keydown", (evt, data) => {
        if (evt.keyCode == 13 && !evt.shiftKey) {
          evt.preventDefault();
          this.saveMessage();
        }
      });
  }

  private checkForwardMessage() {
    if (this.openedChat.forwardMessage) {
      this.addBaseMessage(
        this.openedChat.forwardMessage,
        EChatEditorResendMode.Forward
      );
    }
  }

  private setEventsListeners() {
    this.chatEventsService
      .listenEvent(EChatEventName.MessageSelectedForEdit)
      .pipe(untilDestroyed(this))
      .subscribe((event) => {
        const messageForEdit = event.data as IChatMessage;
        this.message = messageForEdit;
        this.content = messageForEdit.content;
      });

    this.chatEventsService
      .listenEvent(EChatEventName.MessageSelectedForReply)
      .pipe(untilDestroyed(this))
      .subscribe((event: IChatEvent) => {
        this.addBaseMessage(
          event.data as IChatMessage,
          EChatEditorResendMode.Reply
        );
      });
  }

  private buildMessage(
    editorData: string,
    type: "chat" | "file" = "chat"
  ): IChatMessage {
    const domain = this.store.selectForLocal("domain");

    const sender: IChatMember = {
      id: this.user.id,
      firstname: this.user.contact.firstname,
      surname: this.user.contact.surname,
      avatarColour: this.user.avatarColour,
      accountType: this.user.accountType,
    };

    // #TODO - need to handle recipients on Server Side
    const recipients = this.openedChat.members.filter(
      (r) => r.id !== this.user.id
    );

    return {
      id: newUUID(),
      content: editorData,
      chatId: this.openedChat.id,
      user: sender,
      createdAt: new Date(),
      domainId: domain,
      channelName: this.openedChat.name,
      recipients: recipients,
      type: type,
      replyTo: this.replyTo,
      forwardOf: this.forwardOf,
    };
  }

  private buildResendModeElements() {
    const editorEl: HTMLDivElement =
      this.editorComponent["elementRef"].nativeElement.querySelector(
        ".ck-editor"
      );

    // build top 'Resend To' holder element
    const resendToHolderEl: HTMLDivElement = this.renderer.createElement(`div`);
    const title = this.replyTo ? "Reply to:" : "Forward to:";
    resendToHolderEl.classList.add("resend-to-holder");
    resendToHolderEl.innerHTML = `
            <img src="assets/images/chat/reply-icon.svg" />
            <div class="reply-to-title"> ${title} </div>
            <div class="reply-to-username"></div>
            <div class="reply-to-cancel-btn">Cancel</div>
        `;
    editorEl.prepend(resendToHolderEl);

    // build 'Base Message' holder element
    const baseMessageHolderEl: HTMLDivElement =
      this.renderer.createElement("div");
    baseMessageHolderEl.classList.add("base-message-holder");
    baseMessageHolderEl.innerHTML = `
      <div class="base-message">
        <div class="base-message-user-name"></div>
        <div class="base-message-content"></div>
      </div>
    `;
    editorEl.querySelector(".ck-editor__main").append(baseMessageHolderEl);

    // add event Listener to Cancel Btn
    editorEl
      .querySelector(".reply-to-cancel-btn")
      .addEventListener("click", () => {
        this.replyTo = null;
        this.cleanEditor();
      });
  }

  private addBaseMessage(
    message: IChatMessage,
    mode: EChatEditorResendMode = EChatEditorResendMode.Reply
  ) {
    if (!this.editorComponent) return;

    if (mode === EChatEditorResendMode.Reply) this.replyTo = message.id;
    if (mode === EChatEditorResendMode.Forward) this.forwardOf = message;

    const username = message.user.firstname + " " + message.user.surname;
    const messageContent = message.content;
    let recipientName;
    if (mode === EChatEditorResendMode.Reply) {
      recipientName = username;
    } else {
      const recipient = this.openedChat.members.find(
        (m) => m.id !== this.user.id
      );
      recipientName = recipient.firstname + recipient.surname;
    }

    const editorEl: HTMLDivElement =
      this.editorComponent["elementRef"].nativeElement.querySelector(
        ".ck-editor"
      );

    const titleHolderEl = editorEl.querySelector(".reply-to-title");
    titleHolderEl.innerHTML =
      mode === EChatEditorResendMode.Reply ? "Reply to:" : "Forward to:";

    const usernameHolderEl = editorEl.querySelector(".reply-to-username");
    usernameHolderEl.innerHTML = recipientName;
    const messageUsernameEl = editorEl.querySelector(".base-message-user-name");
    messageUsernameEl.innerHTML = username;
    const messageContentEl = editorEl.querySelector(".base-message-content");
    messageContentEl.innerHTML = messageContent;
  }
}

function FileUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return new ChatEditorUploadAdapter(loader, {
      domain: ChatEditorComponent.domain,
      appName: "admin",
    });
  };
}
