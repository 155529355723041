import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Store } from 'src/app/store';
import { EChatSubgroup } from '../../enums/chat-subgroup.enum';
import { IChatListItem, IChatMember, IChatSubgroup } from '../../models';
import { EChatType } from '../../enums';

interface IChatSideMenuItem extends IChatListItem {
    chatNameForDisplay: string;
    chatMemberForDisplay: IChatMember;
}

interface IChatSubgroupForDisplay extends IChatSubgroup {
    unreadCnt: number;
}

@Component({
    selector: 'app-chat-accordion-list',
    templateUrl: './chat-accordion-list.component.html',
    styleUrls: ['./chat-accordion-list.component.scss'],
})
export class ChatAccordionListComponent implements OnInit {
    @Input() groups: IChatSubgroup[];
    @Input() selectedChatId: number;
    @Input() openedSubgroup: EChatSubgroup;
    @Output() chatSelected = new EventEmitter<number>();

    EChatSubgroup = EChatSubgroup;

    user = this.store.selectForLocal('user');

    constructor(private store: Store) {}

    ngOnInit(): void {}

    ngOnChanges(): void {
        this.groups.forEach((g: IChatSubgroupForDisplay) => {
            g.chatItems.forEach((i: IChatSideMenuItem) => {
                i.chatNameForDisplay = this.getChatName(i, g);
                i.chatMemberForDisplay = this.getChatMember(i);
            });
            g.unreadCnt = this.getGroupUnreadCnt(g);
        });
    }

    selectChat(chatId: number) {
        this.chatSelected.emit(chatId);
    }

    needAvatar(group) {
        return ![EChatSubgroup.Channels, EChatSubgroup.Orders, EChatSubgroup.Actions].includes(group.type);
    }

    private getChatName(chatItem, group) {
        if (
            [
                EChatSubgroup.Channels,
                EChatSubgroup.Orders,
                EChatSubgroup.Actions,
                EChatSubgroup.CustomerService,
            ].includes(group.type) &&
            chatItem.name
        ) {
            return chatItem.name;
        }
        const member = this.getChatMember(chatItem);
        return `${member.firstname} ${member.surname}`;
    }

    private getChatMember(chatItem): IChatMember {
        if (chatItem.type === EChatType.CustomerService) {
            return chatItem.members.find((m) => m.userId === chatItem.createdBy);
        }

        const notActiveUser = chatItem.members.filter((m) => m.id !== this.user.id)[0];
        return notActiveUser ? notActiveUser : chatItem.members[0];
    }

    private getGroupUnreadCnt(group: IChatSubgroup) {
        return group.chatItems.reduce((acc, cur) => {
            return cur.unread ? acc + cur.unread : acc;
        }, 0);
    }
}
