import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {BasketService} from './app/services/basket.service';
import {ToolsService} from './app/services/tools.service';
import {CookieService} from './app/services/cookie.service';

@Injectable()
export class ErrorRequestInterceptor implements HttpInterceptor {
  constructor(private router: Router, private cookieService: CookieService, private toolsService: ToolsService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<any> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error.error.code === 'AUTH-0002') {
          this.toolsService.logoutSessionId();
          this.cookieService.remove();

          this.router.navigate(['/login'], {
            queryParams: {
              resetLogin: true,
            },
          });
          return of({});
        }
        return throwError(() => error);
      }),
    );
  }
}
