import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Store } from '../store';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { OverlayService } from './overlay.service';
import { setTimeout$ } from '../utils/setTimeout$';
import { isPlatformBrowser } from '@angular/common';
import { Socket } from 'ngx-socket-io';

@Injectable({
    providedIn: 'root'
})
export class ChatService {
    env = environment;
    private socket: Socket;
    isBrowser = false;

    constructor(
        private store: Store,
        private apiService: ApiService,
        private http: HttpClient,
        private overlayService: OverlayService,
        private injector: Injector,
        @Inject(PLATFORM_ID) platformId: {}
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
        if (this.isBrowser) {
            this.socket = this.injector.get<Socket>(Socket);
        }
    }

    getChannelData() {
        const channelData = {
            type: 'tmpSessionId',
            id: localStorage.getItem('tmpSessionId')
        };
        const user = this.store.selectForLocal('user');
        if (user) {
            channelData.type = 'user';
            channelData.id = user.id;
        }
        return channelData;
    }

    sendChat(message) {
        message.sessionId = '';

        if (this.isBrowser) {
            const sessionId = this.store.selectForLocal('sessionId');

            if (sessionId) {
                message.sessionId = sessionId;
            }

            message.source = 'client';
            this.socket.emit('chat', message);
        }
    }

    sendOnlineSignal() {
        const user = this.store.selectForLocal('user');
        if (user) {
            this.socket.emit('userOnlineStatus', { id: user.id, online: true });
        }
    }

    sendOfflineSignal() {
        const user = this.store.selectForLocal('user');
        if (user) {
            this.socket.emit('userOnlineStatus', { id: user.id, online: false });
        }
    }


    sendTypingSignal(data) {
        this.socket.emit('userTyping', data);
    }

    receiveTypingSignal() {
        this.socket.fromEvent('userTyping').subscribe((data: any) => {
            const chatStore = this.store.selectForLocal('chatStore');
            if (!chatStore.group.typing) {
                chatStore.group.typing = [];
            }
            const user = this.getChatUser();

            if (user.id !== data.user.id) {
                if (data.groupId === chatStore.group.id) {
                    if (data.typing) {
                        let userFound = false;
                        for (let i = 0; i < chatStore.group.typing.length; i++) {
                            if (chatStore.group.typing[i].id === data.user.id) {
                                userFound = true;
                            }
                        }
                        if (!userFound) {
                            chatStore.group.typing.push(data.user);
                        }
                    }

                    if (!data.typing) {
                        for (let i = 0; i < chatStore.group.typing.length; i++) {
                            if (chatStore.group.typing[i].id === data.user.id) {
                                chatStore.group.typing.splice(i, 1);
                            }
                        }
                    }
                }
                this.store.set('chatStore', chatStore);
            }


        });
    }


    receiveChat() {
        if (this.isBrowser) {
            const channelData = this.getChannelData();
            this.getGroups().subscribe();

            this.socket.fromEvent(channelData.id).subscribe((message: any) => {
                if (message.type === 'chat') {
                    const chatStore = this.store.selectForLocal('chatStore');
                    // tslint:disable-next-line:prefer-for-of
                    for (let i = 0; i < chatStore.groups.length; i++) {
                        if (message.groupId === chatStore.groups[i].id) {
                            if (!chatStore.group || (chatStore.groups[i].id !== chatStore.group.id)) {
                                chatStore.groups[i].unread++;
                            }
                        }
                    }

                    if (chatStore.group) {
                        if (message.groupId === chatStore.group.id) {
                            chatStore.messages.push(message);
                        }
                    }

                    chatStore.unread = 0;
                    chatStore.groups.forEach(g => {
                        chatStore.unread = +chatStore.unread + +g.unread;
                    });

                    this.store.set('chatStore', chatStore);
                    // this.scrollBottom();

                    this.getGroups().subscribe(() => {
                        this.scrollBottom();
                    });

                }

                if (message.type === 'chatClose') {
                    const chatStore = this.store.selectForLocal('chatStore');

                    for (let i = 0; i < chatStore.groups.length; i++) {
                        if (chatStore.groups[i].id === message.groupId) {
                            chatStore.groups[i].isClosed = 1;

                            if (!chatStore.group) {
                                chatStore.group = chatStore.groups[i];
                                this.getByGroupId(chatStore.groups[i].id, false).subscribe(data => {
                                    chatStore.messages = data.data;
                                });
                            }

                        }
                    }
                    if (chatStore.group && chatStore.group.id === message.groupId) {
                        chatStore.group.isClosed = 1;
                    }
                    chatStore.openConversations = this.calculateOpenConversations(chatStore);

                    this.store.set('chatStore', chatStore);
                }
                if (message.type === 'chatOpen') {
                    const chatStore = this.store.selectForLocal('chatStore');
                    for (let i = 0; i < chatStore.groups.length; i++) {
                        if (chatStore.groups[i].id === message.groupId) {
                            chatStore.groups[i].isClosed = 0;
                        }
                    }
                    if (chatStore.group && chatStore.group.id === message.groupId) {
                        chatStore.group.isClosed = 0;
                    }
                    chatStore.openConversations = this.calculateOpenConversations(chatStore);

                    this.store.set('chatStore', chatStore);

                    this.getGroups().subscribe(() => {
                    });
                }

            });
            // return this.socket.fromEvent('chat');


        }
    }

    getUsers() {
        if (this.isBrowser) {
            return this.socket.fromEvent('users');
        }
    }

    getByGroupId(groupId, saveToStore) {
        return this.http.get(this.env.apiPath + 'chat/public/' + groupId)
            .pipe(
                map((data: any) => {
                        if (saveToStore) {
                            const chatStore = this.store.selectForLocal('chatStore');
                            chatStore.messages = data.data;
                            this.store.set('chatStore', chatStore);
                        }

                        return data;
                    }
                ));
    }

    getGroups() {
        const userId = this.store.selectForLocal('user')?.id || localStorage.getItem('tmpSessionId');
        return this.http.get(this.env.apiPath + 'chat/groups/' + userId)
            .pipe(
                map((data: any) => {
                        const chatStore = this.store.selectForLocal('chatStore');
                        chatStore.groups = data.data;
                        chatStore.unread = 0;
                        chatStore.openConversations = this.calculateOpenConversations(chatStore);

                        chatStore.groups.forEach(g => {
                            if (+g.unread > 0) {
                                chatStore.unread = +chatStore.unread + +g.unread;
                            }
                        });
                        // if (data.data.length) {
                        //     chatStore.group = data.data[0];
                        // }
                        this.store.set('chatStore', chatStore);
                        return data;
                    }
                ));
    }

    calculateOpenConversations(chatStore) {
        let openConversations = 0;

        chatStore.groups.forEach(g => {
            if (!g.isClosed) {
                openConversations++;
            }
        });

        return openConversations;
    }


    markAsRead(groupId) {
        const userId = this.store.selectForLocal('user')?.id || localStorage.getItem('tmpSessionId');
        return this.http.delete(this.env.apiPath + 'chat/' + groupId + '/' + userId)
            .pipe(map((data: any) => data));
    }

    searchMessagesFromUserGroups(params) {
        const user = this.store.selectForLocal('user');
        const dataToSend = {
            params,
            user
        };
        return this.http.post(this.env.apiPath + 'chat/messages/search', dataToSend)
            .pipe(map((data: any) => data));
    }


    searchUsers(params) {
        const dataToSend = {
            params,
        };
        return this.http.post(this.env.apiPath + 'chat/users/search', dataToSend)
            .pipe(map((data: any) => data));

    }

    addUser(groupId, userId) {
        const dataToSend = {
            groupId,
            userId
        };
        return this.http.post(this.env.apiPath + 'chat/user/add', dataToSend)
            .pipe(map((data: any) => data));

    }

    removeUser(groupId, userId) {
        const dataToSend = {
            groupId,
            userId
        };
        return this.http.post(this.env.apiPath + 'chat/user/remove', dataToSend)
            .pipe(map((data: any) => data));

    }

    getCategories() {
        return this.http.get(this.env.apiPath + 'chat/categories')
            .pipe(map((data: any) => data));
    }

    toggleImportant(groupId, userId, important) {
        const dataToSend = {
            groupIds: [groupId],
            userId,
            important,
        };
        return this.http.post(this.env.apiPath + 'chat/important', dataToSend)
            .pipe(map((data: any) => data));
    }

    toggleArchived(groupId, userId, archived) {
        const dataToSend = {
            groupIds: [groupId],
            userId,
            archived,
        };
        return this.http.post(this.env.apiPath + 'chat/archived', dataToSend)
            .pipe(map((data: any) => data));
    }

    setCategory(groupId, userId, categoryId) {
        const dataToSend = {
            groupIds: [groupId],
            userId,
            categoryId,
        };
        return this.http.post(this.env.apiPath + 'chat/category', dataToSend)
            .pipe(map((data: any) => data));
    }

    removeCategory(groupId, userId) {
        const dataToSend = {
            groupIds: [groupId],
            userId,
        };
        return this.http.post(this.env.apiPath + 'chat/category/remove', dataToSend)
            .pipe(map((data: any) => data));
    }

    scrollBottom() {
        const typeBox = document.getElementById('chat-content-holder');
        if (typeBox) {
            setTimeout$(() => typeBox.scrollTo(0, typeBox.scrollHeight), 1500);
        }
    }

    startChatGroup(recipient, recipientType) {
        const sender = this.store.selectForLocal('user') || localStorage.getItem('tmpSessionId');

        const dataToSend = {
            sender: {
                id: sender?.id || sender,
                senderType: sender?.company ? 'company' : 'customer'
            },
            recipient: {
                id: recipient.id,
                recipientType
            }
        };

        return this.http.post(this.env.apiPath + 'chat/group/checkexists', dataToSend)
            .subscribe((data: any) => {
                    const chatStore = this.store.selectForLocal('chatStore');
                    chatStore.groupToOpen = data.data.groupId;
                    this.store.set('chatStore', chatStore);
                    const overlays = this.store.selectForLocal('overlays');
                    if (!overlays.chat) {
                        this.overlayService.toggle('chat');
                    }
                }
            );
    }

    startChatGroupFromWithinChat(recipient, recipientType, name, sender) {
        const dataToSend = {
            name: name || '',
            sender: {
                id: sender.id,
                name: sender.name,
                senderType: '',
                email: sender.email,
            },
            recipient: {
                id: recipient.id,
                recipientType
            }
        };

        return this.http.post(this.env.apiPath + 'chat/group/checkexists', dataToSend)
            .pipe(map((data: any) => {
                    // Todo if group was newly created send a "created: true" flag for conditional rendering of welcome message
                    return data;
                }
            ));
    }


    openChat(groupId) {
        return this.http.get(this.env.apiPath + 'chat/groups/open/' + groupId)
            .pipe(map((data: any) => data));

    }

    sendTranscript(group) {
        const user = this.store.selectForLocal('user');
        let email = '';

        if (user) {
            email = user.username;
        } else {
            email = group.createdByEmail;
        }

        const dataToSend = {
            groupId: group.id,
            email
        };
        return this.http.post(this.env.apiPath + 'chat/groups/transcript', dataToSend)
            .pipe(map((data: any) => data));

    }

    getChatUser() {
        const user = this.store.selectForLocal('user');
        let chatUser;

        if (!user) {
            chatUser = {
                id: localStorage.getItem('tmpSessionId'),
                name: localStorage.getItem('tmpSessionName')
            };
        } else {
            chatUser = {
                id: user.id,
                name: `${user.contact.firstname} ${user.contact.surname}`,
            };
        }
        return chatUser;
    }


}
