import { Component, Input, OnInit } from '@angular/core';
import { Store } from '../../store';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
    user$ = this.store.select<any>('user');

    constructor(private store: Store) {
    }

    ngOnInit(): void {
    }
}
