import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Store } from '../store';
import { CookieService } from './cookie.service';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PaymentsService {
    env = environment;

    constructor(
        private apiService: ApiService,
        private http: HttpClient,
        private store: Store,
        private cookieService: CookieService,
    ) {
    }

    intentCreate(intent) {
        const dataToSend = {
            intent,
        };

        return this.http.post(this.env.apiPath + 'payments/intent', dataToSend)
            .pipe(map((data: any) => data));
    }

    intentUpdate(intent) {
        const dataToSend = {
            intent,
        };

        return this.http.put(this.env.apiPath + 'payments/intent', dataToSend)
            .pipe(map((data: any) => data));
    }

    attachPaymentMethodToCustomer(paymentMethodId, user) {

        const dataToSend = {
            paymentMethodId,
            userId: user.id,
            stripeId: user.stripeId
        };

        return this.http.post(this.env.apiPath + 'payments/method/attach', dataToSend)
            .pipe(map((data: any) => data));
    }

    cards(userId) {
        return this.http.get(this.env.apiPath + 'payments/cards/' + userId)
            .pipe(map((data: any) => data));
    }

    card(id) {

        return this.http.get(this.env.apiPath + 'payments/card/' + id)
            .pipe(map((data: any) => data));
    }

    tokenCreate(card) {
        const dataToSend = {
            card,
        };

        return this.http.post(this.env.apiPath + 'payments/token', dataToSend)
            .pipe(map((data: any) => data));
    }

    search(params) {
        const dataToSend = {
            params,
        };
        return this.http.post(this.env.apiPath + 'payments', dataToSend)
            .pipe(map((data: any) => data));
    }
}
