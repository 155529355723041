import { Component, HostBinding, Input, OnInit, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { Store } from 'src/app/store';
import { environment } from 'src/environments/environment';
import { IChatCreationData } from '../chat-side-menu/chat-side-menu.component';
import { EChatStoreKey, EChatEventName, EChatType } from '../../enums';
import { IChatMember } from '../../models';
import { IChatMessage } from '../../models/chat-message.interface';
import { ChatDialogService } from '../../services/chat-dialog.service';
import { ChatEventsService } from '../../services/chat-events.service';
import { ChatGroupLogicService } from '../../services/chat-group-logic.service';
import { IChatReminderDlgResult } from '../../dialogs/message-reminder-dlg/message-reminder-dlg.component';
import { ToastrService } from 'ngx-toastr';
import { ChatReminderService } from '../../services/reminder.service';
import { IChatOpened } from '../../chat.component';
import { ChatRESTService } from '../../services/chat-rest.service';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-chat-message',
    templateUrl: './chat-message.component.html',
    styleUrls: ['./chat-message.component.scss'],
})
export class ChatMessageComponent implements OnInit {
    @Input() message: IChatMessage;

    public static TARGET_MESSAGE_CLASS = 'target-message';

    @HostBinding(`class.${ChatMessageComponent.TARGET_MESSAGE_CLASS}`)
    get isTarget(): boolean {
        const openedChat: IChatOpened = this.store.selectForLocal(EChatStoreKey.OpenedChat);
        if (!this.openedChat?.targetMessageId) return false;
        const isTarget = this.openedChat.targetMessageId === this.message.id;
        if (isTarget) {
            setTimeout(() => {
                this.openedChat.targetMessageId = null;
            }, 3000);
        }
        return isTarget;
    }

    get createdAt(): string {
        return moment(this.message.createdAt).format('LT');
    }

    get messageBelongToUser(): boolean {
        return this.message.user.id === this.user.id;
    }

    get baseMessage(): IChatMessage | null {
        const replyTo = this.message.replyTo;
        const forwardOf = this.message.forwardOf;
        if (!replyTo && !forwardOf) return null;

        if (replyTo) {
            return this.store.selectForLocal(EChatStoreKey.OpenedChat).messages.find((m) => m.id === replyTo);
        } else if (forwardOf) {
            return this.message.forwardOf;
        }
    }

    user = this.store.selectForLocal('user');
    openedChat: IChatOpened = this.store.selectForLocal(EChatStoreKey.OpenedChat);
    viewImage$ = new Subject<string>();
    onImageClickListenerSetup = false;

    constructor(
        private chatEventsService: ChatEventsService,
        private chatRESTService: ChatRESTService,
        private chatGroupLogicService: ChatGroupLogicService,
        private chatDialogService: ChatDialogService,
        private chatReminderService: ChatReminderService,
        public dialog: MatDialog,
        private store: Store,
        private toastrService: ToastrService,
        private elem: ElementRef
    ) {}

    ngOnInit(): void {}

    ngAfterViewChecked(): void {
        if (!this.onImageClickListenerSetup) {
            this.onImageClickListener();
            this.onImageClickListenerSetup = true;
        }
    }

    editMessage() {
        this.chatEventsService.emitEvent(EChatEventName.MessageSelectedForEdit, this.message);
    }

    replyOnMessage() {
        this.chatEventsService.emitEvent(EChatEventName.MessageSelectedForReply, this.message);
    }

    async forwardMessage() {
        const selectedMember: IChatMember = (await this.chatDialogService.openUsersSelectorDlg(
            'forward',
            'Forward'
        )) as IChatMember;
        if (!selectedMember) return;

        const existChatId = this.chatGroupLogicService.chatExists([selectedMember]);

        if (existChatId) {
            this.chatEventsService.emitEvent(EChatEventName.ChatSelected, {
                id: existChatId,
                forwardMessage: this.message,
            });
            return;
        }

        this.chatEventsService.emitEvent(EChatEventName.CreateChat, {
            mode: EChatType.DefaultChat,
            members: this.chatGroupLogicService.addCurrentUserToMemebers([selectedMember]),
            forwardMessage: this.message,
        } as IChatCreationData);
    }

    deleteMessage() {
        this.chatRESTService.deleteMessage(this.message).subscribe(() => {});
    }

    viewImage(event) {
        this.viewImage$.next(event.target.src);
    }

    async setReminder(delayInMunutes: number) {
        let reminderData: IChatReminderDlgResult;
        if (!delayInMunutes) {
            reminderData = await this.chatDialogService.openMessageReminderDlg();
            if (!reminderData) return;
        } else {
            reminderData = {
                datetime: moment().add(delayInMunutes, 'minutes').toDate(),
                comment: null,
            };
        }

        this.chatReminderService
            .addReminder({
                userId: this.user.id,
                messageId: this.message.id,
                datetime: reminderData.datetime,
                comment: reminderData.comment,
                chatId: this.openedChat.id,
            })
            .subscribe((reminder) => {
                this.message.reminder = reminder;
                this.toastrService.info(
                    `Reminder has been set on 
        ${moment(reminder.datetime).format('DD/MM/YYYY')} at 
        ${moment(reminder.datetime).format('LT')}`
                );
            });
    }

    getPathToFile(src: string): string {
        return `${environment.imageUploadPath}${src}`;
    }

    private onImageClickListener() {
        const images = this.elem.nativeElement.querySelectorAll('figure.image');
        images.forEach((image: HTMLElement) => {
            image.addEventListener('click', this.viewImage.bind(this));
        });
    }
}
