import { IChatMessage } from "../models";

export class MessageMapper {
  public static toDomain(dto: any): IChatMessage {
    return {
      ...dto,
      chatId: parseInt(dto.chatId),
      createdAt: new Date(dto.createdAt),
    } as IChatMessage;
  }
}