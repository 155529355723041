import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from 'src/app/store';
import { EChatEventName, EChatType } from '../../enums';
import { IChatMember, IChatListItem, IChatMessage } from '../../models';
import { ChatDialogService } from '../../services/chat-dialog.service';
import { ChatEventsService } from '../../services/chat-events.service';
import { UserAccountType } from 'src/app/data-types/user-data';
import { ChatRESTService } from '../../services/chat-rest.service';

export interface INewChannelDialogResult {
	status: 'create' | 'cancel';
	channelMembers?: IChatMember[];
	channelName?: string;
}

@UntilDestroy()
@Component({
	selector: 'app-new-support-chat-dialog',
	templateUrl: './new-support-chat-dialog.component.html',
	styleUrls: ['./new-support-chat-dialog.component.scss'],
})
export class NewSupportChatDialogComponent implements OnInit {
	channelName = new FormControl('');
	channelMembers: IChatMember[] = [];
	filteredUsers: IChatMember[];
	chats: IChatListItem[];
	user = this.store.selectForLocal('user');
	userDialogOpened = false;

	get canCreateChannel(): boolean {
		return this.channelName.value.trim().length;
	}

	get selectedUserIds() {
		return this.channelMembers.map((u) => u.id);
	}

	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: {
			users: IChatMember[];
			chats: IChatListItem[];
		},
		public dialogRef: MatDialogRef<NewSupportChatDialogComponent>,
		public dialog: MatDialog,
		private store: Store,
		private chatEventsService: ChatEventsService,
		private chatDialogService: ChatDialogService,
		private chatRESTService: ChatRESTService
	) {
		this.filteredUsers = data.users;
		this.chats = data.chats;
	}

	ngOnInit(): void {}

	async selectUserDialog() {
		const selectedUsers: IChatMember[] = (await this.chatDialogService.openUsersSelectorDlg(
			'many',
			'Apply',
			this.channelMembers,
			[UserAccountType.Admin],
			false
		)) as IChatMember[];

		if (!selectedUsers) return;

		this.channelMembers = selectedUsers;
	}

	createChannel() {
		const currentUser: IChatMember = {
			id: this.user.id,
			firstname: this.user.contact.firstname,
			surname: this.user.contact.surname,
			avatarColour: this.user.avatarColour,
			accountType: this.user.accountType,
		};

		const customerSupport: IChatMember = {
			id: 'd6e37e44-ae9b-4c45-89f5-a3508970b876',
			firstname: 'Customer',
			surname: 'Support',
			avatarColour: 'green',
			accountType: UserAccountType.Admin,
		};

		/*const message: IChatMessage = {
            content: 
        }*/

		this.chatEventsService.emitEvent(EChatEventName.CreateChat, {
			mode: EChatType.Channel,
			members: [customerSupport, currentUser],
			channelName: this.channelName.value.trim(),
		});

		/*this.chatRESTService.sendMessage(message).subscribe(() => {
			
		});*/

		this.dialogRef.close();
	}

	removeMember(memberForRemove, $event) {
		this.channelMembers = this.channelMembers.filter((m) => m.id !== memberForRemove.id);
		$event.stopPropagation();
	}
}
