import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Store } from '../store';
import { CookieService } from './cookie.service';
import { catchError, map } from 'rxjs/operators';
import { UserService } from './user.service';
import { environment } from '../../environments/environment';
import { BasketService } from './basket.service';
import { ToolsService } from './tools.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    env = environment;

    authInterval;

    constructor(
        private apiService: ApiService,
        private http: HttpClient,
        private store: Store,
        private cookieService: CookieService,
        private userService: UserService,
        private basketService: BasketService,
        private toolsService: ToolsService,
    ) {
    }

    getTmpSessionId() {
        const sessionId = localStorage.getItem('sessionIdTmp');
        if (!sessionId) {
            const newSessionId = this.toolsService.newUUID();
            localStorage.setItem('sessionIdTmp', this.toolsService.newUUID());
            return newSessionId;
        } else {
            return sessionId;
        }
    }

    login(user) {
        return this.http
            .post(
                this.env.apiPath + 'users/login/supplier',
                user
            )
            .pipe(
                map(async (data: any) => {
                    this.store.set('loggedIn', true);
                    await this.cookieService.setLoggedIn(true);
                    await this.cookieService.setUser(user);

                    this.toolsService.setSessionId(user.id);

                    localStorage.setItem('user', user.id);

                    this.basketService.setGuest(false);
                    return data;
                })
            );
    }

    logout() {
        this.basketService.clearUserFromBasket();
        this.toolsService.logoutSessionId();
        this.cookieService.remove();
        return this.http.post(
            this.env.apiPath + 'users/logout', {}
        );
    }

    passwordReset(email) {
        const dataToSend = {
            email,
        };
        return this.http
            .post(
                this.env.apiPath + 'users/password/reset',
                dataToSend
            )
            .pipe(map((data: any) => data));
    }

    passwordSet(resetData) {
        return this.http
            .post(
                this.env.apiPath + 'users/password/set',
                resetData
            )
            .pipe(map((data: any) => data));
    }

    authTimeout() {
        this.authInterval = setInterval(() => {
            let ttl = this.store.selectForLocal('ttl');
            ttl--;
            this.store.set('ttl', ttl);
            if (ttl === 0) {
                this.logout();
            }
        }, 50000);
    }

    resetAuthTimeout() {
        this.store.set('ttl', 1000); // reset to 10
    }

    clearAuthTimeout() {
        clearInterval(this.authInterval);
    }
}
