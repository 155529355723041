<div class="message-header">
    <div class="message-header-right">
        <app-user-avatar
            [firstname]="message.user.firstname"
            [lastname]="message.user.surname"
            [colour]="message.user.avatarColour"></app-user-avatar>
        <div class="user-name">{{ message.user.firstname }} {{ message.user.surname }}</div>
    </div>
    <div class="message-header-left">
        <div
            class="message-reminder"
            *ngIf="message.reminder">
            <img src="/assets/images/chat/message-reminder-icon.svg" />
        </div>
        <div class="message-time">{{ createdAt }}</div>
        <div
            class="message-options"
            [matMenuTriggerFor]="menu">
            <img src="assets/images/chat/three-dots-icon.svg" />
        </div>
    </div>
</div>
<div
    *ngIf="message.type === 'chat' || message.type === 'subject' || message.type === 'welcome'"
    class="message-text"
    [innerHTML]="message.content"></div>
<img
    *ngIf="message.type === 'file'"
    class="message-image"
    [src]="getPathToFile(message.content)" />
<div
    *ngIf="baseMessage"
    class="replay-message-holder">
    <div class="replay-message">
        <div class="replay-message-username">{{ baseMessage?.user?.firstname }} {{ baseMessage?.user?.surname }}</div>
        <div
            class="replay-message-content"
            [innerHTML]="baseMessage?.content"></div>
    </div>
</div>

<!-- Message Opions -->
<mat-menu
    #menu="matMenu"
    class="popup-simple-menu action-menu">
    <button
        *ngIf="messageBelongToUser"
        mat-menu-item
        (click)="editMessage()">
        <img src="/assets/images/chat/message-edit-icon.svg" />
        <span>Edit Message</span>
    </button>
    <button
        mat-menu-item
        (click)="replyOnMessage()">
        <img src="/assets/images/chat/channel-icon.svg" />
        <span>Reply</span>
    </button>
    <button
        mat-menu-item
        (click)="forwardMessage()">
        <img src="/assets/images/chat/message-replay-icon.svg" />
        <span>Forward Message</span>
    </button>
    <button
        mat-menu-item
        [matMenuTriggerFor]="reminderMenu">
        <img src="/assets/images/chat/message-reminder-icon.svg" />
        <span>Reminder for a Message</span>
    </button>
    <button
        *ngIf="messageBelongToUser"
        mat-menu-item
        (click)="deleteMessage()">
        <img src="/assets/images/chat/message-delete-icon.svg" />
        <span>Delete Message</span>
    </button>
</mat-menu>

<!-- Reminder Opions -->
<mat-menu
    #reminderMenu="matMenu"
    class="popup-simple-menu reminder-menu">
    <button
        mat-menu-item
        (click)="setReminder(20)">
        <span>20 min</span>
    </button>
    <button
        mat-menu-item
        (click)="setReminder(60)">
        <span>1 hour</span>
    </button>
    <button
        mat-menu-item
        (click)="setReminder(180)">
        <span>3 hours</span>
    </button>
    <button
        mat-menu-item
        (click)="setReminder(360)">
        <span>6 hours</span>
    </button>
    <button
        mat-menu-item
        (click)="setReminder(null)">
        <span>Custom Period</span>
    </button>
</mat-menu>

<app-simple-image-viwer [viewImage$]="viewImage$"></app-simple-image-viwer>
