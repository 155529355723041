import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-order-board-container',
    templateUrl: './order-board-container.component.html',
    styleUrls: ['./order-board-container.component.scss']
})
export class OrderBoardContainerComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
