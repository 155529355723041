<div class="preview-box">
    <div class="preview-box-title">
        Your Users
    </div>
    <div class="preview-box-content">
        <app-user-search [showCompany]="false" [company]="user.company"></app-user-search>
    </div>
</div>

<app-user-edit *ngIf="(overlays$ | async).userEdit"></app-user-edit>

