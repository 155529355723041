<div class="bg-slate-5" style="position: relative" *ngIf="(user$ | async)">

    <div class="container-fluid" style="min-height: 110vh; padding: 50px; margin-left: 252px; width: calc(100% - 252px)">

<!--        <div class="row mt-4">-->
<!--            <div class="col-md-2">-->
<!--                <input type="text" placeholder="Order No" class="form-control bordered" [(ngModel)]="params.orderId" (ngModelChange)="filterSearch()" />-->
<!--            </div>-->
<!--            <div class="col-md-2">-->
<!--                <input type="text" placeholder="Customer" class="form-control bordered" [(ngModel)]="params.customer" (ngModelChange)="filterSearch()" />-->
<!--            </div>-->
<!--            <div class="col-md-2">-->
<!--                <input type="text" placeholder="Supplier" class="form-control bordered" [(ngModel)]="params.supplier" (ngModelChange)="filterSearch()" />-->
<!--            </div>-->
<!--            <div class="col-md-2">-->
<!--                <mat-form-field class="full-width" [appearance]="'outline'">-->
<!--                    <input matInput [autocomplete]="false" name="nofill"-->
<!--                           placeholder="Date ordered"-->
<!--                           (click)="picker3.open()"-->
<!--                           [satDatepicker]="picker3"-->
<!--                           [(ngModel)]="params.createdAt"-->
<!--                           (ngModelChange)="filterSearch()">-->
<!--                    <sat-datepicker #picker3 [rangeMode]="true">-->
<!--                    </sat-datepicker>-->
<!--                    <sat-datepicker-toggle matSuffix [for]="picker3"></sat-datepicker-toggle>-->
<!--                </mat-form-field>-->
<!--        -->
<!--            </div>-->
<!--            <div class="col-md-2">-->
<!--                <mat-form-field class="full-width" [appearance]="'outline'">-->
<!--                    <input matInput [autocomplete]="false" name="nofill"-->
<!--                           placeholder="Due date"-->
<!--                           (click)="picker2.open()"-->
<!--                           [satDatepicker]="picker2"-->
<!--                           [(ngModel)]="params.dueDate"-->
<!--                           (ngModelChange)="filterSearch()">-->
<!--                    <sat-datepicker #picker2 [rangeMode]="true">-->
<!--                    </sat-datepicker>-->
<!--                    <sat-datepicker-toggle matSuffix [for]="picker2"></sat-datepicker-toggle>-->
<!--                </mat-form-field>-->
<!--            </div>-->
<!--            <div class="col-md-2">-->
<!--                <select class="form-control bordered" [(ngModel)]="params.allocatedTo" (ngModelChange)="filterSearch()">-->
<!--                    <option [ngValue]="''">Allocated to</option>-->
<!--                    <option [ngValue]="u.id" *ngFor="let u of admins">{{u.name}}</option>-->
<!--                </select>-->
<!--            </div>-->
<!--        </div>-->


        <div class="d-flex justify-content-between mt-4 mb-4" *ngIf="(shipmentsBoard$ | async)">
            <div
                class="board-col-group"
                *ngFor="let g of (shipmentsBoard$ | async).groups">
                <h5 class="title">{{g.name}}</h5>
                <div class=" d-flex justify-content-between align-items-stretch">
                    <div
                        class="board-col"
                        *ngFor="let c of g.cols">
                        <h5 class="title">{{c.name}}</h5>
                        <div class="card-holder">

                            <div
                                class="card"
                                *ngFor="let card of c.cards">
                                <div class="row no-gutters mb-1">
                                    <div class="col-6">
                                        <p class="mb-1 sm">ORD-{{card.orderIdPublic}}/{{card.lineId}}</p>
                                    </div>
                                    <div class="col-4">
<!--                                        <a awesomeTooltip="Outstanding update request">-->
<!--                                            <i *ngIf="card.osUpdateRequests.length" class="fa fa-exclamation-circle text-danger"></i>-->
<!--                                        </a>-->
                                    </div>
                                    <div class="col-2 text-right">
                                        <a (click)="open(card)">
                                            <i class="fa fa-ellipsis-h"></i>
                                        </a>
<!--                                        <app-order-board-popup-->
<!--                                            *ngIf="card.open"-->
<!--                                            [card]="card"-->
<!--                                            [col]="c"-->
<!--                                            [params]="params"-->
<!--                                            (updateRequested)="requestUpdate($event)"></app-order-board-popup>-->
                                    </div>
                                </div>
<!--                                <p class="mb-1 md">{{card.contactsName}}</p>-->
                                <p class="mb-1 md">
                                    {{card.supplierName}}
                                </p>
                                <p class="mb-1 md">
                                    {{card.collectionPostcodeUsed}} <i class="fa fa-arrow-right"></i> {{card.deliveryPostcodeUsed}}
                                </p>
                                <div class="row">
                                    <div class="col-6">
                                        <p class="mb-1 md">{{card.pallets}} pallets</p>
                                    </div>
                                    <div class="col-6 text-right">
                                        <p class="mb-1 md">{{card.serviceName}}</p>
                                    </div>
                                </div>

                                <hr style="margin: 5px" />
                                <div class="text-center" *ngIf="card.status.id === 7">
<!--                                    <button type="button" class="btn btn-slim btn-yellow btn-block" style="padding: 5px 15px!important">-->
<!--                                        Send Questionnaire-->
<!--                                    </button>-->
                                </div>
                                <div class="row no-gutters mb-1" *ngIf="card.status.id !== 7">
                                    <div class="col-10">
                                        <p class="m-0 sm">
                                            Due {{card.dueDate | date: 'EEE dd MMM'}} ({{card.dueDays}} days)
                                        </p>
                                    </div>
                                    <div class="col-2 text-right">
                                        <i class="fa fa-circle" [ngClass]="card.bgCol"></i>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
