<div class="menu-top">
	<div class="logo-holder">
		<img
			*ngIf="domainClass === 'ys'"
			src="assets/images/chat/logo-image.svg" />
		<img
			*ngIf="domainClass === 'up'"
			src="assets/images/chat/logo-image-up.svg" />
	</div>
	<div class="chat-side-menu-switcher">
		<!--<app-chat-type-switcher
			[type]="chatGroupLogicService.chatType"
			(switched)="typeSwitched($event)"></app-chat-type-switcher>-->
	</div>
</div>

<hr class="separator mb-4" />

<div class="search-holder">
	<app-chat-search-field></app-chat-search-field>
</div>

<div class="accordion-list-holder">
	<app-chat-accordion-list
		[groups]="chatGroupLogicService.activeSubgroups"
		[selectedChatId]="openedChat.id"
		[openedSubgroup]="openedSubgroup"
		(chatSelected)="selectChat($event)"></app-chat-accordion-list>
</div>

<hr class="separator" />

<div class="menu-toggle-section">
	<div
		class="new-chat-button-holder"
		(click)="creatNewChat()">
		<span>Create New Chat</span>
		<svg-icon
			[svgClass]="'new-chat-button'"
			src="/assets/images/chat/icon-new-chat.svg"></svg-icon>
	</div>
</div>
<div
	class="archive-section"
	(click)="openArchive()">
	<svg-icon
		[svgClass]="'archive-section-icon'"
		src="/assets/images/chat/archive-box.svg"></svg-icon>
	<span class="archive-section-label">Chat Archive</span>
</div>
