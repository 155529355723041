import { Component, Input, OnInit } from '@angular/core';
import { IChatMessage } from '../../models/chat-message.interface';
import * as moment from 'moment';


export interface IOneDayMessageGroup {
  [day: string] : IChatMessage[];
}
@Component({
  selector: 'app-chat-day-message-block',
  templateUrl: './chat-day-message-block.component.html',
  styleUrls: ['./chat-day-message-block.component.scss']
})
export class ChatDayMessageBlockComponent implements OnInit {
  @Input() day: string;
  @Input() messageGroup: IChatMessage[] = [];
  date = new Date();

  getFormattedDate(date: string) {
    return moment(new Date(date)).format('Do MMMM YYYY'); 
  }

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges() {
  }

  get sortedMessages(): IChatMessage[] {
    if(this.messageGroup.length > 0) {
      this.messageGroup.sort(function(a, b){
        return a.createdAt.getTime() - b.createdAt.getTime();
      });
    }
    return this.messageGroup;
  }

}
