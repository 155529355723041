import { Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { pipe, Subject, take, tap } from "rxjs";
import { Store } from "src/app/store";
import { IChatMessageReminder } from "../models/chat-message-reminder.interface";
import { ChatRESTService } from "./chat-rest.service";

@Injectable({
  providedIn: 'root'
})
export class ChatReminderService {

  reminderList: IChatMessageReminder[];

  constructor(
    private chatRESTService: ChatRESTService,
    private toastrService: ToastrService,
    private router: Router,
    private store: Store,
    private zone: NgZone) { }

  initReminders() {
    this.chatRESTService.getReminders()
      .subscribe((reminders: IChatMessageReminder[]) => {
        const storedReminders = this.store.selectForLocal('cahtReminders');
        if(storedReminders) {
          reminders = reminders.filter(r => !storedReminders.map(sr => sr.id).includes(r.id))
        }
        this.store.set('cahtReminders', reminders);
        reminders.forEach((reminder) => {
          this.setReminderNotification(reminder);
        });
      });
  }

  addReminder(reminder: IChatMessageReminder) {
    return this.chatRESTService.setReminder(reminder)
      .pipe(tap((reminder) => {
        this.addReminderToStore(reminder);
        this.setReminderNotification(reminder)
      }));
  }

  private addReminderToStore(reminder: IChatMessageReminder) {
    const storedReminders: IChatMessageReminder[] = this.store.selectForLocal('cahtReminders');
    if(!storedReminders.map(sr => sr.id).includes(reminder.id)) {
      storedReminders.push(reminder);
      this.store.set('cahtReminders', storedReminders);
    }
  }

  private setReminderNotification(reminder: IChatMessageReminder) {
    const timeDiff = reminder.datetime.getTime() - new Date().getTime();
    if(timeDiff <= 0) {
      this.pushReminderNotification(reminder);
    } else {
      const that = this;
      setTimeout(function() {
        that.pushReminderNotification(reminder);
      }, timeDiff);
    }
  } 

  private pushReminderNotification(reminder: IChatMessageReminder) {
    const toastRef = this.toastrService.success(
      `<div class="reminder-title">Reminder: ACTION NOW</div>
      <div class="reminder-time">${moment(reminder.datetime).format('LT')}</div>`,
      null,
      {
        enableHtml: true,
        timeOut: 0,
        toastClass: 'reminder-toast ngx-toastr'
      }
    );
    toastRef.onTap
      .pipe(take(1))
      .subscribe(() => {
        this.deleteReminder(reminder.id);
        this.router.navigate([this.reminderPath(reminder)]);
      });
    toastRef.onHidden
      .pipe(take(1))
      .subscribe(() => {
        this.deleteReminder(reminder.id);
      });
  }

  private deleteReminder(reminderId: number) {
    this.chatRESTService.deleteReminder(reminderId).subscribe(() => {});
  }

  private reminderPath(reminder: IChatMessageReminder): string {
    return `/chat/${reminder.chatId}/${reminder.messageId}`;
  }
}
