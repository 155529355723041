<div class="table-responsive">
    <table class="table table-condensed">
        <thead class="thead-white">

        <tr>
            <th>Created</th>
            <th>Amount</th>
            <th>Order No</th>
            <th>Payment Method</th>
            <th>Card Number</th>
            <th>Expires</th>
        </tr>
<!--        <tr>-->
<!--            <th><input type="text" class="form-control" [(ngModel)]="params.created" (ngModelChange)="filterSearch()" /></th>-->
<!--            <th></th>-->
<!--            <th><input type="text" class="form-control" [(ngModel)]="params.orderId" (ngModelChange)="filterSearch()" /></th>-->
<!--            <th></th>-->
<!--            <th></th>-->
<!--            <th></th>-->
<!--        </tr>-->
        </thead>
        <tbody>
        <tr class="underline" *ngFor="let s of items">
            <td>{{s.createdAt | date: 'dd MMM yyyy @ HH:mm'}}</td>
            <td>{{s.amount | currency: 'GBP'}}</td>
            <td>{{s.publicId}}</td>
            <td>{{s.method.name}} ({{s.handler.name}})</td>
            <td>**** **** **** {{s.card.lastFour}}</td>
            <td>{{s.card.expireMonth}}/{{s.card.expireYear}}</td>
        </tr>
        </tbody>
    </table>
</div>

<div class="no-results" *ngIf="!items.length">
    <p>No payments found</p>
</div>

<app-pagination *ngIf="params.pageArray.length" [params]="params" (update)="search()"></app-pagination>
