
import { ErrorHandler, Injectable, Injector } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import { environment as env } from './environments/environment';
import { Store } from './app/store';

@Injectable()
export class YsErrorHandler implements ErrorHandler {

    constructor(private injector: Injector) { }

    handleError(error: any): void {
        if (error.toString().indexOf('ExpressionChangedAfterItHasBeenCheckedError') !== -1) {
            console.log('Suppressing error `ExpressionChangedAfterItHasBeenCheckedError`');
            return;
        }

        if (env.captureDebug) {
            const http = this.injector.get(HttpClient);
            const store = this.injector.get(Store);
            const user = store.selectForLocal('user');

            http
                .post(
                    `${env.apiPath}error-reporting`,
                    {
                        user: user ? user.id : null,
                        siteArea: 'Admin',
                        errorType: document.title,
                        description: error.message,
                        url: window.location.href.toString(),
                        error: error.toString(),
                        agent: navigator.userAgent,
                        isDev: env.isDev,
                        domain: env.domain,
                    }
                )
                .pipe(
                    catchError(this.consoleReport.bind(this))
                )
                .subscribe();
        } else {
            this.consoleReport(error);
        }
    }

    private consoleReport(error: any): void {
        // do something with the exception
        const toastr = this.injector.get(ToastrService);
        toastr.error('', `Oops something didn't quite work`);
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.error(error);
        }

        throwError('Something bad happened; please try again later.');
    }
}
