import { environment } from '../../../environments/environment';
import { ApiService } from '../../services/api.service';
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { setTimeout$ } from '../../utils/setTimeout$';
import {
    animate,
    animateChild,
    keyframes,
    query,
    stagger,
    state,
    style,
    transition,
    trigger
} from '@angular/animations';

@Component({
    selector: 'app-file-uploader',
    templateUrl: './file-uploader.component.html',
    styleUrls: ['./file-uploader.component.scss'],
    animations: [
        trigger('fade', [
            state('void', style({ opacity: 0 })),
            transition('void <=> *', [
                animate('200ms')
            ])
        ]),
        trigger('list', [
            transition('* => *', [
                query('@listItem', stagger(300, animateChild()), { optional: true })
            ])
        ]),
        trigger('listItem', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translateX(-50px)' }),
                animate('300ms', style({ opacity: 1, transform: 'translateX(0px)' }))
            ])
        ]),
        trigger('bounceIn', [
            state('void', style({ opacity: 0 })),
            transition('void <=> *', [
                animate(
                    '0.3s 0.3s cubic-bezier(0.215, 0.610, 0.355, 1.000)',
                    keyframes([
                        style({ opacity: 0, transform: 'scale3d(.3, .3, .3)', offset: 0 }),
                        style({ transform: 'scale3d(1.1, 1.1, 1.1)', offset: 0.2 }),
                        style({ transform: 'scale3d(.9, .9, .9)', offset: 0.4 }),
                        style({
                            opacity: 1,
                            transform: 'scale3d(1.03, 1.03, 1.03)',
                            offset: 0.6
                        }),
                        style({ transform: 'scale3d(.97, .97, .97)', offset: 0.8 }),
                        style({ opacity: 1, transform: 'scale3d(1, 1, 1)', offset: 1 })
                    ])
                ),
            ])
        ]),
    ]
})
export class FileUploaderComponent implements OnInit {
    @Input() uploadConfig = {
        url: '',
        acceptedFiles: '',
        maxFiles: 5,
        maxFileSize: 10024,
    };
    @Input() ownerType = '';
    @Input() ownerId = '';
    @Input() batchId = '';
    @Output() success = new EventEmitter();
    @Output() uploading = new EventEmitter();

    env = environment;
    files: any[] = [];
    errors: [];

    constructor(
        private http: HttpClient,
        private apiService: ApiService,
        private toastrService: ToastrService
    ) {
    }

    ngOnInit() {
    }

    onFileDropped($event) {
        this.prepareFilesList($event);
    }

    fileBrowseHandler(files) {
        this.prepareFilesList(files);
    }

    /**
     * Delete file from files list
     * @param index (File index)
     */
    deleteFile(index: number) {
        this.files.splice(index, 1);
    }

    uploadFiles() {
        if (this.uploadConfig.url) {
            this.files.forEach((file, index) => {
                const formData: any = new FormData();
                formData.append('file', file);
                formData.append('fileId', this.getUniqueId(1));
                formData.append('type', this.ownerType);
                formData.append('userId', this.ownerId);
                formData.append('batchId', this.batchId);
                this.http.post(this.uploadConfig.url, formData, this.apiService.getHttpOptionsUpload())
                    .subscribe((event: HttpEvent<any>) => {
                        switch (event.type) {
                            case HttpEventType.Sent:
                                break;
                            case HttpEventType.ResponseHeader:
                                break;
                            case HttpEventType.UploadProgress:
                                this.uploading.emit();
                                this.files[index].progress = Math.round(event.loaded / event.total * 100);
                                break;
                            case HttpEventType.Response:
                                this.success.emit(event.body);
                                setTimeout$(() => {
                                    this.files[index].loaded = true;
                                }, 2000);
                        }
                    }, error => {
                        this.files[index].error = true;
                        throw new Error('Sorry, something went wrong - please try again in a couple of minutes.');
                    });
            });
        }
    }


    /**
     * Convert Files list to normal array list
     * @param files (Files List)
     */
    prepareFilesList(files: Array<any>) {
        const errors = [];
        const filesArray = [...files];
        filesArray.forEach((file, index) => {
            file.errors = [];

            if (index + 1 > this.uploadConfig.maxFiles) {
                file.errors.push('Only one file for upload allowed.');
            }

            if (this.uploadConfig.acceptedFiles) {
                let isAccepted = false;
                const acceptedFilesArray = this.uploadConfig.acceptedFiles.split(',');
                acceptedFilesArray.forEach((acceptedFile) => {
                    if (acceptedFile === file.type) {
                        isAccepted = true;
                    }
                });

                if (!isAccepted) {
                    file.errors.push('File format is not allowed.');
                }
            }

            if (file.size > this.uploadConfig.maxFileSize) {
                file.errors.push('File size is too large.');
            }

            if (file.errors.length) {
                this.toastrService.error(file.errors[0]);
            } else {
                file.progress = 0;
                this.files.push(file);
            }
        });
        this.uploadFiles();
    }

    /**
     * format bytes
     * @param bytes (File size in bytes)
     * @param decimals (Decimals point)
     */
    formatBytes(bytes, decimals) {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals || 2;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    /**
     * generate groups of 4 random characters
     * @example getUniqueId(1) : 607f
     * @example getUniqueId(2) : 95ca-361a-f8a1-1e73
     */
    getUniqueId(parts: number): string {
        const stringArr = [];
        for (let i = 0; i < parts; i++) {
            // tslint:disable-next-line:no-bitwise
            const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
            stringArr.push(S4);
        }
        return stringArr.join('-');
    }

}
