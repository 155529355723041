import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '../../store';
import { UserService } from '../../services/user.service';
import { BasketService } from '../../services/basket.service';
import {AuthService} from '../../services/auth.service';
import {NotificationService} from '../../services/notification.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    navigationHistory = [];
    params: any;

    constructor(
        private userService: UserService,
        private basketService: BasketService,
        private router: Router,
        private route: ActivatedRoute,
        private store: Store,
        private authService: AuthService,
        private notificationService: NotificationService,
    ) {
    }

    ngOnInit() {
        if (this.route.snapshot.queryParams.resetLogin) {
            this.notificationService.stopListening();
            this.authService.logout().subscribe(() => {
                this.authService.clearAuthTimeout();
            });
        }
        this.navigationHistory = this.store.selectForLocal('navigationHistory');
    }

    authenticated(e) {
        if (e) {
            const basket = this.store.selectForLocal('basket');
            const user = this.store.selectForLocal('user');
            let deliveryAddresses = [];

            basket.customer = user;
            basket.deliveryAddress = undefined;
            basket.billingAddress = undefined;

            if (user.address) {
                basket.billingAddress = user.address;
            }

            this.userService
                .findAddressesByCustomer(user.id)
                .subscribe((data: any) => {
                    deliveryAddresses = data.data;

                    if (deliveryAddresses.length) {
                        basket.deliveryAddress = deliveryAddresses[0];
                        basket.deliveryPostcode = deliveryAddresses[0].postcode;
                    }
                    this.store.set('deliveryAddresses', deliveryAddresses);
                    this.basketService.saveBasketChanges(basket, 'modalAuth');
                    if (this.navigationHistory.length) {
                        this.router.navigate(['/account']);
                      //  this.router.navigate([this.navigationHistory[0]]);
                    } else {
                        this.router.navigate(['/account']);
                    }
                });
        }
    }

    goToRegister() {
        this.router.navigate(['/register']);
    }

}
