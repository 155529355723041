import { Component, OnInit } from '@angular/core';
import { Store } from '../../store';
import { AuthService } from '../../services/auth.service';
import { AlertService } from '../../services/alert.service';
import { Router } from '@angular/router';
import { OverlayService } from '../../services/overlay.service';
import { NotificationService } from '../../services/notification.service';

@Component({
    selector: 'app-account-header-mobile',
    templateUrl: './account-header-mobile.component.html',
    styleUrls: ['./account-header-mobile.component.scss']
})
export class AccountHeaderMobileComponent implements OnInit {

    user$ = this.store.select<any>('user');
    basket$ = this.store.select<any>('basket');
    notifications$ = this.store.select<any>('notifications');
    chatStore$ = this.store.select<any>('chatStore');
    settings$ = this.store.select<any>('settings');
    deviceType$ = this.store.select<string>('deviceType');
    favourites$ = this.store.select<string>('favourites');

    confirmationData = {
        open: false,
        title: '',
        detail: '',
        data: undefined
    };

    constructor(
        private store: Store,
        private authService: AuthService,
        private alertService: AlertService,
        public router: Router,
        private overlayService: OverlayService,
        private notificationService: NotificationService,
    ) {
    }

    ngOnInit(): void {
    }

    toggleMenu() {
        this.store.set('mobileMenu', true);
    }

    toggleUserMenu() {
        this.store.set('mobileMenuUser', true);
    }

    toggleNotifications() {
        this.overlayService.toggle('notifications');
    }

    toggleChat() {
        this.overlayService.toggle('chat');
    }

    logout() {
        const notifications = { unread: 0, data: [] };
        const chatStore = {
            unread: 0,
            groups: [],
            group: undefined,
            replyingTo: undefined,
            groupToOpen: undefined,
            messages: [],
        };

        this.store.set('chatStore', chatStore);
        this.store.set('notifications', notifications);

        this.notificationService.stopListening();
        this.authService.logout().subscribe(() => {
            this.authService.clearAuthTimeout();
            this.router.navigate(['login']);
        });
    }

    logoutConfirmation() {
        this.confirmationData.title = 'Are you sure you want to logout?';
        this.confirmationData.data = {};
        this.confirmationData.detail = '';
        this.confirmationData.open = true;
    }

    confirmationComplete(e) {
        this.confirmationData.open = false;
        if (e.action) {
            this.logout();
        } else {
        }
    }
}
