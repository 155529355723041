import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { environment } from '../../environments/environment';
import { DirectivesModule } from '../directives/directives.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgSelectModule } from '@ng-select/ng-select';
import { PipesModule } from '../pipes/pipes.module';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { ChatRoutingModule } from './chat-routing.module';
import { ChatComponent } from './chat.component';
import { ChatTypeSwitcherComponent } from './components/chat-type-switcher/chat-type-switcher.component';
import { ChatSideMenuComponent } from './components/chat-side-menu/chat-side-menu.component';
import { ChatAccordionListComponent } from './components/chat-accordion-list/chat-accordion-list.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ChatPanelComponent } from './components/chat-panel/chat-panel.component';
import { ChatMessagesAreaComponent } from './components/chat-messages-area/chat-messages-area.component';
import { ChatMessageComponent } from './components/chat-message/chat-message.component';
import { ChatDayMessageBlockComponent } from './components/chat-day-message-block/chat-day-message-block.component';
import { MatMenuModule } from '@angular/material/menu';
import { NewSupportChatDialogComponent } from './dialogs/new-support-chat-dialog/new-support-chat-dialog.component';
import { ChatArchiveComponent } from './components/chat-archive/chat-archive.component';
import { ChatArchiveRowComponent } from './components/chat-archive-row/chat-archive-row.component';
import { ChatSearchFieldComponent } from './components/chat-search-field/chat-search-field.component';
import { UserSelectorDlgComponent } from './dialogs/user-selector-dlg/user-selector-dlg.component';
import { MessageReminderDlgComponent } from './dialogs/message-reminder-dlg/message-reminder-dlg.component';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CustomDatepickerHeader } from './dialogs/message-reminder-dlg/custom-dp-header/custom-datepicker-header';
import { ChatEditorComponent } from './components/chat-editor/chat-editor.component';
import { MatDialogModule } from '@angular/material/dialog';

const config: SocketIoConfig = { url: environment.socketPath, options: {} };

@NgModule({
	declarations: [
		ChatComponent,
		ChatTypeSwitcherComponent,
		ChatSideMenuComponent,
		ChatAccordionListComponent,
		ChatPanelComponent,
		ChatMessagesAreaComponent,
		ChatMessageComponent,
		ChatDayMessageBlockComponent,
		UserSelectorDlgComponent,
		ChatArchiveComponent,
		ChatArchiveRowComponent,
		ChatSearchFieldComponent,
		MessageReminderDlgComponent,
		CustomDatepickerHeader,
		ChatEditorComponent,
		NewSupportChatDialogComponent,
	],
	imports: [
		ChatRoutingModule,
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		SharedModule,
		DirectivesModule,
		SocketIoModule.forRoot(config),
		NgMultiSelectDropDownModule.forRoot(),
		NgSelectModule,
		PipesModule,
		DropzoneModule,
		CdkAccordionModule,
		AngularSvgIconModule,
		MatMenuModule,
		MatIconModule,
		MatDatepickerModule,
		MatFormFieldModule,
		MatAutocompleteModule,
		MatDialogModule,
	],
})
export class ChatModule {}
