<ckeditor
    #editor
    [editor]="Editor"
    [config]="config"
    [ngClass]="{ 'replay-mode': resendModeActive() }"
    [(ngModel)]="content"
    (ready)="onEditorReady()">
</ckeditor>
<button
    class="send-btn btn-styled"
    type="button"
    [disabled]="canNotSend()"
    (click)="saveMessage()">
    {{ editMode ? 'Save' : 'Send' }}
    <i class="fa fa-chevron-right icon-right"></i>
</button>
