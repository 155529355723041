<div class="message-reminder-top">
  <div>Message Reminder</div>
</div>
<div class="message-reminder-body">
  <form [formGroup]="form">
    <div class="date-selector-holder">
      <label class="padded-top-gray-label">Date:</label>
      <div class="date-selector">
        <!--<div class="date-selector-text">Set Date</div>-->
        <input 
          class="date-selector-text" 
          readonly 
          placeholder="Set Date" 
          matInput 
          [matDatepicker]="picker"
          [min]="todayDate"
          formControlName="date" 
          (click)="picker.open()">
        <mat-datepicker-toggle class="calendar-icon" [for]="picker">
          <mat-icon matDatepickerToggleIcon>
            <img class="calendar-icon" src="assets/images/calendar-simple-icon.svg"/>
          </mat-icon>
        </mat-datepicker-toggle>
        <!---->
      </div>
    </div>
    <mat-datepicker 
      #picker 
      [calendarHeaderComponent]="customDatepickerHeader"
      panelClass="reminder-datepicker"></mat-datepicker>
    
    <div class="time-selector-holder">
      <label class="padded-top-gray-label">Time:</label>
      <ng-select
        formControlName="time"
        [disabled]="!dateSelected"
        [clearable]="false"
        [searchable]="false"
        placeholder="Set Time"
        class="dd-select dropdown-time-selector">
        <ng-option *ngFor="let moment of moments" [value]="moment.moment">{{ moment.label }}</ng-option>
      </ng-select>
    </div>
    <div class="comment-holder">
      <label class="padded-top-gray-label">Notes (Not Obligatory):</label>
      <textarea
        class="textarea-styled"
        rows="8"
        placeholder="Enter Comment"
        formControlName="comment"></textarea>
    </div>
  </form>

  <div class="buttons-holder">
    <button 
      class="btn btn-styled--secondary btn-block btn-select" 
      type="button"
      mat-dialog-close>
      Cancel
    </button>
    <button 
      class="btn btn-styled btn-block btn-select mx-2" 
      type="button"
      (click)="create()"
      [disabled]="!form.valid">
      Create
    </button>
</div>
