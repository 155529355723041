<div 
  class="wrapper"
  [style.backgroundColor]="getColour()"
  [ngStyle]="{ 
    'font-size': fontSize ? fontSize : 10, 
    'line-height' : fontSize ? fontSize : 10,
    'font-weight' : fontWeight ? fontWeight : 'bold'
  }">
  {{ imagePlaceholder }}
  <div *ngIf="isOnline()" class="online-mark"></div>
</div>
