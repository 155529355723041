export enum EChatSubgroup {
    Orders = 'CHAT_GROUP_TYPE_ORDERS',
    Actions = 'CHAT_GROUP_TYPE_ACTIONS',
    Managers = 'CHAT_GROUP_TYPE_MANAGERS',
    Suppliers = 'CHAT_GROUP_TYPE_SUPPLIERS',
    Couriers = 'CHAT_GROUP_TYPE_COURIERS',
    Channels = 'CHAT_GROUP_TYPE_CHANNELS',
    Unknown = 'CHAT_GROUP_TYPE_UNKNOWN',
    Private = 'CHAT_GROUP_TYPE_PRIVATE',
    Trade = 'CHAT_GROUP_TYPE_TRADE',
    Premium = 'CHAT_GROUP_TYPE_PREMIUM',
    CustomerService = 'CHAT_GROUP_TYPE_CUSTROMER_SERVICE',
}
