import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-simple-image-viwer',
    templateUrl: './simple-image-viwer.component.html',
    styleUrls: ['./simple-image-viwer.component.scss'],
})
export class SimpleImageViwerComponent implements OnInit {
    @Input() viewImage$ = new Subject<string>();

    imgPath = null;

    constructor() {}

    public viewImage(imgPath: string) {
        this.imgPath = imgPath;
    }

    ngOnInit(): void {
        this.viewImage$.pipe().subscribe((imgPath) => {
            this.viewImage(imgPath);
        });
    }

    close() {
        this.imgPath = null;
    }

    downloadImage(url) {
        const filename = url.split('/').pop();
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.withCredentials = true;
        xhr.onload = function () {
            const a = document.createElement('a');
            a.href = window.URL.createObjectURL(xhr.response);
            a.download = filename;
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        };
        xhr.open('GET', url);
        xhr.send();
    }
}
