import { EChatType } from '../enums';
import { IChatListItem } from '../models';

export class ChatListItemMapper {
    public static toDomain(dto: any): IChatListItem {
        return {
            ...dto,
            members: dto.users.map((u) => {
                u.firstname = u.firstname || 'unknown';
                u.surname = u.surname || 'unknown';
                u.id = u.userId;
                return u;
            }),
            lastAction: new Date(dto.lastAction),
            createdAt: new Date(dto.createdAt),
            unread: parseInt(dto.unread),
            messagesCnt: parseInt(dto.messagesCnt),
            type: this.mapType(dto.type),
        };
    }

    public static mapType(type: string): EChatType {
        switch (type) {
            case 'Channel':
                return EChatType.Channel;
            case 'Order':
                return EChatType.Order;
            case 'Action':
                return EChatType.Action;
            case 'CustomerService':
                return EChatType.CustomerService;
            default:
                return EChatType.DefaultChat;
        }
    }
}
