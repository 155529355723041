import { Component, OnInit, Input, OnChanges, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { IChat, IChatMessage, IChatMember, IChatEvent } from '../../models';
import { IOneDayMessageGroup } from '../chat-day-message-block/chat-day-message-block.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChatRESTService } from '../../services/chat-rest.service';
import { Store } from 'src/app/store';
import { ChatGroupLogicService } from '../../services/chat-group-logic.service';
import { EChatGroupType } from '../../enums/chat-group-type.enum';
import { ChatEventsService } from '../../services/chat-events.service';
import { EChatEventName, EChatType } from '../../enums';
import { IChatOpened } from '../../chat.component';
import { ChatMessageComponent } from '../chat-message/chat-message.component';
import { ChatDialogService } from '../../services/chat-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { UserAccountType } from 'src/app/data-types/user-data';

@UntilDestroy()
@Component({
    selector: 'app-chat-messages-area',
    templateUrl: './chat-messages-area.component.html',
    styleUrls: ['./chat-messages-area.component.scss'],
})
export class ChatMessagesAreaComponent implements OnInit, OnChanges {
    @Input() chat: IChatOpened;
    @ViewChild('scrollPanel') scrollPanel: ElementRef;

    EChatGroupType = EChatGroupType;
    EChatType = EChatType;

    user = this.store.selectForLocal('user');

    messagesGroups: IOneDayMessageGroup[];

    title: string | null = null;
    senderEmail: string | null = null;
    senderLeadLink: string | null = null;

    get chatMembersWithouUser(): IChatMember[] {
        return this.chat.members.filter((m) => m.id !== this.user.id);
    }

    get sortedMembers(): IChatMember[] {
        if (!this.chat.members?.length) return [];

        this.chat.members.sort((a, b) => {
            return (a.firstname + a.surname).localeCompare(b.firstname + b.surname);
        });
        const currentUser = this.chat.members.find((m) => m.id === this.user.id);
        this.chat.members = this.chat.members.filter((m) => m.id !== this.user.id);
        this.chat.members.unshift(currentUser);
        return this.chat.members;
    }

    constructor(
        public chatGroupLogicService: ChatGroupLogicService,
        private chatRESTService: ChatRESTService,
        private chatEventsService: ChatEventsService,
        private chatDialogService: ChatDialogService,
        private toastrService: ToastrService,
        private cdr: ChangeDetectorRef,
        private store: Store,
        private el: ElementRef
    ) {}

    ngOnInit(): void {
        this.chatEventsService
            .listenEvent(EChatEventName.MessageAdded)
            .pipe(untilDestroyed(this))
            .subscribe((event: IChatEvent) => {
                const message = event.data;
                // do not add messages from other chats/channels
                if (
                    message.chatId !== this.chat.id &&
                    (this.chat.id !== null || message.id !== this.chat.sendingMessageId)
                )
                    return;
                if (this.chat.id !== null) {
                    this.chatRESTService.markAsRead(this.chat.id).subscribe();
                }
                this.chat.messages.push(message);
                this.cdr.detectChanges();
                this.messagesGroups = this.groupByDays(this.chat.messages);
                this.scrollMesagesDown();
            });

        this.chatEventsService
            .listenEvent(EChatEventName.MessageEdited)
            .pipe(untilDestroyed(this))
            .subscribe((event: IChatEvent) => {
                const editedMessage: IChatMessage = event.data.message;
                const message: IChatMessage = this.chat.messages.find((m) => m.id === editedMessage.id);
                if (!message) return;
                message.content = editedMessage.content;
                this.messagesGroups = this.groupByDays(this.chat.messages);
                if (this.chat.sendingMessageId === editedMessage.id) {
                    this.chat.sendingMessageId = null;
                }
            });

        this.chatEventsService
            .listenEvent(EChatEventName.MessageDeleted)
            .pipe(untilDestroyed(this))
            .subscribe((event: IChatEvent) => {
                const deletedMessage = event.data.message;
                this.chat.messages = this.chat.messages.filter((m) => m.id !== deletedMessage.id);
                this.messagesGroups = this.groupByDays(this.chat.messages);
            });
    }

    ngOnChanges() {
        this.messagesGroups = this.groupByDays(this.chat.messages);
        this.setViewParams();
    }

    ngAfterViewInit() {
        this.setViewParams();
        this.scrollMessages();
    }

    defineGroupOf(chat: IChat): EChatGroupType {
        return this.chatGroupLogicService.defineGroupOf(chat);
    }

    /**
     * Returns amount of archived chats for group (ADMIN/CUSTOMER)
     * that passed chat belongs to.
     */
    getArchiveChatsCnt(chat: IChat): number {
        const group: EChatGroupType = this.defineGroupOf(chat);
        const archivedChats = this.chatGroupLogicService.getArhivedChatsBy(group);
        return archivedChats.length;
    }

    isCurrentUser(member: IChatMember): 'member' | 'creator' | false {
        if (member.id === this.user.id) {
            if (this.chat.createdBy === this.user.id) {
                return 'creator';
            } else {
                return 'member';
            }
        } else {
            return false;
        }
    }

    removeMember(member: IChatMember): void {
        this.chatRESTService
            .removeMemberFromChannel(member, this.chat.id)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.chat.members = this.chat.members.filter((m) => m !== member);
                this.toastrService.success('User removed');
            });
    }

    async addMember(): Promise<void> {
        const newMember: IChatMember = (await this.chatDialogService.openUsersSelectorDlg(
            'single',
            'Apply',
            [],
            [UserAccountType.Admin],
            false
        )) as IChatMember;
        if (!newMember) return;

        this.chatRESTService
            .addMemberToChannel(newMember, this.chat.id)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.chat.members.push(newMember);
                this.toastrService.success('User added');
            });
    }

    archiveChat() {
        this.chatRESTService
            .archiveChat(this.chat.id)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.chatEventsService.emitEvent(EChatEventName.ChatUnselected, null);
                this.chatGroupLogicService.moveChatToArchive(this.chat.id);
                this.toastrService.success('Chat was archived');
            });
    }

    leaveGroup() {
        this.chatRESTService
            .removeMemberFromChannel(this.user, this.chat.id)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.toastrService.success('You left the group');
                this.chatEventsService.emitEvent(EChatEventName.ChatUnselected, null);
                this.chatGroupLogicService.removeChat(this.chat.id);
                //this.chatEventsService.emitEvent(EChatEventName.ChatRemoved, this.chat);
            });
    }

    /**
     * Grouping chat message in one-day blocks
     */
    private groupByDays(chat: IChatMessage[]): IOneDayMessageGroup[] {
        let dayMessageBlocks: IOneDayMessageGroup[] = [];
        const messages = chat.map((m) => {
            return { ...m, day: new Date(m.createdAt).toISOString().split('T')[0] };
        });

        dayMessageBlocks = messages.reduce(function (rv, x) {
            (rv[x['day']] = rv[x['day']] || []).push(x);
            return rv;
        }, []);

        return dayMessageBlocks;
    }

    private scrollMessages() {
        setTimeout(() => {
            const targetMessageEl = this.el.nativeElement.querySelectorAll(
                `.${ChatMessageComponent.TARGET_MESSAGE_CLASS}`
            )[0];
            if (this.scrollPanel) {
                if (targetMessageEl) {
                    this.scrollPanel.nativeElement.scrollTop = targetMessageEl.offsetTop;
                } else {
                    this.scrollPanel.nativeElement.scrollTop =
                        this.chat.isClosed === 1 ? 0 : this.scrollPanel.nativeElement.scrollHeight;
                }
            }
        }, 300);
    }

    private scrollMesagesDown() {
        setTimeout(() => {
            this.scrollPanel.nativeElement.scrollTop = this.scrollPanel.nativeElement.scrollHeight;
        }, 300);
    }

    private setViewParams() {
        this.setViewParamTitle();
        this.setViewParamSenderEmail();
        this.setViewParamSendareLeadLink();
    }

    private setViewParamTitle() {
        if (this.chat?.mode === EChatType.Channel && this.chat?.name) {
            this.title = this.chat.name;
            return;
        }

        let m = this.chatMembersWithouUser[0];
        // for the case when the user is only one persone in chat
        if (!m && this.chat.members.length) {
            m = this.chat.members[0];
        }
        let title = m.firstname + ' ' + m.surname + (m.companyName ? ' - ' + m.companyName : '');
        this.title = title;
    }

    private setViewParamSenderEmail() {
        if (this.chat.type === EChatType.CustomerService) {
            this.senderEmail = this.chat.createdByEmail;
        } else {
            this.senderEmail = null;
        }
    }

    private setViewParamSendareLeadLink() {
        if (this.chat.type === EChatType.CustomerService && this.chat.senderIsLead && this.chat.createdById) {
            this.senderLeadLink = '/leads/' + this.chat.createdById;
        } else {
            this.senderLeadLink = null;
        }
    }
}
