<cdk-accordion
    class="chat-accordion-list"
    [multi]="true">
    <cdk-accordion-item
        *ngFor="let group of groups; let index = index"
        #accordionItem="cdkAccordionItem"
        class="chat-accordion-list-item"
        role="button"
        tabindex="0"
        [expanded]="group.type === openedSubgroup"
        [attr.id]="'accordion-header-' + index"
        [attr.aria-expanded]="accordionItem.expanded"
        [attr.aria-controls]="'accordion-body-' + index">
        <div
            class="chat-accordion-list-item-header"
            (click)="accordionItem.toggle()">
            <div class="chat-accordion-list-item-title">
                <div>
                    <span>{{ group.title }}</span>
                    <div
                        class="unread-cnt"
                        *ngIf="group.unreadCnt">
                        {{ group.unreadCnt }}
                    </div>
                </div>
            </div>
            <div class="chat-accordion-list-item-toggle">
                <svg-icon
                    *ngIf="!accordionItem.expanded"
                    src="assets/images/icon-chevron-down.svg"
                    [class]="'hover'"
                    [svgClass]="'open-close-arrow'"></svg-icon>
                <svg-icon
                    *ngIf="accordionItem.expanded"
                    src="assets/images/icon-chevron-up.svg"
                    [class]="'hover'"
                    [svgClass]="'open-close-arrow'"></svg-icon>
            </div>
        </div>
        <div
            class="chat-accordion-list-item-body"
            role="region"
            [style.display]="accordionItem.expanded ? '' : 'none'"
            [attr.id]="'accordion-body-' + index"
            [attr.aria-labelledby]="'accordion-header-' + index">
            <ng-container>
                <div
                    class="no-chats-yet"
                    *ngIf="!group.chatItems.length">
                    No chats yet
                </div>
                <div
                    class="user-row"
                    *ngFor="let chatMenuItem of group.chatItems"
                    [ngClass]="{ selectedChat: chatMenuItem.id === selectedChatId }">
                    <div
                        *ngIf="needAvatar(group)"
                        class="user-avatar">
                        <app-avatar
                            [user]="chatMenuItem.chatMemberForDisplay"
                            [fontSize]="'8px'"
                            [fontWeight]="'300'"
                            border="1px solid #fff"></app-avatar>
                    </div>
                    <!--<div *ngIf="chatMenuItem.members.length > 1" class="number-of-memebers">
            {{ chatMenuItem.members.length }}
          </div>-->
                    <div
                        class="user-name-holder"
                        (click)="selectChat(chatMenuItem.id)">
                        <div class="user-name">{{ chatMenuItem.chatNameForDisplay }}</div>
                        <div
                            *ngIf="chatMenuItem.unread"
                            class="unread-cnt">
                            {{ chatMenuItem.unread }}
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </cdk-accordion-item>
</cdk-accordion>
