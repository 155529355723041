import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'leadtime'
})
export class LeadtimePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (value > 13) {
            return `${Math.floor(value / 7)} week${this.descriptionSuffix(value)}`;
        }
        return `${value} day${this.descriptionSuffix(value)}`;
    }

    descriptionSuffix(days) {
        if (days !== 1) {
            return 's';
        }
        return '';
    }
}
