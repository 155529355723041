import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { debounceTime, map } from 'rxjs';
import { Store } from 'src/app/store';
import { EChatEventName, EChatType } from '../../enums';
import { IChatListItem, IChatMember } from '../../models';
import { ChatEventsService } from '../../services/chat-events.service';
import { ChatGroupLogicService } from '../../services/chat-group-logic.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export interface ISearchGroupedResults {
    data: {
        [key in EChatType]: ISearchResultItem[];
    };
    total: number;
}

export interface ISearchResultItem {
    chat: IChatListItem;
    member?: IChatMember;
}

@UntilDestroy()
@Component({
    selector: 'app-chat-search-field',
    templateUrl: './chat-search-field.component.html',
    styleUrls: ['./chat-search-field.component.scss'],
})
export class ChatSearchFieldComponent implements OnInit {
    @ViewChild('searchMenuTrigger') searchMenuTrigger: MatMenuTrigger;

    EChatType = EChatType;

    user = this.store.selectForLocal('user');

    searchText = new FormControl('');

    defaultSearchResults: ISearchGroupedResults = {
        data: {
            [EChatType.DefaultChat]: [],
            [EChatType.Action]: [],
            [EChatType.Order]: [],
            [EChatType.Channel]: [],
            [EChatType.CustomerService]: [],
        },
        total: 0,
    };
    searchResults: ISearchGroupedResults = this.defaultSearchResults;

    constructor(
        private chatGroupLogicService: ChatGroupLogicService,
        private chatEventsService: ChatEventsService,
        private store: Store
    ) {}

    ngOnInit(): void {
        this.searchText.valueChanges
            .pipe(untilDestroyed(this))
            .pipe(debounceTime(400))
            .pipe(
                map((term) => {
                    const result: ISearchGroupedResults = JSON.parse(JSON.stringify(this.defaultSearchResults));

                    if (term.trim().length === 0) return result;

                    this.chatGroupLogicService.chats.forEach((chat) => {
                        chat.members
                            .filter((m) => m.id !== this.user.id)
                            .find((m) => {
                                const fullname: string = m.firstname + ' ' + m.surname;
                                if (fullname.toLocaleLowerCase().includes(term.toLocaleLowerCase())) {
                                    const r: ISearchResultItem = {
                                        chat: chat as IChatListItem,
                                        member: m,
                                    };
                                    result.data[chat.type].push(r);
                                    result.total++;
                                }
                            });

                        // handle channel names matches
                        if (chat.name?.toLocaleLowerCase().includes(term.toLocaleLowerCase())) {
                            if (!result.data[chat.type].find((r) => r.chat.id === chat.id)) {
                                result.data[chat.type].push({
                                    chat: chat as IChatListItem,
                                });
                                result.total++;
                            }
                        }
                    });
                    return result;
                })
            )
            .subscribe((result) => {
                this.searchResults = result;
                if (this.searchResults.total) {
                    this.searchMenuTrigger.openMenu();
                }
            });
    }

    onSearchFocus(): void {
        if (this.searchResults.total) this.searchMenuTrigger.openMenu();
    }

    highlightMatches(text: string) {
        const searchText = this.searchText.value;
        if (searchText) {
            const regex = new RegExp(searchText, 'gi');
            return text.replace(regex, '<mark class="highlighted">$&</mark>');
        }
        return text;
    }

    selectChat(chatId: number) {
        this.chatEventsService.emitEvent(EChatEventName.ChatSelected, {
            id: chatId,
        });
    }
}
