import { Injectable } from '@angular/core';
import { IChatEvent } from '../models';
import { filter, Subject } from "rxjs";
import { EChatEventName } from '../enums';


@Injectable({
  providedIn: 'root'
})
export class ChatEventsService {
  private _events$ = new Subject<IChatEvent>();

  constructor() { }

  listenEvent(eventName: EChatEventName) {
    return this._events$.pipe(filter(e => e.name === eventName));
  }

  emitEvent(eventName: EChatEventName, eventData: any) {
    this._events$.next({
      name: eventName,
      data: eventData
    });
  }
}
