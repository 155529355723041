import {Component, Input, OnInit} from '@angular/core';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-user-avatar',
    templateUrl: './user-avatar.component.html',
    styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent implements OnInit {
    @Input() firstname: string
    @Input() lastname: string
    @Input() colour;
    @Input() fontSize: string = null;
    @Input() fontWeight: string = null;
    @Input() detectOnline: boolean = false;
    @Input() userId: string;

    defaultColour = 'rgb(46, 121, 126)';

    imagePlaceholder = ''
    constructor(private userService: UserService) {}

    ngOnInit(): void {
        if (this.firstname && this.lastname) {
            this.imagePlaceholder = `${this.firstname.charAt(0)?.toUpperCase()}${this.lastname.charAt(0)?.toUpperCase()}`
        }
    }

    getColour( ) {
        return this.colour && this.colour.length ? this.colour : this.defaultColour;
    }

    isOnline() {
        return this.userService.userOnline(this.userId);
    }

}
