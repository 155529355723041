import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { HttpClient } from "@angular/common/http";
import { Store } from "../store";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { ToolsService } from "./tools.service";
import { CookieService } from "./cookie.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  env = environment;

  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private store: Store,
    private cookieService: CookieService,
    private toolsService: ToolsService
  ) {}

  logSession(userId, sessionId) {
    const device = this.store.selectForLocal("device");

    const dataToSend = {
      device: device.info,
      userId,
      sessionId,
    };
    return this.http
      .post(this.env.apiPath + "users/device", dataToSend)
      .pipe(map((data: any) => data));
  }

  async postcodeLookup(postcode) {
    const result: any = await this.http
      .get(
        "https://api.getAddress.io/find/" +
          postcode +
          "?api-key=CzqJCvY6GEeVcLJLm0kJWQ28605&expand=true&sort=true"
      )
      .toPromise();
    return result.addresses;
  }

  async createUserNew(user) {
    const dataToSend = {
      user,
      createdBy: this.store.selectForLocal("user"),
    };
    const result: any = await this.http
      .post(this.env.apiPath + "users/new", dataToSend)
      .toPromise();
    return result.data;
  }

  async createAddressNew(address, user) {
    const dataToSend = {
      address,
      user,
      createdBy: this.store.selectForLocal("user"),
    };
    const result: any = await this.http
      .post(this.env.apiPath + "users/address/new", dataToSend)
      .toPromise();

    return result.data;
  }

  async createCompanyNew(company, user) {
    const dataToSend = {
      company,
      user,
      createdBy: this.store.selectForLocal("user"),
    };
    const result: any = await this.http
      .post(this.env.apiPath + "users/company/new", dataToSend)
      .toPromise();
    return result.data;
  }

  RegistrationAdditionalDetails(address, company, user) {
    const dataToSend = {
      address,
      company,
      user,
    };
    return this.http
      .post(this.env.apiPath + "users/company/additional", dataToSend)
      .pipe(map((data: any) => data));
  }

  createUser(user) {
    return this.http
      .post(this.env.apiPath + "users/signup", user)
      .pipe(map((data: any) => data));
  }

  async createUserAsync(user) {
    const result: any = await this.http
      .post(this.env.apiPath + "users/signup", user)
      .toPromise();
    return result.data;
  }

  async checkUserExists(email) {
    const result: any = await this.http
      .get(this.env.apiPath + "users/exists/" + email)
      .toPromise();
    return result.data;
  }

  checkReferralCode(referralCode, referralEmail) {
    const dataToSend = {
      referralCode,
      referralEmail,
    };
    return this.http
      .post(this.env.apiPath + "users/referralcode/check", dataToSend)
      .pipe(map((data: any) => data));
  }

  async createGuest(user) {
    const result: any = await this.http
      .post(this.env.apiPath + "users/signup", user)
      .toPromise();
    return result.data;
  }

  async createAddress(address, createdBy) {
    const dataToSend = {
      address,
      createdBy,
    };
    const result: any = await this.http
      .post(this.env.apiPath + "users/address", dataToSend)
      .toPromise();
    return result;
  }

  updateAddress(address) {
    const user = this.store.selectForLocal("user");
    const dataToSend = {
      address,
      user,
    };
    return this.http
      .put(this.env.apiPath + "users/address/" + address.id, dataToSend)
      .pipe(map((data: any) => data));
  }

  updatePrimaryUser(companyId, userId) {
    const dataToSend = {
      companyId,
      userId,
    };
    return this.http
      .put(
        this.env.apiPath + "users/company/" + companyId + "/primaryuser",
        dataToSend
      )
      .pipe(map((data: any) => data));
  }

  createCompany(company, createdBy) {
    const dataToSend = {
      company,
      createdBy,
    };
    return this.http
      .post(this.env.apiPath + "users/company", dataToSend)
      .pipe(map((data: any) => data));
  }

  getByToken() {
    return this.http.get(this.env.apiPath + "users/profile").pipe(
      map((data: any) => {
        this.store.set("user", data.data);
        this.toolsService.setSessionId(data.data.id);
        localStorage.setItem("user", data.data.id);
        this.cookieService.setUser(data.data);
        return data;
      })
    );
  }

  update(user) {
    return this.http.put(this.env.apiPath + "users/" + user.id, user).pipe(
      map(async (data: any) => {
        this.store.set("user", user);
        localStorage.setItem("user", user.id);
        await this.cookieService.setUser(user);
        return data;
      })
    );
  }

  updateContact(user) {
    return this.http
      .put(this.env.apiPath + "users/contact/" + user.id, user)
      .pipe(map((data: any) => data));
  }

  findAllByCompany(params) {
    const dataToSend = {
      params,
    };
    return this.http
      .post(this.env.apiPath + "users/search/users", dataToSend)
      .pipe(map((data: any) => data));
  }

  findOne(id) {
    return this.http
      .get(this.env.apiPath + "users/" + id)
      .pipe(map((data: any) => data));
  }

  delete(id) {
    return this.http
      .delete(this.env.apiPath + "users/" + id)
      .pipe(map((data: any) => data));
  }

  search() {
    const companyUsers = this.store.selectForLocal("companyUsers");
    const user = this.store.selectForLocal("user");
    companyUsers.params.accountType = user.accountType;
    companyUsers.params.companyId = user.company.id;
    const dataToSend = {
      params: companyUsers.params,
    };
    return this.http.post(this.env.apiPath + "users/search", dataToSend).pipe(
      map((data: any) => {
        companyUsers.data = data.data.data;

        companyUsers.params.pages = Math.ceil(
          data.data.totalRecords / companyUsers.params.limit
        );
        companyUsers.params.totalRecords = data.data.totalRecords;
        companyUsers.params.pageArray = [];
        for (let i = 0; i < companyUsers.params.pages; i++) {
          companyUsers.params.pageArray.push(i);
        }
        this.store.set("companyUsers", companyUsers);

        return data;
      })
    );
  }

  searchCustomers(params) {
    const dataToSend = {
      params,
    };
    return this.http
      .post(this.env.apiPath + "users/search/customers", dataToSend)
      .pipe(map((data: any) => data));
  }

  findAddressesByCustomer(userId) {
    return this.http.get(this.env.apiPath + "users/addresses/" + userId).pipe(
      map((data: any) => {
        this.store.set("deliveryAddresses", data.data);
        return data;
      })
    );
  }

  addressDelete(addressId) {
    return this.http
      .delete(this.env.apiPath + "users/address/" + addressId)
      .pipe(map((data: any) => data));
  }

  searchCompanies(params) {
    const dataToSend = {
      params,
    };
    return this.http
      .post(this.env.apiPath + "users/search/companies", dataToSend)
      .pipe(map((data: any) => data));
  }

  areasCovered(params) {
    const dataToSend = {
      params,
    };
    return this.http
      .post(
        this.env.apiPath + "users/search/companies/areascovered",
        dataToSend
      )
      .pipe(map((data: any) => data));
  }

  getCompanyAreasCovered(companyId) {
    return this.http
      .get(
        this.env.apiPath + "users/search/companies/areascovered/" + companyId
      )
      .pipe(map((data: any) => data));
  }

  searchSupplierCollectionCosts(params) {
    const dataToSend = {
      params,
    };
    return this.http
      .post(this.env.apiPath + "courier/supplier/collection/", dataToSend)
      .pipe(map((data: any) => data));
  }

  updateSupplierCollectionCosts(supplier, courierId) {
    const dataToSend = {
      courierId,
      supplier,
    };

    return this.http
      .put(this.env.apiPath + "courier/supplier/collection", dataToSend)
      .pipe(map((data: any) => data));
  }

  userOnline(userId) {
    // TODO: need to fill store with onlineUsers
    const onlineUsers = this.store.selectForLocal("onlineUsers");
    if (!onlineUsers) return false;
    if (onlineUsers.indexOf(userId) < 0) {
      return false;
    }
    return true;
  }

  all() {
    return this.http.get(environment.apiPath + "users").pipe(
      map((data: any) => {
        this.store.set("allUsers", data.data);
        return data;
      })
    );
  }
}
