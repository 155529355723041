import { IChatMember } from '../models';

export class ChatMemberMapper {
    public static toDomain(dto: any): IChatMember {
        return {
            ...dto,
            id: dto.id ? dto.id : dto.userId,
            isPremium: !!dto.isPremium,
            companyName: dto.companyName || dto.prospectCompanyName,
            firstname: dto.firstname || 'undefined',
            surname: dto.surname || 'undefined'
        };
    }
}
