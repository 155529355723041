import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Store } from '../store';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PostcodeService {
    env = environment;

    constructor(
        private apiService: ApiService,
        private http: HttpClient,
        private store: Store,
    ) {
    }

    search(params) {
        const dataToSend = {
            params,
        };
        return this.http
            .post(
                this.env.apiPath + 'postcode/search',
                dataToSend
            )
            .pipe(map((data: any) => data));
    }


    addPostcode(params) {
        const dataToSend = {
            params,
        };
        return this.http
            .post(
                this.env.apiPath + 'postcode/company/add',
                dataToSend
            )
            .pipe(map((data: any) => data));
    }

    addPostcodeByRegion(params) {
        const dataToSend = {
            params,
        };
        return this.http
            .post(
                this.env.apiPath + 'postcode/company/add/region',
                dataToSend
            )
            .pipe(map((data: any) => data));
    }

    addPostcodeByArray(params) {
        const dataToSend = {
            params,
        };
        return this.http
            .post(
                this.env.apiPath + 'postcode/company/add/array',
                dataToSend
            )
            .pipe(map((data: any) => data));
    }

    removePostcode(params) {
        const dataToSend = {
            params,
        };
        return this.http
            .post(
                this.env.apiPath + 'postcode/company/remove',
                dataToSend
            )
            .pipe(map((data: any) => data));
    }
}
