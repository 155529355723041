import { Component, OnInit } from '@angular/core';
import { EChatGroupType } from '../../enums/chat-group-type.enum';
import { IChatListItem } from '../../models';
import { ChatGroupLogicService } from '../../services/chat-group-logic.service';

@Component({
  selector: 'app-chat-archive',
  templateUrl: './chat-archive.component.html',
  styleUrls: ['./chat-archive.component.scss']
})
export class ChatArchiveComponent implements OnInit {

  get chatsOnPage(): IChatListItem[] {
    return this.chatGroupLogicService.archiveChats.slice(
      this.pageParams.page * this.pageParams.limit, 
      this.pageParams.page * this.pageParams.limit + this.pageParams.limit);
  }

  get groupTitle(): string {
    return this.chatGroupLogicService.chatType === EChatGroupType.ADMIN
      ? "Admin" 
      : "Customer";
  }

  get chatsCnt(): number {
    return this.chatGroupLogicService.archiveChats.length;
  }

  get pageParams() {
    const total = this.chatGroupLogicService.archiveChats.length;
    this._pageParams.pages = Math.ceil(total / this._pageParams.limit);
    this._pageParams.totalRecords = total;
    this._pageParams.pageArray = [];
    for (let i = 0; i < this._pageParams.pages; i++) {
      this._pageParams.pageArray.push(i);
    }
    return this._pageParams;
  }

  private _pageParams = {
    limit: 10,
    limits: [10],
    page: 0,
    pages: 0,
    pageArray: [],
    totalRecords: 0,
  };

  constructor(
    public chatGroupLogicService: ChatGroupLogicService) { }

  ngOnInit(): void {
  }
  
  paged($event) {
  }
}
