import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationComponent } from './pagination/pagination.component';
import { GrowlerComponent } from './growler/growler.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { AlertComponent } from './alert/alert.component';
import { RatingIconsComponent } from './rating-icons/rating-icons.component';
import { ProcessingComponent } from './processing/processing.component';
import { NotificationsListComponent } from '../notifications/notifications-list/notifications-list.component';
import { ImageSliderKeenComponent } from './image-slider-keen/image-slider-keen.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { RouterModule } from '@angular/router';
import { TileSliderKeenComponent } from './tile-slider-keen/tile-slider-keen.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MatDialogRef } from '@angular/material/dialog';
import { DropdownDirective } from '../directives/dropdown/dropdown.directive';
import { DirectivesModule } from '../directives/directives.module';
import { PipesModule } from '../pipes/pipes.module';
import { UserAvatarComponent } from './user-avatar/user-avatar.component';
import { AvatarComponent } from './avatar/avatar.component';
import { SimpleImageViwerComponent } from './simple-image-viwer/simple-image-viwer.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { InlineSpinnerComponent } from './inline-spinner/inline-spinner.component';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
	declarations: [
		PaginationComponent,
		GrowlerComponent,
		ConfirmationComponent,
		AlertComponent,
		RatingIconsComponent,
		ProcessingComponent,
		NotificationsListComponent,
		ImageSliderKeenComponent,
		BreadcrumbComponent,
		TileSliderKeenComponent,
		FileUploaderComponent,
		UserAvatarComponent,
		AvatarComponent,
		SimpleImageViwerComponent,
		InlineSpinnerComponent,
	],
	imports: [
		CommonModule,
		EditorModule,
		RouterModule,
		AngularSvgIconModule,
		DirectivesModule,
		PipesModule,
		CKEditorModule,
		MatMenuModule,
	],
	exports: [
		PaginationComponent,
		GrowlerComponent,
		ConfirmationComponent,
		AlertComponent,
		RatingIconsComponent,
		ProcessingComponent,
		NotificationsListComponent,
		ImageSliderKeenComponent,
		EditorModule,
		BreadcrumbComponent,
		TileSliderKeenComponent,
		FileUploaderComponent,
		UserAvatarComponent,
		AvatarComponent,
		SimpleImageViwerComponent,
		CKEditorModule,
		InlineSpinnerComponent,
	],
	providers: [
		{
			provide: MatDialogRef,
			useValue: MatDialogRef,
		},
	],
})
export class SharedModule {}
