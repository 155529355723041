<div class="row">
    <div class="col-12">
        <div class="preview-box">
            <div class="preview-box-title">
                Orders
            </div>
            <div class="preview-box-content">
                <div class="data-container" *ngIf="user">

                    <app-order-detail-search
                            class="mt-4"
                            [params]="orderDetailParams"
                            [showSupplier]="showSupplier"
                            [showCustomer]="showCustomer"></app-order-detail-search>

                    <!--                            <app-order-search-->
                    <!--                                class="mt-4"-->
                    <!--                                [params]="orderParams"-->
                    <!--                                [showSupplier]="showSupplier"-->
                    <!--                                [showCustomer]="showCustomer"></app-order-search>-->
                </div>
            </div>
        </div>

    </div>
</div>

