export enum UserAccountType {
    Customer = 'customer',
    Trade = 'trade',
    Supplier = 'supplier',
    Courier = 'courier',
    Admin = 'admin',
    Guest = 'guest',
}

export enum ActionStatus {
    New = 'new',
    Done = 'done',
}

export type UserData = {
    id: string | null;
    username: string;
    password: string;
    extendedExpiration: boolean;
    accountType: UserAccountType;
    terms: boolean;
    marketing: boolean;
    autoConfirm: boolean;
    contact: {
        company: string;
        address: string;
        firstname: string;
        surname: string;
        email: string;
        mobile: string;
        mobDialCode?: string;
        landline: string | null;
        avatar: string | null;
        jobTitle: string | null;
        fullAddress: string; // one line address, used as a note
        companyName: string; // one line company, used as a note
        country?: string;
        town?: string;
        postcode?: string;
        isWarm?: 0 | 1;
        id?: string;
        addressId?: string;
    };
    address: AddressData | null;
    company: CompanyData | null;
    prospect: 0 | 1;
    favorite: 0 | 1;
    avatarColour: string;
    isPremium?: 0 | 1;
    companyName: string;
    prospectCompanyName: string;
};

export const DefaultUserData = {
    id: null,
    username: '',
    password: '',
    extendedExpiration: false,
    accountType: UserAccountType.Customer,
    terms: false,
    marketing: false,
    autoConfirm: false,
    contact: {
        company: '',
        address: '',
        firstname: '',
        surname: '',
        email: '',
        mobile: '',
        landline: '',
        avatar: '',
        jobTitle: '',
        fullAddress: '',
        companyName: '',
    },
    address: null,
    company: null,
    prospect: 0,
    favorite: 0,
} as UserData;

export type CompanyData = {
    id: string | null;
    userId: string | null;
    addressId: string | null;
    primaryUserId: string | null;
    accountType: UserAccountType;
    name: string;
    url: string | null;
    companyNumber: string | null;
    vatNumber: string | null;
    email: string | null;
    mobile: string | null;
    mobDialCode: string | null;
    landline: string | null;
};

export const DefaultCompanyData = {
    id: null,
    userId: null,
    addressId: null,
    primaryUserId: null,
    accountType: UserAccountType.Trade,
    name: '',
    url: null,
    companyNumber: null,
    vatNumber: null,
    email: null,
    mobile: null,
    landline: null,
} as CompanyData;

export enum AddressType {
    Billing = 'billing',
    Delivery = 'delivery',
    Invoice = 'invoice',
}

export type AddressData = {
    id: string | null;
    userId: string;
    add1: string | null;
    add2: string | null;
    add3: string | null;
    town: string | null;
    county: string | null;
    country: string;
    postcode: string | null;
    isBilling: boolean;
    defaultDeliveryAddress: boolean;
};

export const DefaultAddressData = {
    id: null,
    userId: '',
    add1: null,
    add2: null,
    add3: null,
    town: null,
    county: null,
    country: 'GB',
    postcode: null,
    isBilling: true,
    defaultDeliveryAddress: true,
} as AddressData;

export interface IUser {
    id: string;
    firstname: string;
    surname: string;
    avatarColour: string;
    accountType: UserAccountType;
    isPremium?: boolean;
    companyName?: string;
}
