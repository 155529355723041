<div class="dz-container" appFileDnd (fileDropped)="onFileDropped($event)">
    <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)"/>
    <svg-icon src="assets/images/icon-upload.svg" [svgClass]="'dz-message__icon'" class="d-none d-lg-block"></svg-icon>
    <svg-icon src="assets/images/icon-circle-plus.svg" [svgClass]="'dz-message__icon'" class="d-block d-lg-none"></svg-icon>
    <div class="dz-message fs-5 fw-bold ms-3 my-3">
        <span class="text-primary">Drag and drop</span><span class="text-muted"> or browse files</span>
    </div>
    <p class="dz-drop-message fs-5 fw-bold text-primary my-3">Drop file to start upload</p>
</div>
<div @list *ngIf="files.length > 0" class="files-list">
    <div @listItem class="d-flex justify-content-between align-items-center w-100 pt-3"
         *ngFor="let file of files; let i = index">
        <div class="custom-progress w-100">
            <div *ngIf="!file?.loaded" class="back p-2 px-3">
                <div class="me-3">
                    <i class="fas fa-file-alt"></i>
                </div>
                <div class="d-flex flex-column w-100">
                    <div class="small">{{ file?.name }}</div>
                    <div class="small">
                        {{ formatBytes(file?.size, 2) }}
                    </div>
                </div>
            </div>
            <div *ngIf="!file?.loaded" class="front p-2 px-3"
                 [style]="{
                     'clip-path': 'inset(0 0 0 ' + file?.progress + '%)',
                     '-webkit-clip-path': 'inset(0 0 0 ' + file?.progress + '%)'
                     }">
                <div class="me-3">
                    <i class="fas fa-file-alt"></i>
                </div>
                <div class="d-flex flex-column w-100">
                    <div class="small">{{ file?.name }}</div>
                    <div class="small">
                        {{ formatBytes(file?.size, 2) }}
                    </div>
                </div>
            </div>
            <div *ngIf="file?.loaded || file?.error" class="front p-2 px-3">
                <div class="me-3">
                    <i class="fas fa-file-alt fs-5"></i>
                </div>
                <div class="d-flex flex-column w-100">
                    <div class="small" [ngClass]="{'text-danger' : file?.error}">{{ file?.name }}</div>
                    <div class="small">
                        {{ formatBytes(file?.size, 2) }}
                    </div>
                </div>
            </div>
            <div *ngIf="file?.progress === 100 || file?.error" @bounceIn class="progress-actions">
                <div class="ms-4">
                    <i class="fas fa-check text-white"></i>
                </div>
            </div>
            <div *ngIf="file?.loaded" @bounceIn class="progress-actions">
                <div class="ms-4 hover" (click)="deleteFile(i)">
                    <i class="far fa-times-circle"></i>
                </div>
            </div>
            <div *ngIf="file?.error" @bounceIn class="progress-actions">
                <div class="ms-4 hover" (click)="deleteFile(i)">
                    <i class="far fa-times-circle"></i>
                </div>
            </div>
        </div>
    </div>
</div>
