import { Injectable } from '@angular/core';
import { UserAccountType } from 'src/app/data-types/user-data';
import { Store } from 'src/app/store';
import { EChatType, EChatGroupType, EChatSubgroup } from '../enums/';
import { IChatListItem, IChatMember, IChatSubgroup, IChat } from '../models';

@Injectable({
	providedIn: 'root',
})
export class ChatGroupLogicService {
	readonly ADMIN_GROUPS = [
		//EChatSubgroup.Orders,
		//EChatSubgroup.Actions,
		EChatSubgroup.Managers,
		//EChatSubgroup.Suppliers,
		//EChatSubgroup.Couriers,
		EChatSubgroup.Channels,
		EChatSubgroup.CustomerService,
	];

	readonly CUSTOMER_GROUPS = [
		EChatSubgroup.Unknown,
		EChatSubgroup.Private,
		EChatSubgroup.Trade,
		EChatSubgroup.Premium,
	];

	readonly ACCOUNT_TYPE_SUBGROUP_MAP = {
		[UserAccountType.Admin]: EChatSubgroup.Managers,
		[UserAccountType.Supplier]: EChatSubgroup.Suppliers,
		[UserAccountType.Trade]: EChatSubgroup.Trade,
		[UserAccountType.Courier]: EChatSubgroup.Couriers,
		[UserAccountType.Customer]: EChatSubgroup.Private,
		[UserAccountType.Guest]: EChatSubgroup.Unknown,
	};

	private _chats: IChatListItem[];

	private _initSubgropus: IChatSubgroup[] = [
		{ type: EChatSubgroup.Orders, title: 'Orders', chatItems: [] },
		{ type: EChatSubgroup.Actions, title: 'Actions', chatItems: [] },
		{ type: EChatSubgroup.Managers, title: 'Managers', chatItems: [] },
		{ type: EChatSubgroup.Suppliers, title: 'Suppliers', chatItems: [] },
		{ type: EChatSubgroup.Couriers, title: 'Couriers', chatItems: [] },
		{ type: EChatSubgroup.Channels, title: 'Channels', chatItems: [] },
		{ type: EChatSubgroup.Unknown, title: 'Unknown users', chatItems: [] },
		{ type: EChatSubgroup.Private, title: 'Private', chatItems: [] },
		{ type: EChatSubgroup.Trade, title: 'Trade', chatItems: [] },
		{ type: EChatSubgroup.Premium, title: 'Premium Trade', chatItems: [] },
		{
			type: EChatSubgroup.CustomerService,
			title: 'Customer Service',
			chatItems: [],
		},
	];
	private _activeSubgropus: IChatSubgroup[] = JSON.parse(JSON.stringify(this._initSubgropus));
	private _archiveSubgropus: IChatSubgroup[] = JSON.parse(JSON.stringify(this._activeSubgropus));

	private _selectedChatGroup: EChatGroupType = EChatGroupType.ADMIN;

	constructor(private store: Store) {}

	set chats(chats: IChatListItem[]) {
		this.cleanGroups();
		this.handleChats(chats);
	}

	set chatType(chatGroup: EChatGroupType) {
		this._selectedChatGroup = chatGroup;
	}

	get chatType(): EChatGroupType {
		return this._selectedChatGroup;
	}

	/**
	 * Return array of all Chats
	 */
	get chats(): IChatListItem[] {
		return this._chats;
	}

	/**
	 * Returns Subgroups of Active Chats (isClosed = 0)
	 * depend on Selected Chat Group (Admin/Customer)
	 */
	get activeSubgroups() {
		return this.filterSubgroupsBy(this._activeSubgropus);
	}

	/**
	 *  Returns array of Archive Chats (isClosed = 1)
	 *  depend on Selected Chat Group (Admin/Customer)
	 */
	get archiveChats(): IChatListItem[] {
		const archiveChats: IChatListItem[] = [];
		this.filterSubgroupsBy(this._archiveSubgropus).forEach((g) => {
			archiveChats.push(...g.chatItems);
		});
		return archiveChats;
	}

	public getArhivedChatsBy(group: EChatGroupType): IChatListItem[] {
		const archiveChats: IChatListItem[] = [];
		this.filterSubgroupsBy(this._archiveSubgropus, group).forEach((g) => {
			archiveChats.push(...g.chatItems);
		});
		return archiveChats;
	}

	public defineGroupOf(chat: IChat | IChatListItem): EChatGroupType {
		const subgroup: EChatSubgroup = this.findSubgroupFor(chat);
		if (this.ADMIN_GROUPS.includes(subgroup)) {
			return EChatGroupType.ADMIN;
		} else {
			return EChatGroupType.CUSTOMER;
		}
	}

	public addCurrentUserToMemebers(members: IChatMember[]) {
		const user = this.store.selectForLocal('user');
		if (!members.map((m) => m.id).includes(user.id)) {
			members.push({
				id: user.id,
				firstname: user.contact.firstname,
				surname: user.contact.surname,
				avatarColour: user.avatarColour,
				accountType: user.accountType,
			});
		}
		return members;
	}

	public chatExists(members: IChatMember[]) {
		const user = this.store.selectForLocal('user');
		let membersIds = members.map((m) => m.id);

		if (membersIds.includes(user.id)) {
			membersIds.push(user.id);
		}

		if (!membersIds.includes(user.id)) {
			membersIds = [...membersIds, user.id];
		}
		const selectedUserIdsHash = membersIds.sort().join();
		const existsChat = this.chats
			.filter((c) => ![EChatType.Order, EChatType.Channel].includes(c.type))
			.find((c) => {
				return (
					c.members
						.map((m) => m.id)
						.sort()
						.join() === selectedUserIdsHash
				);
			});
		return existsChat ? existsChat.id : null;
	}

	//  TODO need more common solution
	public moveChatToArchive(chatId: number) {
		this.activeSubgroups.forEach((sg) => {
			const chatItemForArchive = sg.chatItems.find((c) => c.id === chatId);
			if (chatItemForArchive) {
				chatItemForArchive.isClosed = 1;
				sg.chatItems = sg.chatItems.filter((c) => c !== chatItemForArchive);
				this.addChatToGroup(chatItemForArchive, this.findSubgroupFor(chatItemForArchive));
			}
		});
	}

	public addChat(chat: IChatListItem) {
		chat.createdAt = new Date(chat.createdAt);
		this._chats.push(chat);
		this.handleChats(this._chats);
	}

	public removeChat(chatId) {
		this._chats = this._chats.filter((c) => c.id !== chatId);
	}

	public markChatAsRead(chatId: number) {
		this.chats.find((c) => c.id === chatId).unread = 0;
		this.updateChatState();
	}

	private handleChats(chats: IChatListItem[]) {
		// removed emtpy chats
		this._chats = chats.filter((c) => c.members.length);
		this._chats.forEach((chat) => {
			const groupType = this.findSubgroupFor(chat);
			this.addChatToGroup(chat, groupType);
		});
		this.updateChatState();
	}

	public findSubgroupFor(chat: IChatListItem | IChat): EChatSubgroup {
		const user = this.store.selectForLocal('user');
		if (chat.type === EChatType.CustomerService) {
			return EChatSubgroup.CustomerService;
		} else if (chat.type === EChatType.Channel || chat.members.filter((u) => u.id !== user.id).length > 1) {
			// For backward compatibility with old Customer Service channels
			if (chat.name === 'Customer Services') {
				return EChatSubgroup.CustomerService;
			}
			return EChatSubgroup.Channels;
		} else if (chat.type === EChatType.Order) {
			return EChatSubgroup.Orders;
		} else if (chat.type === EChatType.Action) {
			return EChatSubgroup.Actions;
		} else {
			return this.determineSubgroupFor(chat.members);
		}
	}

	updateChatState() {
		const chatStore = this.store.selectForLocal('chatStore');
		const unread = this.calcAllUnreadMessages();
		chatStore.unread = unread;
		this.store.set('chatStore', chatStore);
	}

	/**
	 * Determine subgroup of chat by user that
	 *  - has to be NOT Admin member for chats that contain type of members other than admin
	 * or
	 * - can be Admin for chat that have only admin members
	 * For example:
	 * [admin, admin] -> admin -> EChatSubgroup.Managers
	 * [admin, guest, amdin] -> guest -> EChatSubgroup.Unknown
	 * [admin, customer] -> customer -> EChatSubgroup.Private
	 * [admin, trade] -> trade -> EChatSubgroup.Trade
	 * [admin, trade(isPremium === 1)] -> trade(isPremium === 1) -> EChatSubgroup.Premium
	 */
	private determineSubgroupFor(members: IChatMember[]): EChatSubgroup {
		const user = this.store.selectForLocal('user');
		const notAdminUser = members
			.filter((u) => u.id !== user.id)
			.filter((u) => u.accountType != UserAccountType.Admin)[0];

		const accountType = notAdminUser ? notAdminUser.accountType : UserAccountType.Admin;
		if (accountType === UserAccountType.Trade && notAdminUser.isPremium) {
			return EChatSubgroup.Premium;
		}
		return this.ACCOUNT_TYPE_SUBGROUP_MAP[accountType];
	}

	/**
	 * Filter passed Chat Subgroups depend on selected (or passed)
	 * Chat Group Type (Admin/Customer)
	 */
	private filterSubgroupsBy(subgroups: IChatSubgroup[], group: EChatGroupType = null): IChatSubgroup[] {
		group = group ? group : this._selectedChatGroup;
		if (group === EChatGroupType.ADMIN) {
			return subgroups.filter((g) => this.ADMIN_GROUPS.includes(g.type));
		} else {
			return subgroups.filter((g) => this.CUSTOMER_GROUPS.includes(g.type));
		}
	}

	private addChatToGroup(chat: IChatListItem, groupType: EChatSubgroup) {
		let groups = chat.isClosed ? this._archiveSubgropus : this._activeSubgropus;
		const group = groups.find((g) => g.type === groupType);
		if (group) {
			if (group.chatItems.find((c) => c.id === chat.id)) return;
			group.chatItems.push(chat);
			group.chatItems.sort(function (a, b) {
				return b.createdAt.getTime() - a.createdAt.getTime();
			});
		} else {
			console.warn('Cannot find a proper group for chat of type:' + groupType);
		}
	}

	private cleanGroups() {
		this._activeSubgropus = JSON.parse(JSON.stringify(this._initSubgropus));
		this._archiveSubgropus = JSON.parse(JSON.stringify(this._activeSubgropus));
	}

	private calcAllUnreadMessages() {
		return this._chats.reduce((acc, cur) => {
			return cur.unread ? acc + cur.unread : acc;
		}, 0);
	}
}
