<div class="row">
    <div class="col-12">
        <div class="preview-box">
            <div class="preview-box-title">
                Payment History
            </div>
            <div class="preview-box-content">
                <div class="data-container">
                    <app-payment-search class="mt-4" [params]="paymentParams"></app-payment-search>
                </div>
            </div>
        </div>

    </div>
</div>

