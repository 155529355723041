<div class="preview-box">
    <div class="preview-box-title">
        Products
    </div>
    <div class="preview-box-content">


            <div class="table-responsive">
            <table class="table table-bordered table-hover align-middle small">
                <thead class="thead-white">

                <tr class="border-top-0">
                    <th class="ff-spectral fs-5 fw-normal pb-3">Code</th>
                    <th class="ff-spectral fs-5 fw-normal pb-3">Product</th>
                    <th class="ff-spectral fs-5 fw-normal  pb-3">Type</th>
                    <th class="ff-spectral fs-5 fw-normal  pb-3"> Range</th>
                    <th class="ff-spectral fs-5 fw-normal  pb-3" *ngIf="showSupplier">Supplier</th>
                    <th class="ff-spectral fs-5 fw-normal  pb-3">Actions</th>
                </tr>
<!--                <tr>-->
<!--                    <th><input type="text" class="form-control" [(ngModel)]="params.productCode" (ngModelChange)="filterSearch()" /></th>-->
<!--                    <th><input type="text" class="form-control" [(ngModel)]="params.name" (ngModelChange)="filterSearch()" /></th>-->
<!--                    <th><input type="text" class="form-control" [(ngModel)]="params.group" (ngModelChange)="filterSearch()" /></th>-->
<!--                    <th><input type="text" class="form-control" [(ngModel)]="params.range" (ngModelChange)="filterSearch()" /></th>-->
<!--                    <th *ngIf="showSupplier"><input type="text" class="form-control" [(ngModel)]="params.supplier" (ngModelChange)="filterSearch()" /></th>-->
<!--                    <th><input type="text" class="form-control" [(ngModel)]="params.margin" (ngModelChange)="filterSearch()" /></th>-->
<!--                    <th></th>-->
<!--                </tr>-->
                </thead>
                <tbody>
                <tr *ngFor="let s of products">
                    <td>{{s.productCode}}</td>
                    <td>{{s.name}}</td>
                    <td>
                        <span *ngFor="let pg of s.productGroups; let first = first"><span *ngIf="!first">, </span>{{pg.name}}</span>
                    </td>
                    <td>
                        <span *ngIf = "s.rangeName"> {{s.rangeName}}</span>
                    </td>
                    <td *ngIf="showSupplier">{{s.company.name}}</td>
                    <td><a [routerLink]="['/account', 'products', 'edit', s.slug]">
                        <i class="fa fa-edit"></i>
                    </a> </td>
                </tr>
                </tbody>
            </table>
        </div>

        <div class="no-results" *ngIf="!products.length">
            <p>No products found</p>
        </div>

       <!---- <app-pagination *ngIf="params.pageArray.length" [params]="params" (update)="search()"></app-pagination>--->
        <app-pagination *ngIf="showPagination && params.pageArray.length" [params]="params" (update)="search()"></app-pagination>

    </div>
</div>
