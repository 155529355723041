<ng-container *ngIf="user">
    <div class="row">
        <div class="col-lg-6">

            <div class="preview-box">
                <div class="preview-box-title">
                    Latest Notifications
                </div>
                <div class="preview-box-content">
                </div>
            </div>

        </div>
        <div class="col-lg-6">
            <div class="preview-box">
                <div class="preview-box-title">
                    Account Details
                </div>
                <div class="preview-box-content">
                    <div class="row">
                        <div class="col-4">
                            <p class="sub-title">Company name</p>
                            <p>{{user.company.name}}</p>
                        </div>
                        <div class="col-4">
                            <p class="sub-title">Supplier Account Details</p>
                            <p></p>

                        </div>
                        <div class="col-4">
                            <p class="sub-title">VAT Number</p>
                            <p>{{user.company.vatNumber}}</p>
                        </div>
                    </div>
                    <hr />
                    <div class="row">
                        <div class="col-8">
                            <h3>{{user.contact.firstname}} {{user.contact.surname}}</h3>
                            <p>{{user.contact.jobTitle}}</p>
                        </div>
                        <div class="col-4">
                            <button (click)="editUser(user)" class="btn btn-round btn-sm btn-secondary-reverse">
                                Edit Profile
                            </button>
                        </div>
                    </div>
                    <hr />
                    <div class="text-center">
                        <h3>Users linked to account: {{user.company.userCount}}</h3>
                    </div>
                    <hr />
                    <button (click)="addUser()" class="btn btn-round btn-sm btn-secondary-reverse">
                        Add new user
                    </button>

                </div>
            </div>


        </div>
    </div>


    <div class="row">
        <div class="col-12">
            <div class="preview-box">
                <div class="preview-box-title">
                    Recent Orders
                </div>
                <div class="preview-box-content">

                </div>
            </div>

        </div>
    </div>



</ng-container>
