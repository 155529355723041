import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Store } from '../store';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CourierService {
    env = environment;

    constructor(
        private apiService: ApiService,
        private http: HttpClient,
        private store: Store,
    ) {
    }

    calculateDeliveryCosts(basket) {
        const dataToSend = {
            basket,
        };

        return this.http.post(this.env.apiPath + 'courier/delivery/costs', dataToSend)
            .pipe(map((data: any) => data));
    }


    get(postcode) {
        return this.http
            .get(
                this.env.apiPath + 'courier/postcode/' + postcode
            )
            .pipe(map((data: any) => data));
    }

    delete(postcode) {
        return this.http
            .delete(
                this.env.apiPath + 'courier/postcode/' + postcode.postcode
            )
            .pipe(map((data: any) => data));
    }

    update(postcode) {
        return this.http
            .put(
                this.env.apiPath + 'courier/postcode/' + postcode.postcode,
                postcode
            )
            .pipe(map((data: any) => data));
    }

    create(postcode) {
        return this.http
            .post(
                this.env.apiPath + 'courier/postcode',
                postcode
            )
            .pipe(map((data: any) => data));
    }

    search(params) {
        const dataToSend = {
            params,
        };
        return this.http
            .post(
                this.env.apiPath + 'courier/postcode/search',
                dataToSend
            )
            .pipe(map((data: any) => data));
    }

    updateZone(zone) {
        return this.http
            .put(
                this.env.apiPath + 'courier/postcode/zones/' + zone.id,
                zone
            )
            .pipe(map((data: any) => data));
    }

    createZone(zone) {
        return this.http
            .post(
                this.env.apiPath + 'courier/postcode/zones',
                zone
            )
            .pipe(map((data: any) => data));
    }


    searchZones(params) {
        const dataToSend = {
            params,
        };
        return this.http
            .post(
                this.env.apiPath + 'courier/postcode/zones/search',
                dataToSend
            )
            .pipe(map((data: any) => data));
    }

    getZone(zone) {
        return this.http
            .get(
                this.env.apiPath + 'courier/postcode/zones/' + zone
            )
            .pipe(map((data: any) => data));
    }

    deleteZone(zone) {
        return this.http
            .delete(
                this.env.apiPath + 'courier/postcode/zones/' + zone.id
            )
            .pipe(map((data: any) => data));
    }


    searchZonePostcodes(params) {
        const dataToSend = {
            params,
        };
        return this.http
            .post(
                this.env.apiPath + 'courier/postcode/zone/postcode/search',
                dataToSend
            )
            .pipe(map((data: any) => data));
    }

    addPostcodeToZone(params) {
        const dataToSend = {
            params,
        };
        return this.http
            .post(
                this.env.apiPath + 'courier/postcode/zone/postcode/add',
                dataToSend
            )
            .pipe(map((data: any) => data));
    }

    addPostcodeToZoneByRegion(params) {
        const dataToSend = {
            params,
        };
        return this.http
            .post(
                this.env.apiPath + 'courier/postcode/zone/postcode/add/region',
                dataToSend
            )
            .pipe(map((data: any) => data));
    }

    addPostcodeToZoneByArray(params) {
        const dataToSend = {
            params,
        };
        return this.http
            .post(
                this.env.apiPath + 'courier/postcode/zone/postcode/add/array',
                dataToSend
            )
            .pipe(map((data: any) => data));

    }

    removePostcodeFromZone(params) {
        const dataToSend = {
            params,
        };
        return this.http
            .post(
                this.env.apiPath + 'courier/postcode/zone/postcode/remove',
                dataToSend
            )
            .pipe(map((data: any) => data));
    }


    updateVehicle(vehicle) {
        return this.http
            .put(
                this.env.apiPath + 'courier/vehicles/' + vehicle.id,
                vehicle
            )
            .pipe(map((data: any) => data));
    }

    createVehicle(vehicle) {
        return this.http
            .post(
                this.env.apiPath + 'courier/vehicles',
                vehicle
            )
            .pipe(map((data: any) => data));
    }


    searchVehicles(params) {
        const dataToSend = {
            params,
        };
        return this.http
            .post(
                this.env.apiPath + 'courier/vehicles/search',
                dataToSend
            )
            .pipe(map((data: any) => data));
    }

    getVehicle(vehicleId) {
        return this.http
            .get(
                this.env.apiPath + 'courier/vehicle/' + vehicleId
            )
            .pipe(map((data: any) => data));
    }

    getVehicleTypes() {
        return this.http
            .get(
                this.env.apiPath + 'courier/vehicletypes'
            )
            .pipe(map((data: any) => data));
    }

    getPricingTypes() {
        return this.http
            .get(
                this.env.apiPath + 'courier/pricingtypes'
            )
            .pipe(map((data: any) => data));
    }

    searchSupplierCouriers(params) {
        const dataToSend = {
            params,
        };
        return this.http
            .post(
                this.env.apiPath + 'courier/supplier/linked',
                dataToSend
            )
            .pipe(map((data: any) => data));
    }
}
