import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChatComponent } from './chat.component';
import { UserGuard } from '../guards/user-guard';

const routes: Routes = [
    {
        path: '',
        component: ChatComponent,
        canActivate: [UserGuard],
        data: {
            menuGroup: ['chat'],
            staticRoute: '/chat',
            colouredBackground: true,
            offset: true,
            showMenu: true,
            showHeader: true,
            showFooter: true,
            disableAuthTimeout: false,
        },
    },
    {
        path: '',
        component: ChatComponent,
        canActivate: [UserGuard],
        data: {
            menuGroup: ['chat'],
            staticRoute: '/chat/:chatId/:messageId',
        },
    },
    {
        path: '',
        component: ChatComponent,
        canActivate: [UserGuard],
        data: {
            menuGroup: ['chat'],
            staticRoute: '/chat/:chatId',
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ChatRoutingModule {}
