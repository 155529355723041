import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '../../store';
import { OrdersService } from '../../services/orders.service';

@Component({
    selector: 'app-order-board-card',
    templateUrl: './order-board-card.component.html',
    styleUrls: ['./order-board-card.component.scss'],
})
export class OrderBoardCardComponent implements OnInit {
    @Input() col;
    @Input() card;
    @Input() colIndex;
    @Input() boardView;
    @Input() params;

    @Output() updateOrder = new EventEmitter();

    updateRequested = false;
    orderDetail;

    constructor(
        private store: Store,
        private ordersService: OrdersService
    ) {
    }

    ngOnInit(): void {
    }

    open(card) {
        if (!card.open) {
            card.open = true;
        } else {
            card.open = false;
        }
        this.closeAllPopups(card.id);
    }

    closeAllPopups(toIgnore) {
        this.ordersService.orderDetailBoard.value.cols.forEach(c => {
            c.orderLines.forEach(ol => {
                if (ol.id !== toIgnore) {
                    ol.open = false;
                }
            });
        });
    }

    requestUpdate(orderDetail) {
        this.orderDetail = orderDetail;
        this.updateRequested = true;
    }

    randomizeClass(idNumber): string
    {
        const rand = Math.floor(idNumber % 3);
        return rand === 0 ? 'low' : (rand === 1 ? 'medium' : 'high');
    }
}
