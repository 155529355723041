<div
    *ngIf="imgPath"
    class="image-container"
    (click)="close()">
    <div class="buttons-holder">
        <i
            class="fas fa-download mr-2"
            (click)="downloadImage(imgPath)"></i>
        <svg-icon
            [svgStyle]="{ 'width.px': 22, 'height.px': 22, fill: '#fff', cursor: 'pointer' }"
            src="assets/images/icon-menu-close.svg"></svg-icon>
    </div>
    <img src="{{ this.imgPath }}" />
</div>
