import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
    IChatReminderDlgResult,
    MessageReminderDlgComponent,
} from '../dialogs/message-reminder-dlg/message-reminder-dlg.component';
import { UserSelectorDlgComponent } from '../dialogs/user-selector-dlg/user-selector-dlg.component';
import {
    EUserSelectorDlgResultStatus,
    IUserSelectorSettings,
    IUserSelectorDlgResult,
} from '../dialogs/user-selector-dlg/user-selector-dlg.interface';
import { IChatMember } from '../models';
import { Store } from 'src/app/store';
import { EChatStoreKey } from '../enums';

@Injectable({
    providedIn: 'root',
})
export class ChatDialogService {
    constructor(public dialog: MatDialog, private store: Store) {}

    public openUsersSelectorDlg(
        mode = 'single',
        label = 'Apply',
        selectedMembers: IChatMember[] = [],
        allowedUserTypes?,
        showTypeSelector = true
    ): Promise<IChatMember[] | IChatMember> {
        return new Promise((resolve) => {
            this.dialog
                .open(UserSelectorDlgComponent, {
                    height: '616px',
                    width: '390px',
                    position: { top: '305px' },
                    panelClass: 'new-chat-dialog-container',
                    data: {
                        mode: mode,
                        label,
                        userList: null,
                        selectedUsers: selectedMembers,
                        allowedUserTypes,
                        showTypeSelector,
                    } as IUserSelectorSettings,
                })
                .afterClosed()
                .subscribe((data: IUserSelectorDlgResult) => {
                    if (data?.status === EUserSelectorDlgResultStatus.Selected) {
                        resolve(data.members);
                    } else {
                        resolve(null);
                    }
                });
        });
    }

    public openMessageReminderDlg(): Promise<IChatReminderDlgResult> {
        return new Promise((resolve) => {
            this.dialog
                .open(MessageReminderDlgComponent, {
                    height: '576px',
                    width: '468px',
                    panelClass: 'common-dialog-panel',
                })
                .afterClosed()
                .subscribe((data: IChatReminderDlgResult) => {
                    if (data) {
                        resolve(data);
                    } else {
                        resolve(null);
                    }
                });
        });
    }
}
