<input
    type="text"
    class="search-text-input_light"
    placeholder="Search"
    [formControl]="searchText"
    (focus)="onSearchFocus()" />
<div
    [matMenuTriggerFor]="searchResultsMenu"
    #searchMenuTrigger="matMenuTrigger"></div>

<!-- Search Result Menu Panel-->
<mat-menu
    #searchResultsMenu="matMenu"
    class="popup-simple-menu search-results-panel">
    <div
        class="result-scroll-panel styled-scrolled"
        *ngIf="searchResults?.total">
        <div class="search-results-top">
            <img
                class="search-results-icon"
                src="/assets/images/chat/search-results-icon.svg" />
            <span>Search results ({{ searchResults.total }})</span>
        </div>
        <div
            class="search-results-block"
            *ngIf="searchResults?.data[EChatType.DefaultChat].length">
            <div class="search-results-heading">
                <span>Personal Chat</span>
            </div>
            <div
                class="personal-chat-row"
                *ngFor="let chat of searchResults?.data[EChatType.DefaultChat]"
                (click)="selectChat(chat.chat.id)">
                <div class="personal-chat-avatar">
                    <app-user-avatar
                        [firstname]="chat.member.firstname"
                        [lastname]="chat.member.surname"
                        [colour]="chat.member.avatarColour"
                        [fontSize]="'12px'"
                        [fontWeight]="'600'">
                    </app-user-avatar>
                </div>
                <div class="personal-chat-name-holder">
                    <div class="personal-chat-name">
                        <span
                            class="highlight-matching"
                            [innerHtml]="highlightMatches(chat.member.firstname + ' ' + chat.member.surname)">
                        </span>
                    </div>
                    <div class="personal-chat-accounttype">{{ chat.member.accountType }}</div>
                </div>
            </div>
        </div>
        <!-- Do not remove code below - will use this design later! -->
        <!--<div class="search-results-block" *ngIf="searchResults?.data[EChatType.Order].length">
      <div class="search-results-heading">
        <span>Order Chats</span>
      </div>
      <div class="order-chat-row">
        <div class="order-chat-header">#ORD-103</div>
        <div class="order-chat-text">12 members in order chat including Frank Spencer</div>
      </div>
      <div class="order-chat-row">
        <div class="order-chat-header">#ORD-1052</div>
        <div class="order-chat-text match-to-search">8 members in order chat including Frank Spencer</div>
      </div>
      <div class="order-chat-row">
        <div class="order-chat-header">#ORD-288</div>
        <div class="order-chat-text match-to-search">9 members in order chat including Frank Spencer</div>
      </div>
    </div>-->
        <div
            class="search-results-block"
            *ngIf="searchResults?.data[EChatType.Channel].length">
            <div class="search-results-heading">
                <span>Channels</span>
            </div>
            <div
                class="channel-row"
                *ngFor="let srChannel of searchResults?.data[EChatType.Channel]"
                (click)="selectChat(srChannel.chat.id)">
                <div
                    class="channel-header"
                    [innerHtml]="highlightMatches(srChannel.chat.name ? srChannel.chat.name : 'Unnamed Channel')"></div>
                <div
                    class="channel-text"
                    *ngIf="srChannel.member"
                    [innerHtml]="
                        srChannel.chat.members.length +
                        ' members in channel including ' +
                        highlightMatches(srChannel.member.firstname + ' ' + srChannel.member.surname)
                    "></div>
            </div>
        </div>
        <div
            class="search-results-block"
            *ngIf="searchResults?.data[EChatType.CustomerService].length">
            <div class="search-results-heading">
                <span>Customer Services</span>
            </div>
            <div
                class="channel-row"
                *ngFor="let srChannel of searchResults?.data[EChatType.CustomerService]"
                (click)="selectChat(srChannel.chat.id)">
                <div
                    class="channel-header"
                    [innerHtml]="
                        highlightMatches(srChannel.chat.name ? srChannel.chat.name + '11' : 'Unnamed Channel')
                    "></div>
                <div
                    class="channel-text"
                    *ngIf="srChannel.member"
                    [innerHtml]="
                        srChannel.chat.members.length +
                        ' members in channel including ' +
                        highlightMatches(srChannel.member.firstname + ' ' + srChannel.member.surname)
                    "></div>
            </div>
        </div>
    </div>
    <div
        *ngIf="!searchResults?.total"
        class="no-results-found">
        No results found!
    </div>
</mat-menu>
