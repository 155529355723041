
<div 
  class="chat-type"
  [ngClass]="{ 'selected' : type === EChatGroupType.ADMIN }"
  (click)="toggle(EChatGroupType.ADMIN )">
  Admin
</div>
<div 
  class="chat-type"
  [ngClass]=" { 'selected' : type === EChatGroupType.CUSTOMER }"
  (click)="toggle(EChatGroupType.CUSTOMER)">
  Customer
</div>
