<div *ngIf="isOpen" [@slideLeftRight] (@slideLeftRight.done)="onSlideLeftRightEnd()"
     class="defaultAside aside-left aside-open shadow-none bg-primary overflow-auto">

    <ng-container *ngIf="user.accountType === 'trade'">
        <div class="d-flex flex-column">
            <div class="storefront__header d-flex align-items-center py-4">
                <button
                    (click)="goToRouteWithDelay('/')"
                    type="button"
                    class="btn btn-sidebar btn-sidebar--top fs-6 text-start w-100 px-5 py-2">
                    <svg-icon src="assets/images/icon-storefront.svg" [svgClass]="'icon-sidebar--mobile'"></svg-icon>
                    <span class="ms-3">Storefront</span>
                </button>
                <a href="javascript:void(0)" class="me-3" (click)="startClosing()">
                    <svg-icon src="assets/images/icon-menu-close.svg"
                              [svgClass]="'icon-sidebar--close'"></svg-icon>
                </a>
            </div>
            <div class="title d-flex align-items-center op-4 px-5 py-2 mt-3">
                <div
                    class="ff-spectral small text-white">
                    Actions
                </div>
            </div>

            <!--
            <button
                *ngIf="user.accountType === 'trade'"
                [routerLink]="['/account', 'mail']"
                type="button"
                [ngClass]="{'active': step === 'chat'}"
                class="btn btn-sidebar fs-6 text-start px-5 py-2">
                <svg-icon src="assets/images/icon-chat.svg" [svgClass]="'icon-sidebar--mobile'"></svg-icon>
                <span class="ms-3">Chat</span>
            </button>
            -->

            <button
                (click)="addUser()"
                type="button"
                [ngClass]="{'active': step === 'users'}"
                class="btn btn-sidebar fs-6 text-start px-5 py-2">
                    Add New User
            </button>
            <hr class="bg-secondary op-9">
            <div class="title d-flex align-items-center op-4 px-5 py-2">
                <div
                    class="ff-spectral small text-white">
                    Navigation
                </div>
            </div>
            <button
                (click)="goToRouteWithDelay('/account')"
                [routerLinkActive]="'active'"
                [routerLinkActiveOptions]="{exact: true}"
                type="button"
                class="btn btn-sidebar fs-6 text-start px-5 py-2">
                Overview
            </button>

            <button
                (click)="goToRouteWithDelay('/account/orders')"
                [routerLinkActive]="'active'"
                [routerLinkActiveOptions]="{exact: false}"
                type="button"
                class="btn btn-sidebar fs-6 text-start px-5 py-2">
                Orders
            </button>

            <button
                (click)="goToRouteWithDelay('/account/rewards')"
                [routerLinkActive]="'active'"
                [routerLinkActiveOptions]="{exact: false}"
                type="button"
                class="btn btn-sidebar fs-6 text-start px-5 py-2">
                Rewards
            </button>

            <button
                (click)="goToRouteWithDelay('/account/samples')"
                [routerLinkActive]="'active'"
                [routerLinkActiveOptions]="{exact: false}"
                type="button"
                class="btn btn-sidebar fs-6 text-start px-5 py-2">
                Samples
            </button>

            <!--
            <button
                [routerLink]="['/account', 'jobs']"
                [routerLinkActive]="'active'"
                [routerLinkActiveOptions]="{exact: true}"
                type="button"
                class="btn btn-sidebar fs-6 text-start px-5 py-2">
                Find work
            </button>


            <button
                [routerLink]="['/account', 'jobs', 'accepted']"
                [routerLinkActive]="'active'"
                [routerLinkActiveOptions]="{exact: false}"
                type="button"
                class="btn btn-sidebar fs-6 text-start px-5 py-2">
                Jobs
            </button>

            -->

            <button
                (click)="goToRouteWithDelay('/account/users')"
                [routerLinkActive]="'active'"
                [routerLinkActiveOptions]="{exact: false}"
                type="button"
                class="btn btn-sidebar fs-6 text-start px-5 py-2">
                Users
            </button>

            <hr class="bg-secondary op-9">
            <div class="title d-flex align-items-center op-4 px-5 pt-2">
                <div class="ff-spectral small text-white">Company</div>
            </div>
            <div class="ff-spectral fs-4 text-white px-5 py-2">
                {{user.company.name}}
            </div>
            <div class="title d-flex align-items-center op-4 px-5 pt-2">
                <div class="ff-spectral small text-white">Company Number</div>
            </div>
            <div class="ff-spectral fs-4 text-white px-5 py-2">
                {{user.company.companyNumber}}
            </div>
            <div class="title d-flex align-items-center op-4 px-5 pt-2">
                <div class="ff-spectral small text-white">VAT Number</div>
            </div>
            <div class="ff-spectral fs-4 text-white px-5 py-2">
                {{user.company.vatNumber}}
            </div>

        </div>
    </ng-container>

    <ng-container *ngIf="user.accountType === 'customer'">
        <div class="d-flex flex-column">
            <div class="storefront__header d-flex align-items-center py-4">
                <button
                    (click)="goToRouteWithDelay('/')"
                    type="button"
                    class="btn btn-sidebar btn-sidebar--top fs-6 text-start w-100 px-5 py-2">
                    <svg-icon src="assets/images/icon-storefront.svg" [svgClass]="'icon-sidebar--mobile'"></svg-icon>
                    <span class="ms-3">Storefront</span>
                </button>
                <a href="javascript:void(0)" class="me-3" (click)="startClosing()">
                    <svg-icon src="assets/images/icon-menu-close.svg"
                              [svgClass]="'icon-sidebar--close'"></svg-icon>
                </a>
            </div>
            <div class="title d-flex align-items-center op-4 px-5 py-2 mt-3">
                <div
                    class="ff-spectral small text-white">
                    Actions
                </div>
            </div>

            <button
                (click)="goToRouteWithDelay('/account/photos')"
                [routerLinkActive]="'active'"
                [routerLinkActiveOptions]="{exact: true}"
                type="button"
                class="btn btn-sidebar fs-6 text-start px-5 py-2">
                    Submit Photos
            </button>

            <hr class="bg-secondary op-9">
            <div class="title d-flex align-items-center op-4 px-5 py-2">
                <div
                    class="ff-spectral small text-white">
                    Navigation
                </div>
            </div>
            <button
                (click)="goToRouteWithDelay('/account')"
                [routerLinkActive]="'active'"
                [routerLinkActiveOptions]="{exact: true}"
                type="button"
                class="btn btn-sidebar fs-6 text-start px-5 py-2">
                Overview
            </button>

            <button
                (click)="goToRouteWithDelay('/account/orders')"
                [routerLinkActive]="'active'"
                [routerLinkActiveOptions]="{exact: false}"
                type="button"
                class="btn btn-sidebar fs-6 text-start px-5 py-2">
                My Orders
            </button>

            <button
                (click)="goToRouteWithDelay('/account/samples')"
                [routerLinkActive]="'active'"
                [routerLinkActiveOptions]="{exact: false}"
                type="button"
                class="btn btn-sidebar fs-6 text-start px-5 py-2">
                Samples
            </button>

            <button
                (click)="goToRouteWithDelay('/account/payments')"
                [routerLinkActive]="'active'"
                [routerLinkActiveOptions]="{exact: false}"
                type="button"
                class="btn btn-sidebar fs-6 text-start px-5 py-2">
                Payment History
            </button>

            <button
                (click)="goToRouteWithDelay('/account/rewards')"
                [routerLinkActive]="'active'"
                [routerLinkActiveOptions]="{exact: false}"
                type="button"
                class="btn btn-sidebar fs-6 text-start px-5 py-2">
                Rewards
            </button>

            <!--
            <button
                [routerLink]="['/account', 'proposals']"
                [routerLinkActive]="'active'"
                [routerLinkActiveOptions]="{exact: false}"
                type="button"
                class="btn btn-sidebar fs-6 text-start px-5 py-2">
                Proposals
            </button>
            -->

            <button
                (click)="goToRouteWithDelay('/account/settings')"
                [routerLinkActive]="'active'"
                [routerLinkActiveOptions]="{exact: false}"
                type="button"
                class="btn btn-sidebar fs-6 text-start px-5 py-2">
                My Profile
            </button>
        </div>
    </ng-container>
</div>
