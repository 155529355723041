import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { ConfirmationData } from 'src/app/data-types/confirmation-data';


@Component({
    selector: 'app-confirmation',
    templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
    @Input() data: ConfirmationData;
    @Output() complete = new EventEmitter();

    constructor(public dialogRef: MatDialogRef<ConfirmationComponent>) {}

    ngOnInit() {}

    confirm() {
        this.data.isMatDialog
            ? this.dialogRef.close("confirm")
            : this.complete.emit({ action: true, data: this.data.data });
    }

    cancel() {
        this.data.isMatDialog
            ? this.dialogRef.close("cancel")
            : this.complete.emit({ action: false, data: this.data.data });
    }

}
