import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '../store';
import { CookieService } from '../services/cookie.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(
        private store: Store,
        private router: Router,
        private cookieService: CookieService,
    ) {

    }

    async canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Promise<boolean | UrlTree>
    {

        const res = await this.cookieService.isAuthenticated();
        return res ? true : this.router.parseUrl('/login');
    }
}
