import { NgModule } from '@angular/core';
import { DashboardSupplierComponent } from './dashboard-supplier/dashboard-supplier.component';
import { DashboardCourierComponent } from './dashboard-courier/dashboard-courier.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../pipes/pipes.module';


@NgModule({
    declarations: [
        DashboardSupplierComponent,
        DashboardCourierComponent,
        DashboardComponent
    ],
    imports: [
        CommonModule,
        PipesModule
    ],
    exports: [
        DashboardComponent
    ]
})

export class DashboardsModule {
}
