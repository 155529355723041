<div
	class="menu-holder menu-open"
	*ngIf="user">
	<div class="menu-container">
		<div class="list-group">
			<ng-container *ngIf="user.accountType === 'supplier'">
				<button
					type="button"
					class="list-group-item list-group-item-action list-group-item-title">
					Actions
				</button>
				<button
					type="button"
					class="btn btn-sidebar fs-6 text-start px-5 py-2"
					[ngClass]="{ active: step === 'chat' }"
					(click)="requestCreate()">
					Submit a Request
				</button>
				<button
					type="button"
					class="btn btn-sidebar fs-6 text-start px-5 py-2"
					[ngClass]="{ active: step === 'chat' }"
					[routerLink]="['/account', 'chat']"
					routerLinkActive="active"
					[routerLinkActiveOptions]="{ exact: true }">
					Chat
				</button>
				<hr />

				<div class="title d-flex align-items-center op-4 px-5 py-2">
					<div class="ff-spectral small text-white">Navigation</div>
				</div>

				<button
					type="button"
					class="btn btn-sidebar fs-6 text-start px-5 py-2"
					[ngClass]="{ active: step === 'overview' }"
					[routerLink]="['/account']"
					routerLinkActive="active"
					[routerLinkActiveOptions]="{ exact: true }">
					Overview
				</button>
				<button
					type="button"
					class="btn btn-sidebar fs-6 text-start px-5 py-2"
					[ngClass]="{ active: step === 'orders' }"
					[routerLink]="['/account/orderdetails']"
					routerLinkActive="active"
					[routerLinkActiveOptions]="{ exact: true }">
					Orders
				</button>
				<button
					type="button"
					class="btn btn-sidebar fs-6 text-start px-5 py-2"
					[ngClass]="{ active: step === 'samples' }"
					[routerLink]="['/account/ordersamples']"
					routerLinkActive="active"
					[routerLinkActiveOptions]="{ exact: true }">
					Sample Requests
				</button>
				<button
					type="button"
					class="btn btn-sidebar fs-6 text-start px-5 py-2"
					[ngClass]="{ active: step === 'samples' }"
					[routerLink]="['/account/requests']"
					routerLinkActive="active"
					[routerLinkActiveOptions]="{ exact: true }">
					Yorkstone Requests
				</button>
				<button
					type="button"
					class="btn btn-sidebar fs-6 text-start px-5 py-2"
					[routerLink]="['/account/products']"
					[ngClass]="{ active: step === 'products' }"
					routerLinkActive="active"
					[routerLinkActiveOptions]="{ exact: true }">
					Products
				</button>
				<button
					type="button"
					class="btn btn-sidebar fs-6 text-start px-5 py-2"
					[ngClass]="{ active: step === 'users' }"
					[routerLink]="['/account/users']"
					routerLinkActive="active"
					[routerLinkActiveOptions]="{ exact: true }">
					Users
				</button>
				<button
					type="button"
					class="btn btn-sidebar fs-6 text-start px-5 py-2"
					[routerLink]="['/account/couriers']"
					[ngClass]="{ active: step === 'couriers' }"
					routerLinkActive="active"
					[routerLinkActiveOptions]="{ exact: true }">
					Couriers
				</button>
			</ng-container>

			<ng-container *ngIf="user.accountType === 'courier'">
				<button
					type="button"
					class="btn btn-sidebar fs-6 text-start px-5 py-2">
					Actions
				</button>
				<button
					type="button"
					class="btn btn-sidebar fs-6 text-start px-5 py-2"
					(click)="requestCreate()"
					[ngClass]="{ active: step === 'chat' }"
					routerLinkActive="active"
					[routerLinkActiveOptions]="{ exact: true }">
					Submit a Request
				</button>
				<button
					type="button"
					class="btn btn-sidebar fs-6 text-start px-5 py-2"
					[ngClass]="{ active: step === 'chat' }"
					[routerLink]="['/account', 'mail']"
					routerLinkActive="active"
					[routerLinkActiveOptions]="{ exact: true }">
					Chat
				</button>
				<hr />

				<button
					type="button"
					class="btn btn-sidebar fs-6 text-start px-5 py-2">
					Navigation
				</button>
				<button
					type="button"
					class="btn btn-sidebar fs-6 text-start px-5 py-2"
					[routerLink]="['/account']"
					[ngClass]="{ active: step === 'overview' }"
					routerLinkActive="active"
					[routerLinkActiveOptions]="{ exact: true }">
					Overview
				</button>
				<button
					type="button"
					class="btn btn-sidebar fs-6 text-start px-5 py-2"
					[routerLink]="['/account/shipments-board']"
					[ngClass]="{ active: step === 'shipmentsBoard' }"
					routerLinkActive="active"
					[routerLinkActiveOptions]="{ exact: true }">
					Shipments Board
				</button>
			</ng-container>
			<hr />

			<div class="account-info">
				<div class="title d-flex align-items-center op-4 pt-2">
					<div class="ff-spectral small text-white">Account Name</div>
				</div>
				<div class="ff-spectral fs-4 text-white py-2">
					{{ user.company.name }}
				</div>
				<div class="title d-flex align-items-center op-4 pt-2">
					<div class="ff-spectral small text-white">Company Number</div>
				</div>
				<div class="ff-spectral fs-4 text-white py-2">
					{{ user.company.companyNumber }}
				</div>
				<div class="title d-flex align-items-center op-4 pt-2">
					<div class="ff-spectral small text-white">VAT Number</div>
				</div>
				<div class="ff-spectral fs-4 text-white py-2">
					{{ user.company.vatNumber }}
				</div>
			</div>
		</div>
	</div>
</div>
