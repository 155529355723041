import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApiAuthHttpClient } from 'src/app/http/ApiAuthHttpClient';
import { Store } from 'src/app/store';
import { environment as env } from 'src/environments/environment';
import { IChatListItem, IChatMember, IChatMessage } from '../models';
import { ChatMapper } from '../mappers/chat.mapper';
import { ChatListItemMapper } from '../mappers/chat-list-item.mapper';
import { IChatMessageReminder } from '../models/chat-message-reminder.interface';
import { ApiService } from 'src/app/services/api.service';
import { ChatMemberMapper } from '../mappers/chat-member.mapper';
import { EChatType } from '../enums';

@Injectable({
	providedIn: 'root',
})
export class ChatRESTService {
	user = this.store.selectForLocal('user');

	constructor(private store: Store, private http: HttpClient, private apiService: ApiService) {}

	getChatList(): Observable<IChatListItem[]> {
		this.user = this.store.selectForLocal('user');
		return this.http.get(env.apiPath + 'chat-admin/groups/' + this.user.id).pipe(
			map((data: any) => {
				const chats = this.hideEmptyChats(this.removeChatDuplicates(data.data));
				return chats.map((c) => ChatListItemMapper.toDomain(c));
			})
		);
	}

	getChat(chatId: number) {
		return this.http.get(env.apiPath + 'chat-admin/chat/' + chatId).pipe(
			map((data: any) => {
				let chat = data.data;
				return ChatMapper.toDomain(chat);
			})
		);
	}

	sendMessage(message: IChatMessage) {
		return this.http.post<void>(env.apiPath + 'chat-admin/message', { message }, this.apiService.getHttpOptions());
	}

	editMessage(message: IChatMessage) {
		return this.http.put<void>(env.apiPath + 'chat-admin/message', { message }, this.apiService.getHttpOptions());
	}

	deleteMessage(message: IChatMessage) {
		return this.http.delete<void>(env.apiPath + `chat-admin/message/${message.id}`);
	}

	addMemberToChannel(member: IChatMember, chatId: number): Observable<void> {
		return this.http.post<void>(
			env.apiPath + 'chat-admin/chat/member',
			{ member, chatId },
			this.apiService.getHttpOptions()
		);
	}

	removeMemberFromChannel(member: IChatMember, chatId: number) {
		return this.http.delete<void>(env.apiPath + `chat-admin/chat/member/${chatId}/${member.id}`);
	}

	archiveChat(chatId: number) {
		return this.http.post<void>(
			env.apiPath + `chat-admin/chat/archive`,
			{ chatId },
			this.apiService.getHttpOptions()
		);
	}

	getUserList(): Observable<IChatMember[]> {
		return this.http.get<{ data: IChatMember[] }>(env.apiPath + 'chat-admin/users').pipe(
			map((res) => {
				return res.data.map((u) => ChatMemberMapper.toDomain(u));
			})
		);
	}

	markAsRead(chatId) {
		return this.http.delete(env.apiPath + 'chat-admin/unread/' + chatId + '/' + this.user.id);
	}

	setReminder(reminder): Observable<IChatMessageReminder> {
		return this.http
			.post<{ data: IChatMessageReminder }>(
				env.apiPath + 'chat-admin/reminder',
				reminder,
				this.apiService.getHttpOptions()
			)
			.pipe(
				map((res) => {
					return {
						...res.data,
						chatId: reminder.chatId,
						datetime: new Date(reminder.datetime),
					};
				})
			);
	}

	getReminders() {
		return this.http.get(env.apiPath + 'chat-admin/reminder').pipe(
			map((data: any) =>
				data.data.map((r) => {
					return { ...r, datetime: new Date(r.datetime) };
				})
			)
		);
	}

	deleteReminder(reminderId: number) {
		return this.http.delete(env.apiPath + `chat-admin/reminder/${reminderId}`);
	}

	/**
	 * For this moment we can have in DB several chat for same members
	 * New functionality allow to have only one chat for unique combination of members
	 * This function remove duplication of chats, to fit old data to new requirements
	 */
	private removeChatDuplicates(chats) {
		chats.sort(function (a, b) {
			return new Date(b.lastAction).getTime() - new Date(a.lastAction).getTime();
		});

		const uniqueMemberIdsHashes = [];
		const uniqueChats = [];
		chats.forEach((chat) => {
			// allow same members list for Channels and Customer Service
			if ([EChatType.Channel, EChatType.CustomerService].includes(chat.type)) {
				uniqueChats.push(chat);
				return;
			}

			const hash =
				chat.users
					.map((u) => u.userId)
					.sort()
					.concat('')
					.join('') + chat.type;
			if (!uniqueMemberIdsHashes.includes(hash)) {
				uniqueChats.push(chat);
				uniqueMemberIdsHashes.push(hash);
			}
		});

		return uniqueChats;
	}

	hideEmptyChats(chats) {
		return chats.filter((c) => c.messagesCnt > 0);
	}
}
