<div class="modal">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content me-3 ms-3">
            <div class="modal-header">
                <h4 class="modal-title">Request</h4>
                <!-- <a class="close" data-dismiss="modal" (click)="close()">
                    X
                </a> -->
                <button type="button" class="close cross-icon" data-dismiss="modal" (click)="close()">
                    <span aria-hidden="true">×</span>
                    <span class="sr-only">Close</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="form" autocomplete="off" novalidate [autocomplete]="'off'" (ngSubmit)="onSubmit()">

                    <div class="form-group row">
                        <label class="col-sm-12 col-form-label pt-0">Request Type</label>
                        <div class="col-12 col-sm-12">
                            <ng-select  formControlName="type"
                            [clearable]="false"
                            [searchable]="false"  [ngClass]="{
                                'is-invalid':
                                    form['controls'].type.invalid &&
                                    form['controls'].type.touched
                            }" >
                            <ng-option [value]="0">Select Request Type</ng-option>
                            <ng-option [value]="v.id" *ngFor="let v of requestTypes">
                                {{v.name}}
                            </ng-option>
                           </ng-select>  
                        </div>
                    </div>



                    <div class="form-group row">
                        <label class="col-sm-12 col-form-label">Message</label>
                        <div class="col-12 col-sm-12">
                            <textarea [ngClass]="{
                                'is-invalid':
                                    form['controls'].message.invalid &&
                                    form['controls'].message.touched
                            }" rows="30" class="form-control message-box" formControlName="message"
                                placeholder="Enter your message"></textarea>
                            <div class="invalid-feedback">
                                Message is required
                            </div>
                        </div>
                    </div>


                    <div class="text-left" style="padding-top: 30px;">
                        <div class="row">
                            <div class="col-6">
                                <button type="button" class="btn btn-round btn-primary-reverse close modal-btn-cancel"
                                    data-dismiss="modal" (click)="close()">
                                    Cancel
                                </button>
                            </div>
                            <div class="col-6 text-right">
                                <button [disabled]="!form.valid" type="submit" class="btn btn-round btn-primary modal-btn-create float-right">
                                    <span *ngIf="newItem">Create</span>
                                    <span *ngIf="!newItem">Update</span>
                                </button>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
</div>

<app-confirmation *ngIf="this.confirmationData.open" [data]="confirmationData"
    (complete)="confirmationComplete($event)"></app-confirmation>

<div class="modal-backdrop show"></div>