<div class="data-container">
    <h2>Areas you would like to receive quotes for </h2>
    <button (click)="managePostcodes()" type="button" class="btn btn-round btn-primary">
        Add postcode areas
    </button>

    <div class="table-responsive mt-30">
        <table class="table table-condensed">
            <thead class="thead-white">

            <tr>
                <th>Postcode</th>
                <th>Area</th>
                <th>Region</th>
                <th>Actions</th>
            </tr>
            <tr>
                <th>
                    <input type="text" class="form-control" [(ngModel)]="params.postcode" (ngModelChange)="filterSearch()"/>
                </th>
                <th><input type="text" class="form-control" [(ngModel)]="params.area" (ngModelChange)="filterSearch()"/>
                </th>
                <th>
                    <input type="text" class="form-control" [(ngModel)]="params.region" (ngModelChange)="filterSearch()"/>
                </th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr class="underline" *ngFor="let s of items">
                <td>{{s.postcode}}</td>
                <td>{{s.area}}</td>
                <td>{{s.region}}</td>
                <td>
                    <a (click)="removePostcode(s.postcode)">
                        <i class="fa fa-trash"></i>
                    </a>
                </td>
            </tr>
            </tbody>
        </table>
    </div>

    <div class="no-results" *ngIf="!items.length">
        <p>No areas defined</p>
    </div>

    <app-pagination *ngIf="params.pageArray.length" [params]="params" (update)="search()"></app-pagination>
</div>


<app-company-area-manage
        *ngIf="(overlays$ | async).companyPostcodes"
        [companyId]="(user$ | async).company.id"
        (refreshData)="this.filterSearch()"></app-company-area-manage>
