<div class="chat-archive-row">
  <div class="chat-archive-row-cell">
    <div class="archive-gray-label">Chat Organizator:</div>
    <div class="archive-bold-text">{{ createdByName }}</div>
  </div>
  <div class="chat-archive-row-cell">
    <div class="archive-gray-label">Chat Type:</div>
    <div class="archive-bold-text">{{ chatType }}</div>
  </div>
  <div 
    class="chat-archive-row-cell archive-members" 
    [matMenuTriggerFor]="userList"
    #menuTrigger="matMenuTrigger">
    <div class="archive-gray-label">Amount of users:</div>
    <div class="archive-bold-text">{{ membersCnt }} users</div>
  </div>
  <div class="chat-archive-row-cell">
    <div class="archive-gray-label">Amount of Messages:</div>
    <div class="archive-bold-text">{{ amountofMessages }} messages</div>
  </div>
  <div class="chat-archive-row-cell">
    <div class="archive-gray-label">Start Date:</div>
    <div class="archive-bold-text">{{ createdAt }}</div>
  </div>
  <div class="chat-archive-row-cell">
    <div class="archive-gray-label">Archive Date:</div>
    <div class="archive-bold-text">{{ archiveDate }}</div>
  </div>
  <div class="chat-archive-row-cell view-chat-btn-holder">
    <button 
      class="view-chat-btn btn-styled" 
      type="button"
      (click)="viewChat()">
      <img class="view-chat-icon" src="assets/images/chat/view-chat-icon.svg" />
        View Chat
    </button>
  </div>
</div>

<!-- Message Opions -->
<mat-menu #userList="matMenu" class="popup-chat-user-list">
  <div *ngFor="let member of chatItem.members" class="archive-chat-member">
    <div class="archive-chat-member-avatar"> 
      <app-user-avatar
        [firstname]="member.firstname"
        [lastname]="member.surname"
        [colour]="member.avatarColour"
        [fontSize]="'8px'"
        [fontWeight]="'400'"
        [detectOnline]="true"
        [userId]="member.id">
      </app-user-avatar>
    </div>
    <div>{{ member.firstname }} {{ member.surname }}</div>
  </div>
</mat-menu>
