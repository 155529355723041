<div class="new-channel-header">
	<span class="new-channel-title">New Channel</span>
</div>
<div class="new-channel-body">
	<div class="name-holder">
		<label class="padded-top-gray-label">Subject:</label>
		<input
			type="text"
			class="normal-text-input"
			[formControl]="channelName"
			placeholder="Enter Subject" />
	</div>
</div>
<div class="new-channel-buttons">
	<button
		class="btn btn-styled--secondary btn-block btn-select"
		type="button"
		mat-dialog-close
		[disabled]="false">
		Cancel
	</button>
	<button
		class="btn btn-styled btn-block btn-select mx-2"
		type="button"
		[disabled]="!canCreateChannel"
		(click)="createChannel()">
		Create
	</button>
</div>
