<div class="table-responsive">
    <table class="table table-condensed">
        <thead class="thead-white">

        <tr>
            <th>Requested</th>
            <th>Shipment No</th>
            <th>Customer</th>
            <th>Supplier</th>
            <th>Route</th>
            <th>Weight / Pallets</th>
            <th>Vehicle</th>
            <th>Confirmed<br />Collection Date</th>
            <th>Delivery Date</th>
            <th>Status</th>
        </tr>
<!--        <tr>-->
<!--            <th><input type="text" class="form-control" [(ngModel)]="params.createdAt" (ngModelChange)="filterSearch()" /></th>-->
<!--            <th><input type="text" class="form-control" [(ngModel)]="params.publicId" (ngModelChange)="filterSearch()" /></th>-->
<!--            <th><input type="text" class="form-control" [(ngModel)]="params.customer" (ngModelChange)="filterSearch()" /></th>-->
<!--            <th><input type="text" class="form-control" [(ngModel)]="params.supplier" (ngModelChange)="filterSearch()" /></th>-->
<!--            <th><input type="text" class="form-control" [(ngModel)]="params.weight" (ngModelChange)="filterSearch()" /></th>-->
<!--            <th><input type="text" class="form-control" [(ngModel)]="params.pallets" (ngModelChange)="filterSearch()" /></th>-->
<!--            <th><input type="text" class="form-control" [(ngModel)]="params.vehicle" (ngModelChange)="filterSearch()" /></th>-->
<!--            <th><input type="text" class="form-control" [(ngModel)]="params.estDeliveryDate" (ngModelChange)="filterSearch()" /></th>-->
<!--            <th><input type="text" class="form-control" [(ngModel)]="params.shippingStatus" (ngModelChange)="filterSearch()" /></th>-->
<!--            <th></th>-->
<!--        </tr>-->
        </thead>
        <tbody>
        <tr class="underline" *ngFor="let s of items">
            <td>{{s.createdAt | date: 'dd MMM yyyy'}}</td>
            <td>{{s.publicId}}</td>
            <td>{{s.contactsName}}</td>
            <td>{{s.supplierName}}</td>
            <td>{{s.collectionPostcodeUsed}} <i class="fa fa-arrow-right"></i> {{s.deliveryPostcodeUsed}}</td>
            <td>{{s.weight}} /{{s.pallets}}</td>
            <td>
                <ng-container *ngIf="s.vehicle">
                    {{s.vehicle.registration}}
                </ng-container>
                <ng-container *ngIf="!s.vehicle">
                    Not assigned
                </ng-container>
                <a (click)="assignVehicle(s)">
                    <i class="fa fa-edit"></i>
                </a>
            </td>
            <!--            <td>{{s.estShippingDate | date: 'dd MMM yyyy'}}</td>-->
            <td>
                <p *ngIf="s.collectionDate">{{s.collectionDate | date: 'dd MMM'}}</p>
<!--                <mat-form-field>-->
<!--                    <input matInput-->
<!--                           placeholder="Choose a date"-->
<!--                           [satDatepicker]="picker1"-->
<!--                           [(ngModel)]="s.collectionDate"-->
<!--                           (ngModelChange)="updateCollectionDate(s)">-->
<!--                    <sat-datepicker #picker1 [rangeMode]="false" [closeAfterSelection]="true"></sat-datepicker>-->
<!--                    <sat-datepicker-toggle matSuffix [for]="picker1"></sat-datepicker-toggle>-->
<!--                </mat-form-field>-->
            </td>

            <td>
                <p *ngIf="!s.confirmedShippingDate">Est. {{s.estShippingDate | date: 'dd MMM'}}</p>
                <p *ngIf="s.confirmedShippingDate">{{s.confirmedShippingDate | date: 'dd MMM'}}</p>
<!--                <mat-form-field>-->
<!--                    <input matInput-->
<!--                           placeholder="Choose a date"-->
<!--                           [satDatepicker]="picker"-->
<!--                           [(ngModel)]="s.confirmedShippingDate"-->
<!--                            (ngModelChange)="updateShippingDate(s)">-->
<!--                    <sat-datepicker #picker [rangeMode]="false" [closeAfterSelection]="true"></sat-datepicker>-->
<!--                    <sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>-->
<!--                </mat-form-field>-->
            </td>
            <td>
                <p *ngIf="s.status.id < 5">In production</p>
                <p *ngIf="s.status.id === 5">Awaiting collection</p>
                <p *ngIf="s.status.id === 6">In transit</p>
                <p *ngIf="s.status.id === 7">Delivered</p>
            </td>
            <td><a [routerLink]="['/account', 'shipments', s.id]">
                <i class="fa fa-search"></i>
            </a> </td>
        </tr>
        </tbody>
    </table>
</div>

<div class="no-results" *ngIf="!items.length">
    <p>No shipments found</p>
</div>

<app-pagination *ngIf="params.pageArray.length" [params]="params" (update)="search()"></app-pagination>

<app-shipment-vehicle
    *ngIf="(overlays$ | async).shipmentVehicle"
    [overlayData]="overlayData"
    (refreshData)="this.filterSearch()"></app-shipment-vehicle>

<app-confirmation
    *ngIf="confirmationData.open"
    [data]="confirmationData"
    (complete)="confirmationComplete($event)"></app-confirmation>
