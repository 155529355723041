export function sanitizeURL(url) {
  if (url) {
    url = url.toLowerCase();
    url = url.trim();
    url = url.replace(/\s+/g, "-");
    url = url.replace(/'/g, "");
    url = url.replace(/\|/g, "");
    url = url.replace(/,/g, "-");
    url = url.replace(/_/g, "-");
    url = url.replace(/--/g, "-");
    url = url.replace(/\?/g, "");
    url = url.replace(/&(?!amp;)/g, "and");
    return url;
  }
  return url;
}
export function getInitials(firstname: string, surname: string): string {
  return `${firstname.charAt(0)?.toUpperCase()}${surname
    .charAt(0)
    ?.toUpperCase()}`;
}

export function newUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
