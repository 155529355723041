import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { AlertService } from './alert.service';
import { Store } from '../store';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class ApiService {
	constructor(private alertService: AlertService, private store: Store) {}

	getHttpOptions() {
		const domain = this.store.selectForLocal('domain');
		const headers = {
			domain: domain,
			'x-api-key': '1553015152749A58DBD1BABE09729XO102',
		};
		if (environment.emulateSslForLocalDev) {
			headers['x-forwarded-proto'] = 'https';
		}
		return {
			headers: new HttpHeaders(headers),
			withCredentials: true,
		};
	}

	getHttpOptionsUpload() {
		const headers = {
			'x-api-key': '1553015152749A58DBD1BABE09729XO102',
		};
		if (environment.emulateSslForLocalDev) {
			headers['x-forwarded-proto'] = 'https';
		}
		return {
			headers: new HttpHeaders(headers),
			withCredentials: true,
			reportProgress: true,
			observe: 'events' as 'body',
		};
	}
}
