import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Store } from '../store';

@Injectable({
    providedIn: 'root'
})
export class ShipmentService {
    env = environment;

    constructor(
        private apiService: ApiService,
        private http: HttpClient,
        private store: Store,
    ) {
    }

    get(id) {
        return this.http.get(this.env.apiPath + 'shipments/' + id)
            .pipe(map((data: any) => data));
    }

    getByOrderId(orderId) {
        return this.http.get(this.env.apiPath + 'shipments/order/' + orderId)
            .pipe(map((data: any) => data));
    }

    getStatuses() {
        return this.http.get(this.env.apiPath + 'shipments/statuses')
            .pipe(map((data: any) => data));
    }

    search(params) {
        const dataToSend = {
            params,
        };
        return this.http.post(this.env.apiPath + 'shipments/search', dataToSend)
            .pipe(map((data: any) => data));
    }

    shipmentsBoard(params) {
        const dataToSend = {
            params,
        };
        return this.http.post(this.env.apiPath + 'shipments/board', dataToSend)
            .pipe(
                map((data: any) => {
                        this.store.set('shipmentsBoard', data.data);
                        return data;
                    }
                )
            );
    }

    assignVehicle(vehicleId, shipmentId) {
        const dataToSend = {
            vehicleId,
            shipmentId,
        };
        return this.http.post(this.env.apiPath + 'shipments/vehicle/assign', dataToSend)
            .pipe(map((data: any) => data));

    }

    setStatus(status, shipmentId) {
        const dataToSend = {
            status,
            shipmentId,
        };
        return this.http.put(this.env.apiPath + 'shipments/status', dataToSend)
            .pipe(map((data: any) => data));

    }

    setShippingDate(shipment) {
        const dataToSend = {
            shipment,
        };
        return this.http.put(this.env.apiPath + 'shipments/deliveryDate', dataToSend)
            .pipe(map((data: any) => data));
    }

    setCollectionDate(shipment) {
        const dataToSend = {
            shipment,
        };
        return this.http.put(this.env.apiPath + 'shipments/collectionDate', dataToSend)
            .pipe(map((data: any) => data));
    }
}
