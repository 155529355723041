<div class="archive-header">
  <div class="archive-title">{{ groupTitle }} Chats Archive</div>
  <div class="archive-subtitle">{{ chatsCnt }} chats</div>
</div>

<div class="archive-content styled-scrolled" *ngIf="chatsCnt; else emptyMessage">
  <app-chat-archive-row 
    *ngFor="let chatItem of chatsOnPage"
    [chatItem]="chatItem">
  </app-chat-archive-row>
</div>
<div class="archive-paginator">
  <app-pagination *ngIf="pageParams.pageArray.length" [params]="pageParams" (update)="paged($event)"></app-pagination>
</div>

<ng-template #emptyMessage>
  <div class="empty-message">{{ groupTitle }} Chats Archive is Empty</div>
</ng-template>
