<div class="modal show" tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body d-flex flex-column">
                <div class="d-flex justify-content-end mb-auto">
                    <button type="button" class="btn-close" (click)="cancel()"></button>
                </div>
                <div class="d-flex flex-column mb-auto">
                    <div class="text-center mb-4" *ngIf="data.type && data.type === 'warning'">
                        <img src="assets/images/icon-confirmation-warning.png" width="72px" height="auto"/>
                    </div>
                    <div class="text-center fs-4 mb-5" *ngIf="data.title">{{data.title}}</div>
                    <div class="text-center mb-5" *ngIf="data.detail">{{data.detail}}</div>
                    <div class="d-flex justify-content-center">
                        <button *ngIf="data.cancelButtonText" class="btn btn-thin btn-outline-primary me-4" (click)="cancel()">
                            {{data.cancelButtonText}}
                        </button>
                        <button class="btn btn-thin btn-danger" (click)="confirm()">
                            <span *ngIf="data.buttonText">{{data.buttonText}}</span>
                            <span *ngIf="!data.buttonText">OK</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-backdrop fade show modal-slide-show"></div>
