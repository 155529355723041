import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { Store } from 'src/app/store';
import { EChatEventName } from '../../enums';
import { EChatType } from '../../enums/chat-type.enum';
import { IChatListItem } from '../../models';
import { ChatEventsService } from '../../services/chat-events.service';

@Component({
  selector: 'app-chat-archive-row',
  templateUrl: './chat-archive-row.component.html',
  styleUrls: ['./chat-archive-row.component.scss']
})
export class ChatArchiveRowComponent implements OnInit {
  @Input() chatItem: IChatListItem;

  user = this.store.selectForLocal('user');

  constructor(
    private store: Store,
    private chatEventsService: ChatEventsService) { }

  get membersCnt() {
    return this.chatItem.members.length;
  }

  get chatType(): string {
    switch(this.chatItem.type) {
      case EChatType.Channel :
        return "Channel";

      //case EChatType.Order :
      //case EChatType.Action :
      default : 
        return "Default Chat";
    }

  }

  get createdByName(): string {
    return this.chatItem.createdByName ? this.chatItem.createdByName : 'Unknown'
  }

  get amountofMessages(): number {
    return this.chatItem.messagesCnt;
  }

  get createdAt(): string {
    return moment(this.chatItem.createdAt).format('DD/MM/YYYY'); 
  }

  get archiveDate(): string {
    return moment(this.chatItem.lastAction).format('DD/MM/YYYY'); 
  }

  ngOnInit(): void {
  }

  viewChat() {
    this.chatEventsService.emitEvent(
      EChatEventName.ChatSelected,
      {
        id: this.chatItem.id
      }
    );
  }
}
