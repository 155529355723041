export enum EChatEventName {
  CreateChat,
  ChatSelected,
  ChatUnselected,
  MessageSelectedForEdit,
  MessageSelectedForReply,
  MessageAdded,
  MessageDeleted,
  MessageEdited
}
