import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { Store } from './app/store';
import { OverlayService } from './app/services/overlay.service';
import {environment} from './environments/environment';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
    private activeRequests = 0;

    constructor(
        private store: Store,
        private overlayService: OverlayService,
        private toastr: ToastrService,
    ) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        this.activeRequests++;

        if (this.activeRequests === 1) {
            this.overlayService.open('busyIndicator');
        }
        const headers = {
            domain: 'ys',
            'api-key': '6bf9284a-ebf9-4afa-a185-4d2b9bb29e85',
            'Content-Type': 'application/json'
        }
        if (environment.emulateSslForLocalDev) {
            headers['x-forwarded-proto'] = 'https'
        }
        const authReq = request.clone({
            headers: new HttpHeaders(headers),
            withCredentials: true
        });

        return next.handle(authReq)
            .pipe(
                finalize(() => {
                    this.activeRequests--;
                    if (this.activeRequests === 0) {
                        this.overlayService.close('busyIndicator');
                    }
                }),
            );
    }
}
