import { UserAccountType, UserData } from 'src/app/data-types/user-data';
import { IChatMember } from '../../models';

export interface IUserSelectorSettings {
    mode: 'single' | 'many';
    selectedUsers?: IChatMember[] | UserData[];
    label?: string;
    showTypeSelector?: boolean;
    allowedUserTypes?: UserAccountType[];
    selectByClick?: boolean;
    showUserPhones?: boolean;
    showUserEmail?: boolean;
    showCompnayName?: boolean;
    style?: 'chat' | 'dailer-contacts' | null | undefined;
    avatarSize?: number;
    phoneEmailTopToggle?: boolean;
    showCreateLeadBtn?: boolean;
}

export interface IUserSelectorDlgResult {
    status: EUserSelectorDlgResultStatus;
    members?: IChatMember[] | IChatMember;
}

export enum EUserSelectorDlgResultStatus {
    Selected,
    Canceled,
}
