<section id="footer">

    <div class="container-fluid">
        <div class="row footer-sub-menu">
            <div class="col-12 col-lg-4 text-left">
                &copy;{{(settings$ | async).copyText}}
            </div>
            <div class="col-12 col-lg-4 d-flex justify-content-around text-center">
                <ng-container *ngFor="let q of (menu$ | async).footer">
                    <a *ngIf="q.external === 0" [routerLink]="[q.route]">{{q.name}}</a>
                    <a *ngIf="q.external === 1" target="_blank" [href]="[q.externalURL]">{{q.name}}</a>
                </ng-container>
            </div>
            <div class="col-12 col-lg-4 text-right">
                <span *ngIf="(settings$ | async).companyNo">Company registration number {{(settings$ | async).companyNo}}</span>
                <span *ngIf="(settings$ | async).companyNo && (settings$ | async).vatNo" class="ml-2 mr-2">|</span>
                <span *ngIf="(settings$ | async).vatNo">Vat number {{(settings$ | async).vatNo}}</span>
            </div>
        </div>
    </div>
</section>

