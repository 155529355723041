<div class="d-flex flex-column h-100">
    <div class="d-flex flex-column flex-grow-1 align-items-center justify-content-center bg-cover-image">
        <div class="container my-4">
            <div class="row g-0">
                <div class="col-12 col-md-8 offset-md-2 col-xl-6 offset-xl-3 shadow-lg">
                    <div class="d-flex flex-column bg-secondary py-4">
                        <div class="px-4 mb-3">
                            <svg-icon (click)="backToLogin()" src="assets/images/icon-arrow-left.svg" [class]="'hover'" [svgClass]="'icon--button'"></svg-icon>
                        </div>
                        <div class="d-flex flex-column my-auto">
                            <div class="d-flex flex-column justify-content-center text-center">
                                <svg-icon src="assets/images/ys-logo-text-reversed.svg"
                                          [svgClass]="'logo--text'"></svg-icon>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column align-items-center bg-white">
                        <div class="w-100 p-4 p-lg-5">
                            <div class="fs-4 text-center">
                                Forgotten Password
                            </div>
                            <hr>
                            <div class="text-center mb-4">
                                Don't worry, if you've forgotten your password. Enter your registered email address and we'll send you instructions on how to reset it.
                            </div>
                            <form [formGroup]="form" autocomplete="off" novalidate (ngSubmit)="onSubmit()" class="form--max-width mx-auto">

                                <div class="mb-4">
                                    <input
                                        type="email"
                                        class="form-control bordered"
                                        [ngClass]="{'is-invalid' : form.controls.email.invalid && form.controls.email.dirty}"
                                        formControlName="email"
                                        placeholder="Email address">
                                    <div class="invalid-feedback">Enter a valid email</div>
                                </div>
                
                                <div class="row d-flex justify-content-center">
                                    <div class="col-12 col-sm-6">
                                        <button
                                            [disabled]="form.invalid"
                                            type="submit"
                                            class="btn btn-round btn-primary">
                                            Reset password
                                        </button>
                                    </div>
                                 
                                </div>
                
                                
                
                            </form>
                        </div>
                        <div *ngIf="errorMessage" class="alert alert-danger text-center mt-4">
                            <p>{{errorMessage}}</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
