<div class="row">
    <div class="col-12">
        <div class="preview-box">
            <div class="preview-box-title">
                Sample Requests
            </div>
            <div class="preview-box-content">
                <div class="data-container">
                    <app-order-detail-search
                            class="mt-4"
                            [params]="orderParams"
                            [showSupplier]="showSupplier"
                            [showCustomer]="showCustomer"></app-order-detail-search>
                </div>
            </div>
        </div>

    </div>
</div>
