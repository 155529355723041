import { Component, Input, OnInit } from '@angular/core';
import { IChat } from '../../models';
import { ChatRESTService } from '../../services/chat-rest.service';

@Component({
  selector: 'app-chat-panel',
  templateUrl: './chat-panel.component.html',
  styleUrls: ['./chat-panel.component.scss']
})
export class ChatPanelComponent implements OnInit {
  @Input() chat: IChat;
  @Input() loading: boolean;

  constructor(public chatRESTService: ChatRESTService) { }

  ngOnInit(): void {
  }

}
