<div class="card">

    <div class="d-flex no-gutters p-2" [ngClass]="{ 'border-bottom': boardView !== 'progress'}">
        <div class="small">ORD-{{card.publicId}}/{{card.lineId}}</div>
        <div class="small-ligth flex-grow-1">{{card.createdAt | date: 'dd MMM yyyy'}}</div>

        <div *ngIf="card.osUpdateRequests.length" class="me-1">
            <i class="fa fa-exclamation-circle text-danger" awesomeTooltip="Outstanding update request" (click)="updateOrder.emit(card)"></i>
        </div>
        <div class="text-right">
            <i class="fa fa-ellipsis-h points" (click)="open(card)"></i>
            <app-order-board-popup
                    *ngIf="card.open"
                    [card]="card"
                    [idx]="colIndex"
                    [col]="col"
                    [params]="params"
                    [boardView]="boardView"
                    (updateRequested)="requestUpdate($event)"
            ></app-order-board-popup>
        </div>
    </div>

    <div class="py-1 px-2 grey-bar" *ngIf="boardView === 'progress'">{{card.contactFirstname}} {{card.contactSurname}}</div>

    <div class="p-0 pt-2 px-2" *ngIf="boardView !== 'progress'">
        <div class="sub-header">Customer</div>
        <div class="txt">{{card.contactFirstname}} {{card.contactSurname}}</div>
    </div>

    <div class="p-2">
        <div class="sub-header">Product</div>
        <div class="txt" *ngIf="card.productName"><b>{{card.productCode}}</b> {{card.productName}}</div>
        <div class="txt text-danger" *ngIf="!card.productName">Non assigned</div>
    </div>

    <div class="row m-0 p-0 px-2">
        <div class="col-6 p-0">
            <div class="sub-header">Quantity</div>
            <div class="mb-0">{{card.quantity}}</div>
        </div>
        <div class="col-6 p-0">
            <div class="sub-header">Total price</div>
            <div class="mb-0">{{card.totalPrice | currency: 'GBP'}}</div>
        </div>
    </div>

    <div class="p-2">
        <div class="sub-header">Courier</div>
        <div class="mb-0" *ngIf="card.courier">{{card.courier.name}}</div>
        <div class="mb-0 text-danger" *ngIf="!card.courier">Non assigned</div>
    </div>

    <div class="row m-0 p-0 px-2 bespoke-row" *ngIf="boardView === 'progress'">
        <div class="col-6 p-0">
            <span class="sub-header">Bespoke</span>&nbsp;<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="12" height="12" class="burger {{ randomizeClass(card.productDetailId) }}">
                <path class="top-line" d="M0 96C0 78.33 14.33 64 32 64H416C433.7 64 448 78.33 448 96C448 113.7 433.7 128 416 128H32C14.33 128 0 113.7 0 96z"/>
                <path class="mid-line" d="M0 256C0 238.3 14.33 224 32 224H416C433.7 224 448 238.3 448 256C448 273.7 433.7 288 416 288H32C14.33 288 0 273.7 0 256z"/>
                <path class="btm-line" d="M416 448H32C14.33 448 0 433.7 0 416C0 398.3 14.33 384 32 384H416C433.7 384 448 398.3 448 416C448 433.7 433.7 448 416 448z"/>
            </svg>&nbsp;<span class="sub-header opacity-100">Low</span>
        </div>
        <div class="col-6 p-0">
            <span class="sub-header">Masonry</span>&nbsp;<span class="sub-header opacity-100">{{ card.hasMasonry ? 'Yes' : 'No' }}</span>
        </div>
    </div>

    <ng-container *ngIf="boardView === 'progress' && card.status.id !== 7">
        <div class="row p-2">
            <div class="col-10" *ngIf="card.dueDays">
                <span class="sub-header">Lead time</span>&nbsp;<span class="fat-txt" awesomeTooltip="Due date {{card.dueDate | date: 'EEE dd MMM'}}">{{ card.dueDays }} day{{ card.dueDays > 1 || card.dueDays * -1 > 1 ? 's' : ''}}</span>
            </div>
            <div [ngClass]="{ 'col-2': card.dueDays, 'col-12': !card.dueDays }" class="text-right">
                <i class="fa fa-circle" [ngClass]="card.bgCol"></i>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="boardView !== 'progress' && card.status.id !== 7">
        <div class="row p-2 border-top">
            <div class="col-10" *ngIf="card.dueDays">
                <span class="sub-header">Status</span>&nbsp;<span class="fat-txt">{{card.status.supplierText}}</span>
            </div>
            <div [ngClass]="{ 'col-2': card.dueDays, 'col-12': !card.dueDays }" class="text-right">
                <i class="fa fa-circle" [ngClass]="card.bgCol"></i>
            </div>
        </div>
    </ng-container>
</div>
