<!-- Authorized user -->
<div class="w-100 bg-slate-5" *ngIf="(user$ | async)">
    <div *ngIf="(user$ | async)" class="d-flex">
        <div class="sidebar">
            <app-company-menu
                (navigationChange)="navigationChange($event)"
                [user]="(user$ | async)"
            ></app-company-menu>
        </div>
        <div class="outlet position-relative">
            <app-account-header *ngIf="(deviceType$| async) === 'desktop'"></app-account-header>
            <app-account-header-mobile
                *ngIf="(deviceType$| async) === 'mobile' || (deviceType$| async) === 'tabletV' || (deviceType$| async) === 'tabletH'">
            </app-account-header-mobile>
            <div class="container min-height g-md-3 g-xl-5 py-5">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
<!-- Not authorized user -->
<ng-container *ngIf="!(user$ | async)">
    <router-outlet></router-outlet>
</ng-container>

<app-alert
        [data]="alerts$ | async"
        [showModal]="
            (alerts$ | async).errors.length ||
            (alerts$ | async).notifications.length ||
            (alerts$ | async).simple ||
            (alerts$ | async).confirmAction ||
            (alerts$ | async).customAlert
"></app-alert>

<div class="modal-backdrop fade show modal-slide-show"
    (click)="closeAll()"
    *ngIf="(asideOpen$ | async)">
    <app-processing *ngIf="(overlays$ | async).processing"></app-processing>
</div>

<app-request-create *ngIf="(overlays$ | async).requestCreate"></app-request-create>
<app-floating-spinner [showSpinner]="(overlays$ | async).busyIndicator"></app-floating-spinner>



<!-- <div *ngIf="user$ | async">
    <app-header *ngIf="(device$ | async) && !(device$ | async).mobileVersion"></app-header>
    <app-header-mobile *ngIf="(device$ | async) && (device$ | async).mobileVersion"></app-header-mobile>
</div>
<div class="site-container bg-white">

    <div
        class="main-content-container"
        [ngStyle]="currentURL === '/' && {'padding-top' : '119px'}">
        <router-outlet (activate)="onActivate($event)"></router-outlet>
    </div>

    <ng-container *ngIf="user$ | async">
        <app-footer *ngIf="(device$ | async) && !(device$ | async).mobileVersion"></app-footer>
        <app-footer-mobile *ngIf="(device$ | async) && (device$ | async).isMobile"></app-footer-mobile>
        <app-footer-ipad *ngIf="(device$ | async) && (device$ | async).isTabletV"></app-footer-ipad>
        <app-notifications-list *ngIf="(overlays$ | async).notifications"></app-notifications-list>
        <app-menu-mobile *ngIf="(mobileMenu$ | async)"></app-menu-mobile>
        <app-login-modal *ngIf="(overlays$ | async).loginModal"></app-login-modal>
        <app-register-modal *ngIf="(overlays$ | async).registerModal"></app-register-modal>
        <app-request-create *ngIf="(overlays$ | async).requestCreate"></app-request-create>
    </ng-container>

    <app-alert
        [data]="(alerts$ | async)"
        *ngIf="(alerts$ | async).simple !== '' || (alerts$ | async).errors.length || (alerts$ | async).notifications.length"></app-alert>
    <div
        class="modal-backdrop fade show modal-slide-show"
        (click)="closeAll()"
        *ngIf="(asideOpen$ | async)">
        <app-processing *ngIf="(overlays$ | async).processing"></app-processing>
    </div>
</div> -->
